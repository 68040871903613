import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ViewBidCompletedLoadService } from "../../service/view-bid-completed-load.service";
import { ViewBidCompletedLoadTableData } from './view-bid-completed-load-table-data';
import { zip } from 'rxjs';
import { DisplayLoadData } from '../view-bids-supplier-per-load/tabledata';
@Component({
  selector: 'app-view-bid-completed-load',
  templateUrl: './view-bid-completed-load.component.html',
  styleUrls: ['./view-bid-completed-load.component.css']
})
export class ViewBidCompletedLoadComponent implements OnInit {

  constructor(private viewBidCompletedLoadService : ViewBidCompletedLoadService,
              private router: Router) { }

  viewBidCompletedLoadTableData : ViewBidCompletedLoadTableData = new ViewBidCompletedLoadTableData();
  displayLoadData : DisplayLoadData = new DisplayLoadData();

  ViewBidCompletedLoad : any =[];
  index =0;

  loadIds : any=[];
  requestLoadId : {};
 
  TableData=[];

  countOfBidPerLoad : any=[];
  lowestBidPerLoad : any=[];

  dataNotFound;
  loading;
  serverError;

  //showSupplier;
  title;

  showSupplier = true;
  showSupplierMore = false;

  ngOnInit(): void {

    //this.showSupplier = false;
    this.dataNotFound = false;
    this.loading = true;
    this.serverError = false;
    this.index=0;
    this.getViewBidCompletedLoad();

    this.removeLoadDetails();

  }

  getViewBidCompletedLoad(){

    return this.viewBidCompletedLoadService.getViewBidCompletedLoad().subscribe((data : {}) => {
      this.ViewBidCompletedLoad = data;

      if(this.ViewBidCompletedLoad.status == 1){

        
        for(let i=0; i< this.ViewBidCompletedLoad.list.length; i++){

          this.requestLoadId = {
            loadId : this.ViewBidCompletedLoad.list[i].loadId
          };

        this.loadIds.push(JSON.stringify(this.requestLoadId)); 

        }

        if(this.ViewBidCompletedLoad.list.length ==0){
          this.dataNotFound=true;
          this.loading = false;
        }
        else {

          zip(this.viewBidCompletedLoadService.getCountOfBidPerLoad(this.loadIds),
          this.viewBidCompletedLoadService.getLowestBidPerLoad(this.loadIds)).subscribe(([response1,response2]) => {

            this.countOfBidPerLoad =response1;
            this.lowestBidPerLoad= response2;
            
              for(let i=0; i< this.ViewBidCompletedLoad.list.length; i++){
              
                  this.viewBidCompletedLoadTableData = new ViewBidCompletedLoadTableData();
                  this.viewBidCompletedLoadTableData.loadId = this.ViewBidCompletedLoad.list[i].loadId;
                  this.viewBidCompletedLoadTableData.fromCityName = this.ViewBidCompletedLoad.list[i].fromCityName;
                  this.viewBidCompletedLoadTableData.toCityName = this.ViewBidCompletedLoad.list[i].toCityName;
                  this.viewBidCompletedLoadTableData.branchName = this.ViewBidCompletedLoad.list[i].branchName;
                  this.viewBidCompletedLoadTableData.reportingDate = this.ViewBidCompletedLoad.list[i].reportingDate;

                  if(this.countOfBidPerLoad[i].status==1){
                    this.viewBidCompletedLoadTableData.countOfBidsReceived = this.countOfBidPerLoad[i].countOfBidReceived;
                  }

                  if(this.lowestBidPerLoad[i].status==1){
                    this.viewBidCompletedLoadTableData.lowestBid = this.lowestBidPerLoad[i].lowestBid;
                  }

                  this.TableData.push(this.viewBidCompletedLoadTableData);
              }

              this.loading = false;
            }
            )
        }

      }
      else{

        this.loading = false;
        this.serverError = true;
      }

    }, error => console.log(error));
  }

  showBid(load){

    this.title = 'viewBidsSupplierPerLoad';
    //this.showSupplier = true;
    
    this.showSupplier = false;
    this.showSupplierMore = true;

    this.displayLoadData = new DisplayLoadData ();
    this.displayLoadData.loadId = load.loadId;
    this.displayLoadData.fromCityName = load.fromCityName;
    this.displayLoadData.toCityName = load.toCityName;
    this.displayLoadData.branchName = load.branchName;

   // this.router.navigate(['/viewBidsSupplierPerLoad/'+load.loadId]);
  
  }

  addLoadDetail(load){

    console.log("Completed load details for duplicate : loadId : "+load.loadId+" : fromCityName : "+load.fromCityName+" : toCityName : "+load.toCityName+" : branchName : "+load.branchName);

    //sessionStorage.setItem('loadId', load.loadId);
    localStorage.setItem('loadId', load.loadId);
    localStorage.setItem('fromCityName', load.fromCityName);
    localStorage.setItem('toCityName', load.toCityName);
    localStorage.setItem('branchName', load.branchName);
  }

  removeLoadDetails(){
  localStorage.removeItem('loadId');
  localStorage.removeItem('fromCityName');
  localStorage.removeItem('toCityName');
  localStorage.removeItem('branchName');
  }

  hello(){
    
    this.showSupplier = true;
    this.showSupplierMore = false;
  }

}