import { Component, OnInit, Input } from '@angular/core';
import { DriverListForSupplierService } from 'app/service/driver-list-for-supplier.service';
import { TruckieFunctionsService } from 'app/service/truckie-functions.service';
import { APiProperties } from 'app/class/api-properties';
import { DatePipe,formatDate } from '@angular/common';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-driver-list-for-supplier',
  templateUrl: './driver-list-for-supplier.component.html',
  styleUrls: ['./driver-list-for-supplier.component.css']
})
export class DriverListForSupplierComponent implements OnInit {
  apiProperties : APiProperties = new APiProperties();
  @Input() supplierId : any;

  constructor(private driverListForSupplierService : DriverListForSupplierService, 
              private tfs : TruckieFunctionsService, 
              private datePipe: DatePipe, 
              private toastr: ToastrService) { }

  requestData : any = {};
  driverList :  any = [];

  dataNotFound;
  loading;
  serverError;

  driverListBlk;
  driverAddShow;
  ssvData : any;
  
  ngOnInit(): void {

    this.driverListBlk = true;

    this.dataNotFound=false;
    this.loading = true;
    this.serverError = false;

    this.getDriverListForSupplier();

  }

  getDriverListForSupplier(){

    this.requestData = {
      "supplierId" : this.supplierId
    };
    console.log(this.requestData);

    this.driverListForSupplierService.getDriverListForSupplier(JSON.stringify(this.requestData)).subscribe((data : {}) =>{

      this.driverList = data;

      if(this.driverList.status ==1){
        if(this.driverList.list.length ==0){
          this.dataNotFound=true;
          this.loading = false;
        }
        else{
          this.loading = false;
        }
      }
      else{
        this.dataNotFound=true;
        this.loading = false;
      }

    }, error => {
      this.serverError = true;
      console.log("Error : "+error);
    });

  }

  showDriverAdd(){
    this.ssvData = "1";
    this.driverListBlk = false;
    this.driverAddShow = true;
  }

  hideDriverAdd(){
    this.driverListBlk = true;
    this.driverAddShow = false;
  }

  updateDriverBlk;
  driverId : any;

  updateDriver(driverIdVal){
    this.driverId = driverIdVal;
    this.supplierId = this.supplierId;
    this.driverListBlk = false;
    this.updateDriverBlk = true;
  }

  hideUpdateDriver(driverIdVal){
    this.getDriverListForSupplier();
    this.driverListBlk = true;
    this.updateDriverBlk = false;
  }

  ddStatusRes : any = [];
  updateBDStatus(e,supplierId, driverId, status){

    //if(e.traget.checked){}
    this.requestData = {
      clientId : this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret,
      supplierId : supplierId,
      driverId : driverId,
      status : status
    };
    console.log(this.requestData);

    return this.tfs.updateDriverDetailStatus(JSON.stringify(this.requestData)).subscribe((data : {}) => {
      this.ddStatusRes = data;

      if(this.ddStatusRes.status == 1){
        this.getDriverListForSupplier();
      }

    }, error => console.log(error));

  }

}