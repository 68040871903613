import { Component, OnInit, Input } from '@angular/core';
import { TruckListForSupplierService } from 'app/service/truck-list-for-supplier.service';
import { TruckieFunctionsService } from 'app/service/truckie-functions.service';
import { APiProperties } from 'app/class/api-properties';
import { DatePipe,formatDate } from '@angular/common';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-truck-list-for-supplier',
  templateUrl: './truck-list-for-supplier.component.html',
  styleUrls: ['./truck-list-for-supplier.component.css']
})
export class TruckListForSupplierComponent implements OnInit {
  apiProperties : APiProperties = new APiProperties();
  @Input() supplierId : any;

  constructor(private truckListForSupplierService : TruckListForSupplierService,
              private tfs : TruckieFunctionsService, 
              private datePipe: DatePipe, 
              private toastr: ToastrService) { }

  requestData : any = {};
  truckList :  any = [];
  
  dataNotFound;
  loading;
  serverError;
  
  truckListBlk;
  truckAddShow;
  ssvData : any;
  
  ngOnInit(): void {

    this.truckListBlk = true;

    this.dataNotFound=false;
    this.loading = true;
    this.serverError = false;
    

    this.getTruckListForSupplier();
  }

  getTruckListForSupplier(){

    this.requestData = {
      "supplierId" : this.supplierId
    };
    console.log(this.requestData);

    this.truckListForSupplierService.getTruckListForSupplier(JSON.stringify(this.requestData)).subscribe((data : {}) =>{

      this.truckList = data;

      if(this.truckList.status ==1){
        
        if(this.truckList.list.length ==0){
          this.dataNotFound=true;
          this.loading = false;
        }
        else{
          this.loading = false;
        }
      }
      else{
        this.dataNotFound=true;
        this.loading = false;
      }

    }, error => {
      this.serverError = true;
      console.log("Error : "+error);
    });

  }

  showTruckAdd(){
    this.ssvData = "1";
    this.truckListBlk = false;
    this.truckAddShow = true;
  }

  hideTruckAdd(){
    this.truckListBlk = true;
    this.truckAddShow = false;
    this.getTruckListForSupplier();
  }

  truckDetailUpdateBlk;
  truckId : any;

  truckDetailUpdate(truckIdVal){
    this.supplierId = this.supplierId;
    this.truckId = truckIdVal;
    this.truckListBlk = false;
    this.truckDetailUpdateBlk = true;
  }

  hideTruckDetailUpdate(){
    this.truckListBlk = true;
    this.truckDetailUpdateBlk = false;
  }
  
  tdStatusRes : any = [];
  updateTDStatus(e, truckId, status){

    //if(e.traget.checked){}
    this.requestData = {
      clientId : this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret,
      supplierId : this.supplierId,
      truckId : truckId,
      status : status
    };
    console.log(this.requestData);

    return this.tfs.updateTruckDetailStatus(JSON.stringify(this.requestData)).subscribe((data : {}) => {
      this.tdStatusRes = data;

      if(this.tdStatusRes.status == 1){
        this.getTruckListForSupplier();
      }

    }, error => console.log(error));

  }

}
