import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ViewBidCompletedLoadService } from "../../service/view-bid-completed-load.service";
import { ViewBidCompletedLoadTableData } from '../../pages/view-bid-completed-load/view-bid-completed-load-table-data';
import { TruckieFunctionsService } from 'app/service/truckie-functions.service';
import { ViewLiveLoadService } from "../../service/view-live-load.service";
import { APiProperties } from '../../class/api-properties';
import { zip } from 'rxjs';
import { DisplayLoadData } from '../view-bids-supplier-per-load/tabledata';
import { ViewLiewLoadTableData } from '../view-live-load/view-live-load-table-data';
import { RestApiService } from 'app/rest-service/rest-api.service';

@Component({
  selector: 'app-combine-load',
  templateUrl: './combine-load.component.html',
  styleUrls: ['./combine-load.component.css']
})

export class CombineLoadComponent implements OnInit {
  
  apiProperties : APiProperties = new APiProperties();

  @ViewChild("multiSelect") multiSelect;

    constructor(private viewBidCompletedLoadService : ViewBidCompletedLoadService,
                private router: Router,
                public restApi: RestApiService,
                private tfs: TruckieFunctionsService,
                private viewLiveLoadService : ViewLiveLoadService) { }

  masterBranch : any = [];
  cityMaster : any = [];

  fromCityId = null;
  toCityId = null;
  branchId = null;
  status = null;
  bId=false;

  displayBranch = false;
  displayFrom = false;
  displayTo = false;
  displayReportingDate = false;
  displayBidClosesIn = false;
  displayLowestBid = false;
  displayLoadStatus = false;
  displayRemark = false;
  displayOrderType = false;
  displayBidsCount = false;

  displayColumnsList = [
    {"column":"Branch"},
    {"column":"From"},
    {"column":"To"},
    {"column":"Reporting Date"},
    {"column":"Bid Closes In"},
    {"column":"Lowest Bid"},
    {"column":"Load Status"},
    {"column":"Remark"},
    {"column":"Order Type"},
    {"column":"Bids Count"}
  ];

  public settings = {};

    ngOnInit(): void {
      this.showCombineLoad = true;
  
      this.dataNotFound = false;
      this.loading = true;
      this.serverError = false;
      this.index=0;
  
      //this.getListOfAllLoads();
      this.setDisplayColumnsList();
      this.getListOfAllLoadsUpdated();
      this.fetch_city_master_staging();
      this.fetch_master_branch_staging();
      var id = localStorage.getItem("branchLoginId");
      if(id=="0"){
          this.bId=true;
      }

      this.settings = {
        singleSelection: false,
        idField: 'column',
        textField: 'column',
        enableCheckAll: true,
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        allowSearchFilter: true,
        limitSelection: -1,
        clearSearchFilter: true,
        maxHeight: 197,
       // itemsShowLimit: 3,
        searchPlaceholderText: 'Select Columns For Display',
        noDataAvailablePlaceholderText: 'No data Vailable',
        closeDropDownOnSelection: false,
        showSelectedItemsAtTop: false,
        defaultOpen: false,
      };

    }
    
    setDisplayColumnsList(){

      if(localStorage.getItem(localStorage.getItem('userNameLocal')+"-displayBranch") == 'true')
        this.displayBranch = true;
      else
        localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayBranch", 'false');  
      
      if(localStorage.getItem(localStorage.getItem('userNameLocal')+"-displayFrom") == 'true')
        this.displayFrom = true;
      else
        localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayFrom", 'false');

      if(localStorage.getItem(localStorage.getItem('userNameLocal')+"-displayTo") == 'true')
        this.displayTo = true;
      else
        localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayTo", 'false');

      if(localStorage.getItem(localStorage.getItem('userNameLocal')+"-displayReportingDate") == 'true')
        this.displayReportingDate = true;
      else
        localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayReportingDate", 'false');

      if(localStorage.getItem(localStorage.getItem('userNameLocal')+"-displayBidClosesIn") == 'true')
        this.displayBidClosesIn = true;
      else
        localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayBidClosesIn", 'false');

      if(localStorage.getItem(localStorage.getItem('userNameLocal')+"-displayLowestBid") == 'true')
        this.displayLowestBid = true;
      else
        localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayLowestBid", 'false');

      if(localStorage.getItem(localStorage.getItem('userNameLocal')+"-displayLoadStatus") == 'true')
        this.displayLoadStatus = true;
      else
        localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayLoadStatus", 'false');

      if(localStorage.getItem(localStorage.getItem('userNameLocal')+"-displayRemark") == 'true')
        this.displayRemark = true;
      else
        localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayRemark", 'false');

      if(localStorage.getItem(localStorage.getItem('userNameLocal')+"-displayOrderType") == 'true')
        this.displayOrderType = true;
      else
        localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayOrderType", 'false');

      if(localStorage.getItem(localStorage.getItem('userNameLocal')+"-displayBidsCount") == 'true')
        this.displayBidsCount = true;
      else
        localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayBidsCount", 'false');
    }

    public onItemSelect(item: any) {
    
      if(item.column == 'Branch'){
        this.displayBranch = true;
        localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayBranch", 'true');
      }else if(item.column == 'From'){
        this.displayFrom = true;
        localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayFrom", 'true');
      }else if(item.column == 'To'){
        this.displayTo = true;
        localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayTo", 'true');
      }else if(item.column == 'Reporting Date'){
        this.displayReportingDate = true;
        localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayReportingDate", 'true');
      }else if(item.column == 'Bid Closes In'){
        this.displayBidClosesIn = true;
        localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayBidClosesIn", 'true');
      }else if(item.column == 'Lowest Bid'){
        this.displayLowestBid = true;
        localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayLowestBid", 'true');
      }else if(item.column == 'Load Status'){
        this.displayLoadStatus = true;
        localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayLoadStatus", 'true');
      }else if(item.column == 'Remark'){
        this.displayRemark = true;
        localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayRemark", 'true');
      }else if(item.column == 'Order Type'){
        this.displayOrderType = true;
        localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayOrderType", 'true');
      }else if(item.column == 'Bids Count'){
        this.displayBidsCount = true;
        localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayBidsCount", 'true');
      }
    }

    public onDeSelect(item: any) {
    
      if(item.column == 'Branch'){
        this.displayBranch = false;
        localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayBranch", 'false');
      }else if(item.column == 'From'){
        this.displayFrom = false;
        localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayFrom", 'false');
      }else if(item.column == 'To'){
        this.displayTo = false;
        localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayTo", 'false');
      }else if(item.column == 'Reporting Date'){
        this.displayReportingDate = false;
        localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayReportingDate", 'false');
      }else if(item.column == 'Bid Closes In'){
        this.displayBidClosesIn = false;
        localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayBidClosesIn", 'false');
      }else if(item.column == 'Lowest Bid'){
        this.displayLowestBid = false;
        localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayLowestBid", 'false');
      }else if(item.column == 'Load Status'){
        this.displayLoadStatus = false;
        localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayLoadStatus", 'false');
      }else if(item.column == 'Remark'){
        this.displayRemark = false;
        localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayRemark", 'false');
      }else if(item.column == 'Order Type'){
        this.displayOrderType = false;
        localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayOrderType", 'false');
      }else if(item.column == 'Bids Count'){
        this.displayBidsCount = false;
        localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayBidsCount", 'false');
      }
    }
  
    public onSelectAll(items: any) {
      
      this.displayBranch = true;
      this.displayFrom = true;
      this.displayTo = true;
      this.displayReportingDate = true;
      this.displayBidClosesIn = true;
      this.displayLowestBid = true;
      this.displayLoadStatus = true;
      this.displayRemark = true;
      this.displayOrderType = true;
      this.displayBidsCount = true;

      localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayBranch", 'true');
      localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayFrom", 'true');
      localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayTo", 'true');
      localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayReportingDate", 'true');
      localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayBidClosesIn", 'true');
      localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayLowestBid", 'true');
      localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayLoadStatus", 'true');
      localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayRemark", 'true');
      localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayOrderType", 'true');
      localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayBidsCount", 'true');
    }
  
    public onDeSelectAll(items: any) {
      
      this.displayBranch = false;
      this.displayFrom = false;
      this.displayTo = false;
      this.displayReportingDate = false;
      this.displayBidClosesIn = false;
      this.displayLowestBid = false;
      this.displayLoadStatus = false;
      this.displayRemark = false;
      this.displayOrderType = false;
      this.displayBidsCount = false;

      localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayBranch", 'false');
      localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayFrom", 'false');
      localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayTo", 'false');
      localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayReportingDate", 'false');
      localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayBidClosesIn", 'false');
      localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayLowestBid", 'false');
      localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayLoadStatus", 'false');
      localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayRemark", 'false');
      localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayOrderType", 'false');
      localStorage.setItem(localStorage.getItem('userNameLocal')+"-displayBidsCount", 'false');
    }


    viewLiveLoadTableData : ViewLiewLoadTableData = new ViewLiewLoadTableData();

  viewBidCompletedLoadTableData : ViewBidCompletedLoadTableData = new ViewBidCompletedLoadTableData();
  displayLoadData : DisplayLoadData = new DisplayLoadData();
  
  showCombineLoad;
  ViewBidCompletedLoad : any =[];
  index =0;

  loadIds : any=[];
  requestLoadId : {};
  TableData=[];
  countOfBidPerLoad : any=[];
  lowestBidPerLoad : any=[];

  dataNotFound;
  loading;
  serverError;

  title;

  //showSupplier = true;
  showSupplierMore = false;

  requestData : any = {};
  ViewLiveLoad : any = [];
  //loadIds : any=[];
  //countOfBidPerLoad : any=[];
  //loadStatus : any=[];
  activeStatus : any=[];
  requestCountOfBidPerLoad : {};
  CountBidPerLoad : any=[];
  //TableData=[];
  //index=0;

  completeLoadResponse : any = [];
  viewLiveLoadListData : any = [];
  getListOfAllLoadsUpdated(){
    var id = localStorage.getItem("branchLoginId");
    if(id=="0"){
        id=this.branchId;
    }
    this.requestData = {
      clientId : this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret,
      status : this.status,
      fromCityId : this.fromCityId,
      toCityId : this.toCityId,
      branchId : parseInt(id)
    }    

    return this.tfs.fetchListOfAllLoads(JSON.stringify(this.requestData)).subscribe((data : {}) => {
      
      this.ViewLiveLoad = data;
      //console.log(this.ViewLiveLoad);

      if(this.ViewLiveLoad.status == 1){

        if(this.ViewLiveLoad.listOfLoads.length == 0 ){
          this.dataNotFound=true;
          this.loading = false;
        } else {
          this.viewLiveLoadListData = this.ViewLiveLoad.listOfLoads;
        }
      } else {
        this.loading = false;
        this.serverError = true;
      }

    }, error => console.log(error));
  }

  getListOfAllLoads(){

    this.requestData = {
      clientId: this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret,
    }    

    return this.tfs.fetchListOfAllLoads(JSON.stringify(this.requestData)).subscribe((data : {}) => {
      
      this.ViewLiveLoad = data;
      console.log(this.ViewLiveLoad);

      if(this.ViewLiveLoad.status == 1){

        for(let i=0; i< this.ViewLiveLoad.listOfLoads.length; i++){

            this.requestCountOfBidPerLoad = {
              loadId : this.ViewLiveLoad.listOfLoads[i].loadId
            };
            this.loadIds.push(JSON.stringify(this.requestCountOfBidPerLoad));  
        }

        if(this.ViewLiveLoad.listOfLoads.length ==0){
          this.dataNotFound=true;
          this.loading = false;
          this.TableData = null;
        }else {

          this.viewLiveLoadService.getLoadStatus(this.loadIds).subscribe((data1 : {}) => {

            this.activeStatus = data1;
  
            for(let i=0; i< this.ViewLiveLoad.listOfLoads.length; i++){
  
              this.viewLiveLoadTableData = new ViewLiewLoadTableData();
              this.viewLiveLoadTableData.loadId = this.ViewLiveLoad.listOfLoads[i].loadId;
              this.viewLiveLoadTableData.fromCityList = this.ViewLiveLoad.listOfLoads[i].fromCityList;
              this.viewLiveLoadTableData.toCityList = this.ViewLiveLoad.listOfLoads[i].toCityList;
              this.viewLiveLoadTableData.countOfBidReceived = this.ViewLiveLoad.listOfLoads[i].countOfBidReceived;
              this.viewLiveLoadTableData.lowestBidList = this.ViewLiveLoad.listOfLoads[i].lowestBidList;
              this.viewLiveLoadTableData.bidCloseDateTime = this.ViewLiveLoad.listOfLoads[i].bidCloseDateTime;
              this.viewLiveLoadTableData.branchName = this.ViewLiveLoad.listOfLoads[i].branchName;
              this.viewLiveLoadTableData.reportingDate = this.ViewLiveLoad.listOfLoads[i].reportingDate;
              this.viewLiveLoadTableData.remarks = this.ViewLiveLoad.listOfLoads[i].remarks;
              this.viewLiveLoadTableData.order_type = this.ViewLiveLoad.listOfLoads[i].order_type;
              this.viewLiveLoadTableData.loadStatus = this.ViewLiveLoad.listOfLoads[i].loadStatus;
              
              this.viewLiveLoadTableData.activeStatus = this.activeStatus[i].code;
  
              if(this.activeStatus[i] ==null){
                console.log(this.ViewLiveLoad.listOfLoads[i].loadId+" null status:"+this.activeStatus[i]);
              }
              else{
                this.viewLiveLoadTableData.activeStatus = this.activeStatus[i].code;
                console.log(this.ViewLiveLoad.listOfLoads[i].loadId+"status:"+this.activeStatus[i]);
              }
              
              this.TableData.push(this.viewLiveLoadTableData);
            }
  
              this.loading = false;
            }
            )
            
        }

      }
      else{

        this.loading = false;
        this.serverError = true;
      }

    }, error => console.log(error));
  }

  showBid(load){

    this.title = 'viewBidsSupplierPerLoad';
    
    //this.showSupplier = false;
    this.showCombineLoad = false;
    this.showSupplierMore = true;

    this.displayLoadData = new DisplayLoadData ();
    this.displayLoadData.loadId = load.loadId;
    this.displayLoadData.branchName = load.branchName;
    this.displayLoadData.order_type = load.order_type;
    this.displayLoadData.loadStatus = load.loadStatus;
    this.displayLoadData.completeLoadStatus = load.completeLoadStatus;
    //this.displayLoadData.fromCityName = load.fromCityName;
    // this.displayLoadData.toCityName = load.toCityName;
    

    // this.displayLoadData.loadId = '185';
    // this.displayLoadData.fromCityName = 'Achalpur';
    // this.displayLoadData.toCityName = 'Achalpur';
    // this.displayLoadData.branchName = 'bisalpur';

    // this.displayLoadData.loadId = '184';
    // this.displayLoadData.fromCityName = 'Ahmedabad';
    // this.displayLoadData.toCityName = 'Surat';
    // this.displayLoadData.branchName = 'bisalpur';

   // this.router.navigate(['/viewBidsSupplierPerLoad/'+load.loadId]);
  
  }

  addLoadDetail(load){

    console.log("Completed load details for duplicate : loadId : "+load.loadId+" : fromCityName : "+load.fromCityName+" : toCityName : "+load.toCityName+" : branchName : "+load.branchName);

    //sessionStorage.setItem('loadId', load.loadId);
    localStorage.setItem('loadId', load.loadId);
    localStorage.setItem('fromCityName', load.fromCityName);
    localStorage.setItem('toCityName', load.toCityName);
    localStorage.setItem('branchName', load.branchName);
  }

  removeLoadDetails(){
  localStorage.removeItem('loadId');
  localStorage.removeItem('fromCityName');
  localStorage.removeItem('toCityName');
  localStorage.removeItem('branchName');
  }

  hello(){
    //this.showSupplier = true;
    this.showCombineLoad = true;
    this.showSupplierMore = false;
  }

  completeLoad(loadId,index){

    this.requestData = {
      loadId : loadId
    };

    this.viewLiveLoadService.completeLoad(JSON.stringify(this.requestData)).subscribe((data : {}) => {

      this.completeLoadResponse = data;

      console.log("complete load response : "+ JSON.stringify(this.completeLoadResponse));

      if(this.completeLoadResponse.status == 1){

        alert(this.completeLoadResponse.message);

        this.TableData.splice(index,1);
       // this.ngOnInit();
      }
      else{
        alert(this.completeLoadResponse.message);
      }

    }, error => {
      console.log("error:"+error);
    });

  }

  onCheckboxChange(e,id) {
    // const checkArray: FormArray = this.registrationForm.get('checkArray') as FormArray;
 
     if (e.target.checked) {
      // checkArray.push(new FormControl(e.target.value));

      this.requestData = {
        loadId : id,
        loadStatus : 1
      };

       this.viewLiveLoadService.updateLoadStatus(JSON.stringify(this.requestData)).subscribe((data : {}) => {
        console.log("response :"+JSON.stringify(data));
        
      }, error => console.log(error));
     }
     else{

      this.requestData = {
        loadId : id,
        loadStatus : 2
      };

       this.viewLiveLoadService.updateLoadStatus(JSON.stringify(this.requestData)).subscribe((data : {}) => {
        console.log("response :"+JSON.stringify(data));
        
      }, error => console.log(error));

     }
   }

   fetch_city_master_staging(){
    return this.restApi.fetch_city_master_staging().subscribe(data => {
    this.cityMaster = data.list;
    //console.log(this.cityMaster);
    }, error => console.log(error));
  }

  masterBranchRes : any = [];
  fetch_master_branch_staging(){

    if(localStorage.getItem('userRole') == "SUB_ADMIN"){
      this.requestData = {
        adminId : localStorage.getItem('adminIdLocal')
      }
    } else {
      this.requestData = {
        adminId : ""
      }
    }
    console.log(this.requestData);

    this.restApi.fetch_master_branch_staging(JSON.stringify(this.requestData)).subscribe((data : {}) => {
      
      this.masterBranchRes = data;
      this.masterBranch = this.masterBranchRes.list;
      console.log(this.masterBranch);
      
    }, error => console.log(error));
  }

  // fetch_master_branch_staging(){
  //   return this.restApi.fetch_master_branch_staging().subscribe(data => {
  //     this.masterBranch = data.list;
  //     //console.log(this.masterBranch);
  //   }, error => console.log(error));
  // }

  onChange(e, type){

    if(type == 'from')
      this.fromCityId = e;
    else if(type == 'to')
      this.toCityId = e;
    else if(type == 'branch')
      this.branchId = e;
    else if(type == 'status')
      this.status = e.target.value;

    this.getListOfAllLoadsUpdated();
  }
}
