import { Component, OnInit , ViewChild, Input, Output, EventEmitter} from '@angular/core';
import { FormGroup , FormBuilder , Validators } from '@angular/forms';
import { APiProperties } from '../../class/api-properties';
import { TruckieFunctionsService } from 'app/service/truckie-functions.service';
import { TruckModelService } from "../../service/truck-model.service";
import { zip } from 'rxjs';
import { DatePipe,formatDate } from '@angular/common';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-truck-insurance-add',
  templateUrl: './truck-insurance-add.component.html',
  styleUrls: ['./truck-insurance-add.component.css']
})
export class TruckInsuranceAddComponent implements OnInit {

  @Input() supplierId;
  @Input() truckId;
  @Output() hideTruckInsuranceAdd = new EventEmitter();

  apiProperties : APiProperties = new APiProperties();
  
  constructor(private fb: FormBuilder, 
              private tfs : TruckieFunctionsService,
              private tms : TruckModelService,
              private datePipe: DatePipe, 
              private toastr: ToastrService) { }

  tInsuranceAdd;
  tiaBack;
  tiaBlk;
  truckImageUpload;
  todayDate : any;
  ngOnInit(): void {
    this.tiaBack = true;
    this.tiaBlk = true;
    this.tInsuranceAdd = true;
    this.todayDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  }

  truckInsuranceAddForm = this.fb.group({
    insuranceCompany : ['' , Validators.required],
    insuranceNumber : ['' , Validators.required],
    insuranceExpiryDate : ['' , Validators.required],
    insurancePicture : ['' , Validators.required],
  });
  get uti() { return this.truckInsuranceAddForm.controls; }

  submitted;
  requestData : any = {};
  toInsuranceRes : any = [];
  iExpiryDateUp : any;
  
  tInsuranceProgreess;

  truckInsuranceAdd() {

    this.submitted = true;

    this.tInsuranceAdd = false;
    this.tInsuranceProgreess = true;

    if(this.truckInsuranceAddForm.invalid){
      this.tInsuranceAdd = true;
        this.tInsuranceProgreess = false;
      return true;
    }

    this.iExpiryDateUp = this.datePipe.transform(this.truckInsuranceAddForm.get("insuranceExpiryDate").value, 'yyyy-MM-dd hh:mm:ss');
    //this.iExpiryDateUp = this.datePipe.transform(this.truckInsuranceAddForm.get("insuranceExpiryDate").value, 'yyyy-MM-dd');
    //console.log(this.iExpiryDateUp);

    this.requestData = {
      supplierId : this.supplierId,
      truckId : this.truckId,
      insuranceCompany : this.truckInsuranceAddForm.get("insuranceCompany").value,
      insuranceNumber : this.truckInsuranceAddForm.get("insuranceNumber").value,
      insuranceExpiryDate : this.iExpiryDateUp,
      insurancePicture : this.truckInsuranceAddForm.get("insurancePicture").value,
    }
    console.log(this.requestData);

    return this.tfs.addTruckInsurance(JSON.stringify(this.requestData)).subscribe(data => {
      
      this.toInsuranceRes = data;
      console.log(this.toInsuranceRes);
      
      if(this.toInsuranceRes.status == 1){
        this.tInsuranceAdd = true;
        this.tInsuranceProgreess = false;
        this.showNotification('top','right',this.toInsuranceRes.message, '1');
        this.hideTruckInsuranceAdd.emit({"insuranceId": this.toInsuranceRes.id});
      } else {
        this.tInsuranceAdd = true;
        this.tInsuranceProgreess = false;
        this.showNotification('top','right',this.toInsuranceRes.message, '3');
      }

    },error => console.log(error));

  }


  documentFor : any;
  paramPass : any;
  upImageUrl : any;
  paramPassFinal : any;

  imageUploadShow(paramPassVal) {
    this.documentFor = "truck";
    this.supplierId = this.supplierId;
    this.truckId = this.truckId;
    this.paramPass = paramPassVal;
    console.log(this.supplierId + " ~~~~ " + this.truckId);

    this.tiaBack = false;
    this.tiaBlk = false;
    this.truckImageUpload = true;
  }

  hideImageupload(data){

    if(data != null){
      
        console.log(data);
        
        this.upImageUrl = data.finalImageUp;
        this.paramPassFinal = data.paramPassFinal;

        if(this.paramPassFinal == "insurancePicture"){
          this.truckInsuranceAddForm.patchValue({
            'insurancePicture' : this.upImageUrl,
          });
        }

    }
    
    this.tiaBack = true;
    this.tiaBlk = true;
    this.truckImageUpload = false;
    
  }

  showNotification(from, align, messageDis, errorNo) {
    
    // const color = Math.floor(Math.random() * 5 + 1);
    const color = errorNo;
    
    //console.log(errorNo);

    switch (color) {
      
      case '1':
        this.toastr.success(
          '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">'+messageDis+'</span>',
          "",
          {
            timeOut: 500,
            // closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-success alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;
      
        case '2':
        this.toastr.warning(
        '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">'+messageDis+'</span>',
          "",
          {
            timeOut: 4000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-warning alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;

        case '3':
        this.toastr.error(
        '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">'+messageDis+'</span>',
          "",
          {
            timeOut: 4000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-danger alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;
      
        default:
        break;
    }

  }

  back(){
    this.hideTruckInsuranceAdd.emit();
  }

}
