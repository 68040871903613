import { Component, OnInit , ViewChild, Input, Output, EventEmitter} from '@angular/core';
import { FormGroup , FormBuilder , Validators } from '@angular/forms';
import { APiProperties } from '../../class/api-properties';
import { TruckieFunctionsService } from 'app/service/truckie-functions.service';
import { TruckModelService } from "../../service/truck-model.service";
import { zip } from 'rxjs';
import { DatePipe,formatDate } from '@angular/common';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-truck-update',
  templateUrl: './truck-update.component.html',
  styleUrls: ['./truck-update.component.css']
})
export class TruckUpdateComponent implements OnInit {
  @Input() supplierId;
  @Input() truckId;
  @Output() hideTruckDetailUpdate = new EventEmitter();

  apiProperties : APiProperties = new APiProperties();
  
  constructor(private fb: FormBuilder, 
              private tfs : TruckieFunctionsService,
              private tms : TruckModelService,
              private datePipe: DatePipe, 
              private toastr: ToastrService) { }

  tuBack;
  tuBlk;
  todayDate : any;

  ngOnInit(): void {
    this.tuBack = true;
    this.tuBlk = true;

    this.todayDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    this.getTruckModelList();
    this.fetchTruckDetails();
  }

  TruckModelRes : any = [];
  getTruckModelList(){
    return this.tms.getTruckModel().subscribe((data : {}) => {
      this.TruckModelRes = data;
    }, error => console.log(error));
  }
  
  tBasicRes : any = [];
  tBankRes : any = [];
  tInsuranceRes : any = [];
  tPanRes : any = [];
  tiExpiryDate : any;

  fetchTruckDetails() {

    this.requestData = {
      supplierId : this.supplierId,
      truckId : this.truckId,
    }
    console.log(this.requestData);

  zip(this.tfs.getTruckBasicDetailsOfSupplier(this.requestData),
      this.tfs.getTruckOwnerBankDetails(this.requestData),
      this.tfs.getTruckInsuranceDetailsOfSupplier(this.requestData),
      this.tfs.getTruckOwnerPanDetails(this.requestData)).subscribe(([response1,response2,response3,response4]) => {

            this.tBasicRes = response1;
            this.tBankRes = response2;
            this.tInsuranceRes = response3;
            this.tPanRes = response4;

      console.log(this.tPanRes);      
      
      if(this.tBasicRes.status == 1){

        this.utBasicDetailsForm.patchValue({
           'truckType' : this.tBasicRes.truckTypeId,
           'truckOwnerName' : this.tBasicRes.truckOwnerName,
           'truckOwnerMobileNumber' : this.tBasicRes.truckOwnerMobileNumber,
           'truckRegistrationNumberStateCode' : this.tBasicRes.truckRegistrationNumberStateCode,
           'truckRegistrationNumberCityCode' : this.tBasicRes.truckRegistrationNumberCityCode,
           'truckRegistrationNumberSeries' : this.tBasicRes.truckRegistrationNumberSeries,
           'truckRegistrationNumberNumber' : this.tBasicRes.truckRegistrationNumberNumber,
           'RCBookPictureFrontURL' : this.tBasicRes.RCBookPictureFrontURL,
           'RCBookPictureBackURL' : this.tBasicRes.RCBookPictureBackURL,
         });
      }

      if(this.tBankRes.status == 1){

        this.utOwnerBankDetailsForm.patchValue({
           'supplierBankDetailsId' : this.tBankRes.bankDetailId,
           'IFSCCode' : this.tBankRes.IFSCCode,
           'truckOwnerMobileNumber' : this.tBankRes.truckOwnerMobileNumber,
           'bankName' : this.tBankRes.bankName,
           'bankAccountNumber' : this.tBankRes.bankAccountNumber,
           'bankAccountName' : this.tBankRes.bankAccountName,
           'cancelChequePhoto' : this.tBankRes.cancelChequePhotoURL,
           'bankProofPhotoURL' : this.tBankRes.bankProofPhotoURL,
         });
      }

      if(this.tInsuranceRes.status == 1){

        if(this.tInsuranceRes.insuranceExpiryDate){
          this.tiExpiryDate = this.datePipe.transform(this.tInsuranceRes.insuranceExpiryDate, 'yyyy-MM-dd');
        }

         this.utInsuranceDetailsForm.patchValue({
           'truckInsuranceId' : this.tInsuranceRes.truckInsuranceId,
           'insuranceCompany' : this.tInsuranceRes.insuranceCompany,
           'insuranceNumber' : this.tInsuranceRes.insuranceNumber,
           'insuranceExpiryDate' : this.tiExpiryDate,
           'insurancePicture' : this.tInsuranceRes.insurancePictureURL,
         });
      }

      if(this.tPanRes.status == 1){

        this.utOwnerPanDetailsForm.patchValue({
           'panCardId' : this.tPanRes.PanId,
           'nameOnPanCard' : this.tPanRes.nameOnPanCard,
           'numberOnPanCard' : this.tPanRes.panNumber,
           'panFrontPictureURL' : this.tPanRes.panPictureURL,
         });
      }

    },error => console.log(error));

  }
  
  iExpiryDateUp : any;
  requestData : any = {};
  utobdRes : any = [];
  utBankRes : any = [];
  utInsuranceRes : any = [];
  utoPanRes : any = [];

  utBasicDetailsForm = this.fb.group({
    truckType : ['' , Validators.required], 
    truckOwnerName : ['' , Validators.required],
    truckOwnerMobileNumber : ['' , Validators.required],
    RCBookPictureFrontURL : ['' , Validators.required],
    RCBookPictureBackURL : ['' , Validators.required],
    truckRegistrationNumberStateCode : ['' , [Validators.required,Validators.maxLength(2)]],
    truckRegistrationNumberCityCode : ['' , [Validators.required,,Validators.maxLength(2)]],
    truckRegistrationNumberNumber : ['' , [Validators.required,,Validators.maxLength(2)]],
    truckRegistrationNumberSeries : ['' , [Validators.required,,Validators.maxLength(4)]],
  });
  get f() { return this.utBasicDetailsForm.controls; }
  utBasicDetails() {
    
    this.requestData = {
      clientId : this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret,
      supplierId : this.supplierId,
      truckId : this.truckId,
      truckType : this.utBasicDetailsForm.get("truckType").value,
      truckOwnerName : this.utBasicDetailsForm.get("truckOwnerName").value,
      truckOwnerMobileNumber : this.utBasicDetailsForm.get("truckOwnerMobileNumber").value,
      truckRegistrationNumberStateCode : this.utBasicDetailsForm.get("truckRegistrationNumberStateCode").value,
      truckRegistrationNumberCityCode : this.utBasicDetailsForm.get("truckRegistrationNumberCityCode").value,
      truckRegistrationNumberNumber : this.utBasicDetailsForm.get("truckRegistrationNumberNumber").value,
      truckRegistrationNumberSeries : this.utBasicDetailsForm.get("truckRegistrationNumberSeries").value,
      RCBookPictureFrontURL : this.utBasicDetailsForm.get("RCBookPictureFrontURL").value,
      RCBookPictureBackURL : this.utBasicDetailsForm.get("RCBookPictureBackURL").value,
    }
    console.log(this.requestData);

    return this.tfs.updateTruckBasicDetails(JSON.stringify(this.requestData)).subscribe(data => {
      
      this.utobdRes = data;
      console.log(this.utobdRes);
      
      if(this.utobdRes.status == 1){
        this.showNotification('top','right',this.utobdRes.message, '1');
        document.getElementById("utBasic").style.display = "none";
        //this.back();
      }

    },error => console.log(error));

  }
  
  utOwnerBankDetailsForm = this.fb.group({
    supplierBankDetailsId : ['' , Validators.required], 
    IFSCCode : ['' , Validators.required],
    bankName : ['' , Validators.required],
    bankAccountNumber : ['' , Validators.required],
    bankAccountName : ['' , Validators.required],
    cancelChequePhoto : ['' , Validators.required],
    bankProofPhotoURL : ['' , Validators.required],
    verificationStatus : ['' , Validators.required],
  });
  get utb() { return this.utOwnerBankDetailsForm.controls; } 
  utOwnerBankDetails() {
    
    this.requestData = {
      clientId : this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret,
      supplierId : this.supplierId,
      truckId : this.truckId,
      supplierBankDetailsId : this.utOwnerBankDetailsForm.get("supplierBankDetailsId").value,
      IFSCCode : this.utOwnerBankDetailsForm.get("IFSCCode").value,
      bankName : this.utOwnerBankDetailsForm.get("bankName").value,
      bankAccountNumber : this.utOwnerBankDetailsForm.get("bankAccountNumber").value,
      bankAccountName : this.utOwnerBankDetailsForm.get("bankAccountName").value,
      cancelChequePhoto : this.utOwnerBankDetailsForm.get("cancelChequePhoto").value,
      verificationStatus : 1,
    }
    console.log(this.requestData);

    return this.tfs.updateTruckOwnerBankDetails(JSON.stringify(this.requestData)).subscribe(data => {
      
      this.utBankRes = data;
      console.log(this.utBankRes);
      
      if(this.utBankRes.status == 1){
        this.showNotification('top','right',this.utBankRes.message, '1');
        document.getElementById("utBank").style.display = "none";
        //this.back();
      }

    },error => console.log(error));

  }

  utInsuranceDetailsForm = this.fb.group({
    truckInsuranceId : ['' , Validators.required], 
    insuranceCompany : ['' , Validators.required],
    insuranceNumber : ['' , Validators.required],
    insuranceExpiryDate : ['' , Validators.required],
    insurancePicture : ['' , Validators.required],
  });
  get uti() { return this.utInsuranceDetailsForm.controls; }
  utInsuranceDetails() {
    
    this.iExpiryDateUp = this.datePipe.transform(this.utInsuranceDetailsForm.get("insuranceExpiryDate").value, 'yyyy-MM-dd hh:mm:ss');

    this.requestData = {
      clientId : this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret,
      supplierId : this.supplierId,
      truckId : this.truckId,
      truckInsuranceId : this.utInsuranceDetailsForm.get("truckInsuranceId").value,
      insuranceCompany : this.utInsuranceDetailsForm.get("insuranceCompany").value,
      insuranceNumber : this.utInsuranceDetailsForm.get("insuranceNumber").value,
      insuranceExpiryDate : this.iExpiryDateUp,
      insurancePicture : this.utInsuranceDetailsForm.get("insurancePicture").value,
    }
    console.log(this.requestData);

    return this.tfs.updateTruckInsuranceDetails(JSON.stringify(this.requestData)).subscribe(data => {
      
      this.utInsuranceRes = data;
      console.log(this.utInsuranceRes);
      
      if(this.utInsuranceRes.status == 1){
        this.showNotification('top','right',this.utInsuranceRes.message, '1');
        document.getElementById("utInsurance").style.display = "none";
        //this.back();
      }

    },error => console.log(error));

  }

  utOwnerPanDetailsForm = this.fb.group({
    panCardId : ['' , Validators.required], 
    nameOnPanCard : ['' , Validators.required],
    numberOnPanCard : ['' , [Validators.required, Validators.maxLength(10)]],
    panFrontPictureURL : ['' , Validators.required],
  });
  get utp() { return this.utOwnerPanDetailsForm.controls; }
  utOwnerPanDetails() {
    
    this.requestData = {
      clientId : this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret,
      supplierId : this.supplierId,
      truckId : this.truckId,
      panCardId : this.utOwnerPanDetailsForm.get("panCardId").value,
      nameOnPanCard : this.utOwnerPanDetailsForm.get("nameOnPanCard").value,
      numberOnPanCard : this.utOwnerPanDetailsForm.get("numberOnPanCard").value,
      panFrontPictureURL : this.utOwnerPanDetailsForm.get("panFrontPictureURL").value,
    }
    console.log(this.requestData);

    return this.tfs.updateTruckOwnerPanDetails(JSON.stringify(this.requestData)).subscribe(data => {
      
      this.utoPanRes = data;
      console.log(this.utoPanRes);
      
      if(this.utoPanRes.status == 1){
        this.showNotification('top','right',this.utoPanRes.message, '1');
        document.getElementById("utPanCard").style.display = "none";
        //this.back();
      }

    },error => console.log(error));

  }

  documentFor : any;
  chequePhotoImg;
  upImageUrl : any;
  paramPass : any;
  paramPassFinal : any;
  truckBankBlk = true;
  truckImageUpload = false;
  
  imageUploadShow(paramPassVal) {
    this.documentFor = "truck";
    this.supplierId = this.supplierId;
    this.truckId = this.truckId;
    this.paramPass = paramPassVal;
    console.log(this.supplierId + " ~~~~ " + this.truckId);

    this.tuBack = false;
    this.tuBlk = false;
    this.truckImageUpload = true;
  }

  hideImageupload(data){

    if(data != null){
      
        console.log(data);
        
        this.upImageUrl = data.finalImageUp;
        this.paramPassFinal = data.paramPassFinal;
        
        if(this.paramPassFinal == "RCBookPictureFrontURL"){
          this.utBasicDetailsForm.patchValue({
            'RCBookPictureFrontURL' : this.upImageUrl,
          });
        }
        
        if(this.paramPassFinal == "RCBookPictureBackURL"){
          this.utBasicDetailsForm.patchValue({
            'RCBookPictureBackURL' : this.upImageUrl,
          });
        }

        if(this.paramPassFinal == "bankProofPhotoURL"){
          this.utOwnerBankDetailsForm.patchValue({
            'bankProofPhotoURL' : this.upImageUrl,
          });
        }

        if(this.paramPassFinal == "cancelChequePhoto"){
          this.utOwnerBankDetailsForm.patchValue({
            'cancelChequePhoto' : this.upImageUrl,
          });
        }

        if(this.paramPassFinal == "insurancePicture"){
          this.utInsuranceDetailsForm.patchValue({
            'insurancePicture' : this.upImageUrl,
          });
        }

        if(this.paramPassFinal == "panFrontPictureURL"){
          this.utOwnerPanDetailsForm.patchValue({
            'panFrontPictureURL' : this.upImageUrl,
          });
        }

    }
    
    this.tuBack = true;
    this.tuBlk = true;
    this.truckImageUpload = false;
    
  }

  keyPressForNumber(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  keyPressForName(event: any){
    var filter = /[a-zA-Z]/;
    var ch = String.fromCharCode(event.keyCode);
    if(!filter.test(ch)){
         event.returnValue = false;
    }
  }

  keyPressChar(event: any) {
    const pattern = /[a-zA-Z ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  
  keyPressCharNotStartingSpace(event: any) {
    const pattern = /^[a-zA-Z ]/;
    let inputChar = String.fromCharCode(event.charCode);

    if((event.keyCode != 8 && !pattern.test(inputChar)) || (event.which === 32 && !event.target.value.length)) {
      //alert(inputChar);
      event.preventDefault();
    }
  }

  keyPressNumberNotStartingSpace(event: any) {
    const pattern = /^[0-9 ]/;
    let inputChar = String.fromCharCode(event.charCode);

    if((event.keyCode != 8 && !pattern.test(inputChar)) || (event.which === 32 && !event.target.value.length)) {
      //alert(inputChar);
      event.preventDefault();
    }
  }

  keyPressCharNumberNotStartingSpace(event: any) {
    const pattern = /^[a-zA-Z0-9 ]/;
    let inputChar = String.fromCharCode(event.charCode);

    if((event.keyCode != 8 && !pattern.test(inputChar)) || (event.which === 32 && !event.target.value.length)) {
      //alert(inputChar);
      event.preventDefault();
    }
  }

  showNotification(from, align, messageDis, errorNo) {
    
    // const color = Math.floor(Math.random() * 5 + 1);
    const color = errorNo;
    
    //console.log(errorNo);

    switch (color) {
      
      case '1':
        this.toastr.success(
          '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">'+messageDis+'</span>',
          "",
          {
            timeOut: 500,
            // closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-success alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;
      
        case '2':
        this.toastr.warning(
        '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">'+messageDis+'</span>',
          "",
          {
            timeOut: 4000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-danger alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;
      
        default:
        break;
    }

  }

  close(blockId){
    document.getElementById(blockId).style.display = "none";
  }

  back(){
    this.hideTruckDetailUpdate.emit();
  }

}
