import { Component, OnInit , ViewChild, Input, Output, EventEmitter} from '@angular/core';
import { FormGroup , FormBuilder , Validators } from '@angular/forms';
import { APiProperties } from '../../class/api-properties';
import { TruckieFunctionsService } from 'app/service/truckie-functions.service';
import { TruckModelService } from "../../service/truck-model.service";
import { zip } from 'rxjs';
import { DatePipe,formatDate } from '@angular/common';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-truck-bank-add',
  templateUrl: './truck-bank-add.component.html',
  styleUrls: ['./truck-bank-add.component.css']
})
export class TruckBankAddComponent implements OnInit {

  @Input() supplierId;
  @Input() truckId;
  @Output() hideTruckBankAdd = new EventEmitter();

  apiProperties : APiProperties = new APiProperties();
  
  constructor(private fb: FormBuilder, 
              private tfs : TruckieFunctionsService,
              private tms : TruckModelService,
              private datePipe: DatePipe, 
              private toastr: ToastrService) { }

  tbaBack;  
  tbaBlk; 
  truckImageUpload;
  tBankAdd;       
  ngOnInit(): void {
    this.tBankAdd = true;
    this.tbaBack = true;
    this.tbaBlk = true;
  }
  
  truckBankAddForm = this.fb.group({
    IFSCCode : ['' , Validators.required],
    bankName : ['' , Validators.required],
    bankAccountNumber : ['' , Validators.required],
    bankAccountName : ['' , Validators.required],
    cancelChequePhoto : ['' , Validators.required],
  });
  get utb() { return this.truckBankAddForm.controls; } 

  submitted;
  requestData : any = {};
  toBankRes : any = [];
  tBankProgreess;
  truckBankAdd() {
    
    this.tBankAdd = false;
    this.tBankProgreess = true;

    this.submitted = true;

    if(this.truckBankAddForm.invalid){
      this.tBankAdd = true;
      this.tBankProgreess = false;
      return true;
    }

    this.requestData = {
      supplierId : this.supplierId,
      truckId : this.truckId,
      IFSCCode : this.truckBankAddForm.get("IFSCCode").value,
      bankName : this.truckBankAddForm.get("bankName").value,
      bankAccountNumber : this.truckBankAddForm.get("bankAccountNumber").value,
      bankAccountName : this.truckBankAddForm.get("bankAccountName").value,
      cancelChequePhoto : this.truckBankAddForm.get("cancelChequePhoto").value,
      verificationStatus : 1,
    }
    console.log(this.requestData);

    return this.tfs.addTruckBank(JSON.stringify(this.requestData)).subscribe(data => {
      
      this.toBankRes = data;
      console.log(this.toBankRes);
      
      if(this.toBankRes.status == 1){
        this.tBankAdd = true;
        this.tBankProgreess = false;
        this.showNotification('top','right',this.toBankRes.message, '1');
        this.hideTruckBankAdd.emit({"tBankId": this.toBankRes.panCardId});
      } else {
        this.tBankAdd = true;
        this.tBankProgreess = false;
        this.showNotification('top','right',this.toBankRes.message, '3');
      }

    },error => console.log(error));

  }

  documentFor : any;
  paramPass : any;
  upImageUrl : any;
  paramPassFinal : any;

  imageUploadShow(paramPassVal) {
    this.documentFor = "truck";
    this.supplierId = this.supplierId;
    this.truckId = this.truckId;
    this.paramPass = paramPassVal;
    console.log(this.supplierId + " ~~~~ " + this.truckId);

    this.tbaBack = false;
    this.tbaBlk = false;
    this.truckImageUpload = true;
  }

  hideImageupload(data){

    if(data != null){
      
        console.log(data);
        
        this.upImageUrl = data.finalImageUp;
        this.paramPassFinal = data.paramPassFinal;

        if(this.paramPassFinal == "cancelChequePhoto"){
          this.truckBankAddForm.patchValue({
            'cancelChequePhoto' : this.upImageUrl,
          });
        }

    }
    
    this.tbaBack = true;
    this.tbaBlk = true;
    this.truckImageUpload = false;
    
  }

  showNotification(from, align, messageDis, errorNo) {
    
    // const color = Math.floor(Math.random() * 5 + 1);
    const color = errorNo;
    
    //console.log(errorNo);

    switch (color) {
      
      case '1':
        this.toastr.success(
          '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">'+messageDis+'</span>',
          "",
          {
            timeOut: 500,
            // closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-success alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;
      
        case '2':
        this.toastr.warning(
        '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">'+messageDis+'</span>',
          "",
          {
            timeOut: 4000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-warning alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;

        case '3':
        this.toastr.error(
        '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">'+messageDis+'</span>',
          "",
          {
            timeOut: 4000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-danger alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;
      
        default:
        break;
    }

  }

  back(){
    this.hideTruckBankAdd.emit();
  }

}
