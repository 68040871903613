import { Component, OnInit, ViewChildren, ViewChild, QueryList, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, AbstractControl } from '@angular/forms';
import { TruckieFunctionsService } from 'app/service/truckie-functions.service';
import { APiProperties } from 'app/class/api-properties';

@Component({
  selector: 'app-interested-participant',
  templateUrl: './interested-participant.component.html',
  styleUrls: ['./interested-participant.component.css']
})
export class InterestedParticipantComponent implements OnInit {
  apiProperties : APiProperties = new APiProperties();
  @ViewChild('closeParticipantListModal') closeParticipantListModal : ElementRef;

  constructor(public fb: FormBuilder, 
    private tfs : TruckieFunctionsService) { }
  
  branchUserDetected;
  ngOnInit(): void {
    this.fromCityId= "";
    this.toCityId= "";
    this.branchId= "";

    if(localStorage.getItem('userRole') == 'BranchUser'){
      this.branchId= localStorage.getItem('branchLoginId');
      this.branchUserDetected = false;
    } else {
      this.branchId= "";
      this.branchUserDetected = true;
    }

    this.fetch_city_master();
    this.fetch_master_branch();
    this.listOfUserWhoInterestedAgainstLoad();

  }

  cityMaster: any=[];
  fetch_city_master(){
    return this.tfs.fetch_city_master().subscribe(data => {
        this.cityMaster = data;
        console.log(this.cityMaster);
    }, error => console.log(error));
  }

  masterBranchRes : any = [];
  masterBranch: any=[];
  fetch_master_branch(){

    if(localStorage.getItem('userRole') == "SUB_ADMIN"){
      this.requestData = {
        adminId : localStorage.getItem('adminIdLocal')
      }
    } else {
      this.requestData = {
        adminId : ""
      }
    }
    console.log(this.requestData);

    this.tfs.fetch_master_branch(JSON.stringify(this.requestData)).subscribe((data : {}) => {
      this.masterBranchRes = data;
      this.masterBranch = this.masterBranchRes.list;
      console.log(this.masterBranch);
      
    }, error => console.log(error));
  }

  fromCityId: any;
  toCityId: any;
  branchId: any;
  onChange(e,where){
    if(where == 'from'){
      this.fromCityId = e;
    }
    if(where == 'to'){
      this.toCityId = e;
    }
    if(where == 'branch'){
      this.branchId = e;
    }
    this.listOfUserWhoInterestedAgainstLoad();
  }

  p: number = 1;
  pPass: number = 0;
  onPageChange(number: number) {
    this.p = number;
    this.pPass = (number - 1) * 10;
    alert(this.p);
    alert(this.pPass);
    this.listOfUserWhoInterestedAgainstLoad();
  }

  requestData: any={};
  listOfUserWhoInterestedAgainstLoadRes: any=[];
  displayPagination = false;
  listOfUserWhoInterestedAgainstLoad() {

    this.requestData = {
      clientId : this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret,
      fromCityId : this.fromCityId,
      toCityId : this.toCityId,
      branchId : this.branchId,
      page: this.p, //2,3,4...
      limit: 10
    }
    console.log(this.requestData);

    return this.tfs.listOfUserWhoInterestedAgainstLoad(JSON.stringify(this.requestData)).subscribe(data => {
      this.listOfUserWhoInterestedAgainstLoadRes = data;

      if(this.listOfUserWhoInterestedAgainstLoadRes.status == '1'){
        console.log(this.listOfUserWhoInterestedAgainstLoadRes);
        this.displayPagination = true;
      } else {
        this.displayPagination = false;
      }

    },error => console.log(error));

  }

  pData: any=[];
  listOfSupplier: any=[];
  participantListShow(pData){
    this.pData = pData;
    this.listOfSupplier = pData.listOfSupplier;
  }

}