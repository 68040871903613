import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable,throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { APiProperties } from 'app/class/api-properties';

@Injectable({
  providedIn: 'root'
})
export class BranchUserManagementService {

  constructor(private http:HttpClient) { }

  apiProperties : APiProperties = new APiProperties();

  getBranchUserDetailsHttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  getBranchUserDetails(data : {}) : Observable<{}> {
    //console.log("getBranchUserDetails service :"+data);
    return this.http.post(this.apiProperties.truckiefunctionsApi+'fetch_list_of_users_in_branch', data, this.getBranchUserDetailsHttpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  saveBranchUserDetailsHttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  saveBranchUserDetails(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'update_user_of_branch', data, this.saveBranchUserDetailsHttpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  forgetPasswordBranchUserHttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  forgetPasswordBranchUser(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'forgot_password_branch_user', data, this.forgetPasswordBranchUserHttpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  passwordSmsSendHttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  passwordSmsSend(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'sms_send', data, this.passwordSmsSendHttpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  handleError(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
 }

}
