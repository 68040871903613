import { Component, OnInit , ViewChild, Input, Output, EventEmitter} from '@angular/core';
import { FormGroup , FormBuilder , Validators } from '@angular/forms';
import { DatePipe,formatDate } from '@angular/common';
import { ToastrService } from "ngx-toastr";
import { APiProperties } from '../../class/api-properties';
import { TruckieApiService } from 'app/service/truckie-api.service';
import { RestApiService } from "../../rest-service/rest-api.service";
import { BulkUploadService } from 'app/service/bulk-upload.service';

@Component({
  selector: 'app-bulk-upload',
  templateUrl: './bulk-upload.component.html',
  styleUrls: ['./bulk-upload.component.css']
})
export class BulkUploadComponent implements OnInit {

  @ViewChild('fileUplaod') fileUplaod: any;

  constructor(private fb: FormBuilder, 
              private datePipe: DatePipe,
              private tas: TruckieApiService,
              private buService : BulkUploadService) { }
  
  file : File = null;
  responseData : any = [];
  ngOnInit(): void {
  }

  sample(){
    var URL="https://truckie-staging.s3.ap-south-1.amazonaws.com/BulkUpload.xlsx";
		   window.open(URL);
  }

  fileChangeEvent(event){
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
    }
  }

  uploadBulkLoad(){
    
    if(this.file == null){
      alert("Please uplaod file!");
      return;
    }

    const formData = new FormData();
    formData.append("file", this.file, this.file.name);

    this.tas.uploadBulkLoad(formData).subscribe((data : {})=>{

      this.responseData = data;

      alert(this.responseData.message);
      
      this.fileUplaod.nativeElement.value='';
      this.file = null;

    }, error => console.log("uploadBulkLoad Error: "+error));
  }
}
