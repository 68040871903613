import { Component, OnInit } from '@angular/core';
import { FormBuilder , FormGroup , Validators } from '@angular/forms';
import { SupplierDetails } from 'app/class/supplier-details';
import { BranchService } from 'app/service/branch.service';
import { SupplierListService } from 'app/service/supplier-list.service';

@Component({
  selector: 'app-supplier-list',
  templateUrl: './supplier-list.component.html',
  styleUrls: ['./supplier-list.component.css']
})
export class SupplierListComponent implements OnInit {

  supplieDetails : SupplierDetails;

  constructor(private formBuilder: FormBuilder,private branchService : BranchService,private supplierListService : SupplierListService) { }

  requestData : any = {};
  responseData : any = [];
  City : any = [];

  displayTable;
  displaySingleView;
  dataNotFound;
  loading;
  serverError;

  supplierListForm;
  citySelectedListVal : any;

  ngOnInit(): void {

    this.displayTable = false;
    this.displaySingleView = false;
    this.dataNotFound=false;
    this.loading = true;
    this.serverError = false;

    this.supplierListForm = this.formBuilder.group({
      mappingCity : ['' , Validators.required],
    });

    this.supplierListForm.get("mappingCity").valueChanges.subscribe(cityIdValue  => {
      
      //this.citySelectedListVal = this.supplierListForm.get('mappingCity').value;
      //console.log(this.citySelectedListVal);
      //console.log(cityIdValue[0]);

      if(cityIdValue[0] == ""){
        this.displayTable = false;
      } else {
        this.fetchSupplierList(cityIdValue[0]);
        this.displayTable = true;

        //this.specificeDateOpt = false;
        //this.getExecutiveLogStatus();
      }

    }); 

    this.getCity();
  }

  getCity(){
    return this.branchService.getCity().subscribe((data : {}) => {
        this.City = data;
    }, error => console.log(error));
  }

  fetchSupplierList(cityId){

    this.requestData = {
      supplierCityId : cityId
    };

    console.log(this.requestData);

    this.supplierListService.getListOfSupplierPerCity(JSON.stringify(this.requestData)).subscribe((data:{})=>{

      this.responseData = data;

      if(this.responseData.status==1){

        if(this.responseData.list.length==0){
          this.dataNotFound=true;
          this.loading = false;
        }
        else{
          this.loading = false;
        }
      }
      else{

        this.dataNotFound=true;
        this.loading = false;
      }
    },error => {
      this.serverError = true;
      console.log("Error: "+error);
    });


  }

  onChangeGetValue(event){

    this.displayTable = true;

    const value = event.target.value;

    console.log(value);
    
    this.requestData = {
      supplierCityId : value
    };
    this.supplierListService.getListOfSupplierPerCity(JSON.stringify(this.requestData)).subscribe((data:{})=>{

      this.responseData = data;

      if(this.responseData.status==1){

        if(this.responseData.list.length==0){
          this.dataNotFound=true;
          this.loading = false;
        }
        else{
          this.loading = false;
        }
      }
      else{

        this.dataNotFound=true;
        this.loading = false;
      }
    },error => {
      this.serverError = true;
      console.log("Error: "+error);
    });

  }

  singleView(data){

    this.supplieDetails = new SupplierDetails();
    this.supplieDetails.supplierId = data.supplierId;
    this.supplieDetails.supplierNumber = data.supplierNumber;
    this.supplieDetails.supplierName = data.supplierName;
    this.supplieDetails.supplierCity = data.supplierCityName;

    if(data.supplierType ==1){
      this.supplieDetails.supplierType = 'DCO';
    }
    else if(data.supplierType ==2){
      this.supplieDetails.supplierType = 'Broker';
    }
    else if(data.supplierType ==3){
      this.supplieDetails.supplierType = 'Both';
    }

    if(data.supplierStatus ==0){
      this.supplieDetails.supplierStatus = 'Disable';
    }
    else if(data.supplierStatus ==1){
      this.supplieDetails.supplierStatus = 'Active';
    }
    else if(data.supplierStatus ==2){
      this.supplieDetails.supplierStatus = 'Suspend';
    }
    else if(data.supplierStatus ==3){
      this.supplieDetails.supplierStatus = 'In Approval';
    }


    this.displaySingleView = true;

    localStorage.setItem("singleView","true");

  }

  back(){
    //this.location.back();

    if(localStorage.getItem("singleView") == "true"){

      if(localStorage.getItem("walletRecharge") == "true"){
        
        localStorage.setItem("walletRecharge","false");
      }
      else{
        this.displaySingleView = false;
        localStorage.setItem("singleView","false");
      }
      
    }
  
  }
  
}
