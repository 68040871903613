import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-load',
  templateUrl: './load.component.html',
  styleUrls: ['./load.component.css']
})
export class LoadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    
    //console.log(localStorage.getItem('userRole'));
    localStorage.removeItem('loadId');
    localStorage.removeItem('fromCityName');
    localStorage.removeItem('toCityName');
    localStorage.removeItem('branchName');

    if(window.localStorage ){

        if( !localStorage.getItem('firstLoad') )
        {
          localStorage['firstLoad'] = true;
        //  window.location.reload();
        }  
        else {
          localStorage.removeItem('firstLoad');
        }
          
    }
    
    
    
  }

}
