import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable,throwError,forkJoin } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { APiProperties } from 'app/class/api-properties';

@Injectable({
  providedIn: 'root'
})
export class ViewBidCompletedLoadService {

  constructor(private http:HttpClient) { }

  apiProperties : APiProperties = new APiProperties();

  getViewBidCompletedLoadHttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  getViewBidCompletedLoad() : Observable<{}> {
    return this.http.get<any>(this.apiProperties.truckiefunctionsApi+'view_load_for_bidding_completed', this.getViewBidCompletedLoadHttpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  getCountOfBidPerLoadHttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  getLowestBidPerLoadHttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  countOfBidPerLoad : any=[];
  lowestBidPerLoad : any=[];
  countLowestBidData=[];
  getCountOfBidPerLoad(data : any[]) : Observable<any[]>{
   
  for(let i=0; i< data.length; i++){
    this.countOfBidPerLoad[i]= this.http.post(this.apiProperties.truckiefunctionsApi+'count_of_bids_per_load', data[i] ,this.getCountOfBidPerLoadHttpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }
  
  return forkJoin(this.countOfBidPerLoad);
}

  getLowestBidPerLoad(data : any[]): Observable<any[]> {
   
    for(let i=0; i< data.length; i++){
    
     this.lowestBidPerLoad[i]= this.http.post(this.apiProperties.truckiefunctionsApi+'lowest_bid', data[i] ,this.getLowestBidPerLoadHttpOptions).pipe(
        retry(1),
        catchError(this.handleError)
      )

    }
    
    return forkJoin(this.lowestBidPerLoad);
  }

  handleError(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
 }
}
