import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable,throwError,forkJoin  } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { APiProperties } from 'app/class/api-properties';

@Injectable({
  providedIn: 'root'
})
export class TruckieFunctionsService {

  constructor(private http:HttpClient) { }
  apiProperties : APiProperties = new APiProperties();

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
      //'x-api-key' : this.apiProperties.submitSupplyBidKey
    })
  }

  submitSupplyBidForLoad(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'submitSupplyBidForLoad', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  resubmitSupplyBidForLoad(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'resubmitSupplyBidForLoad', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  fetchListOfAllLoads(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'fetchListOfAllLoads', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  listOfSupplierBidsPerLoad(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'listOfSupplierBidsPerLoad', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  truckAvailabilityPerBidPerLoad(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'truckAvailabilityPerBidPerLoad', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }
  
  loadDetailsForAdmin(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'loadDetailsForAdmin', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  detailsOfLoad(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'detailsOfLoad', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  countWeightSupplierPerLoad : any=[];
  countWeightProvidedBySupplierAsPerLoad(data : any[]) : Observable<any[]> {
    console.log("service: "+data);
    console.log("data length :"+data.length);
   
    for(let i=0; i< data.length; i++){
      
      this.countWeightSupplierPerLoad[i]= this.http.post(this.apiProperties.truckiefunctionsApi+'count_weight_provided_by_supplier_as_per_load', data[i] ,this.httpOptions).pipe(
        retry(1),
        catchError(this.handleError)
      )
    }
  
    return forkJoin(this.countWeightSupplierPerLoad);
  }

  fetchSupplierList(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'fetchSupplierList', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  fetch_driver_details_staging(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'fetch_driver_details', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  updateDriverDetails(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'updateDriverDetails', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  getTruckBasicDetailsOfSupplier(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'get_specific_truck_basic_details_of_a_supplier', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  getTruckOwnerBankDetails(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'get_specific_truck_owner_bank_details', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  getTruckInsuranceDetailsOfSupplier(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'get_specific_truck_insurance_details_of_a_supplier', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  getTruckOwnerPanDetails(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'get_specific_truck_owner_pan_details', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  updateTruckBasicDetails(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'updateTruckBasicDetails', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  updateTruckOwnerBankDetails(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'updateTruckOwnerBankDetails', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  updateTruckInsuranceDetails(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'updateTruckInsuranceDetails', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  updateTruckOwnerPanDetails(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'updateTruckOwnerPanDetails', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  addMaterialType(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'addMaterialType', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  fetchMaterialWithStatus(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'fetchMaterialWithStatus', data, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  updateMaterialType(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'updateMaterialType', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  updateMaterialMaster(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'updateMaterialMaster', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  updateDriverDetailStatus(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'updateDriverDetailStatus', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  updateTruckDetailStatus(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'updateTruckDetailStatus', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  updateBankDetailStatus(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'updateBankDetailStatus', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  addTruckPanCard(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'add_truck_owner_pan_details', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  addTruckInsurance(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'add_truck_insurance', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  addTruckBank(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'add_truck_owner_bank_details', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  fetchStateList(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'fetchStateList', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  fetchCityListBasedOnStateWithStatus(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'fetchCityListBasedOnStateWithStatus', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  addCity(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'addCity', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  updateCityStatus(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'updateCityStatus', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }
  
  updateCity(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'updateCity', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  createSupplierGroup(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'createSupplierGroup', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  fetchSupplierGroupList(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'fetchSupplierGroupList', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  updateGroupStatus(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'updateGroupStatus', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  deleteGroup(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'deleteGroup', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  updateSupplierGroupName(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'updateSupplierGroupName', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  deleteSupplierFromGroup(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'deleteSupplierFromGroup', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  add_supply_user_profile_staging(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'add_supply_user_profile', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  listOfUserWhoInterestedAgainstLoad(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'listOfUserWhoInterestedAgainstLoad', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  fetch_city_master() : Observable<{}> {
    return this.http.get<any>(this.apiProperties.truckiefunctionsApi+'fetch_city_master', this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  fetch_master_branch(data : {}){
    return this.http.post(this.apiProperties.truckiefunctionsApi+'fetch_master_branch', data, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  fetchListOfMappedGroupWithBranch(data : {}){
    return this.http.post(this.apiProperties.truckiefunctionsApi+'fetchListOfMappedGroupWithBranch', data, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  mappUnmappGroupWithBranch(data : {}){
    return this.http.post(this.apiProperties.truckiefunctionsApi+'mappUnmappGroupWithBranch', data, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  updateDispatchForBranch(data : {}){
    return this.http.post(this.apiProperties.truckiefunctionsApi+'updateDispatchForBranch', data, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  handleError(error) {
      let errorMessage = '';
      if(error.error instanceof ErrorEvent) {
        // Get client-side error
        errorMessage = error.error.message;
      } else {
        // Get server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      window.alert(errorMessage);
      return throwError(errorMessage);
  }

}