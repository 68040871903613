import { Component, OnInit, ViewChildren, ViewChild, QueryList, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, AbstractControl } from '@angular/forms';
import { TruckieFunctionsService } from 'app/service/truckie-functions.service';
import { APiProperties } from 'app/class/api-properties';
import { DatePipe,formatDate } from '@angular/common';
import { ToastrService } from "ngx-toastr";
import { TruckieApiService } from 'app/service/truckie-api.service';

@Component({
  selector: 'app-create-role',
  templateUrl: './create-role.component.html',
  styleUrls: ['./create-role.component.css']
})
export class CreateRoleComponent implements OnInit {

  apiProperties : APiProperties = new APiProperties();
  @ViewChild('closeDeleteGroupModal') closeDeleteGroupModal: ElementRef;
  @ViewChild('closeUpdateGroupNameModal') closeUpdateGroupNameModal: ElementRef;

  constructor(private fb: FormBuilder, 
              private tfs : TruckieFunctionsService,
              private datePipe: DatePipe, 
              private toastr : ToastrService,
              private ta : TruckieApiService) { }

  submitted;
  isViewable;
  slGroupAddBack;
  slGroupAddBlk;
  slGroupListTbl;
  dataNotFound;
  addSupplier;
  public settings = {};

  ngOnInit(): void {

    this.slGroupAddBack = true;
    this.slGroupAddBlk = true;
    this.slGroupListTbl = true;

    this.submitted=false;
    this.isViewable = true;

    this.getSupplierList();
    this.getLoginRoleList();

    this.settings = {
      singleSelection: false,
      idField: 'supplierId',
      textField: 'sFullD',
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      limitSelection: -1,
      clearSearchFilter: true,
      maxHeight: 197,
      itemsShowLimit: 3,
      searchPlaceholderText: 'Search Supplier',
      noDataAvailablePlaceholderText: 'No data Vailable',
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false,
    };

    this.addSupplier = false;
  }

  p: number = 0;
  pPass: number = 0;
  onPageChange(number: number) {
    this.p = number;
    this.pPass = (number-1);
    var d = {
      roleMasterId : this.roleId
    }
    this.setGroupSupplierName(d,0);
  }

  createRoleGroupForm = this.fb.group({
    roleName : ['' , [Validators.required]]
  });

  updateGroupNameForm = this.fb.group({
    groupId : [""],
    groupName : ["",Validators.required]
  });

  createAccessLinkForm = this.fb.group({
    accessLink : [""]
  });

  get f(){ return this.createRoleGroupForm.controls; }
  get fEdit(){ return this.updateGroupNameForm.controls; }
  get al(){ return this.createAccessLinkForm.controls; }

  supplierGPLForm = this.fb.group({
    status : ['']
  });

  keyPressCharNotStartingSpace(event: any) {
    const pattern = /^[a-zA-Z0-9 ]/;
    let inputChar = String.fromCharCode(event.charCode);

    if((event.keyCode != 8 && !pattern.test(inputChar)) || (event.which === 32 && !event.target.value.length)) {
      //alert(inputChar);
      event.preventDefault();
    }
    
  }

  clSupplierRes : any = [];
  clSupplierListRes : any = [];
  clSupplierListResLength : any;
  loadContent = false;
  roleIdd : any;

  getSupplierList() {

    this.requestData = {
      clientId : this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret
    }

    return this.tfs.fetchSupplierList(JSON.stringify(this.requestData)).subscribe(data => {
      
      this.clSupplierRes = data;
      this.clSupplierListRes = this.clSupplierRes.list;
      //console.log(this.clSupplierListRes);
      this.clSupplierListResLength = this.clSupplierRes.list.length;
      console.log(this.clSupplierListResLength);

      for(var i=0; i<this.clSupplierListRes.length; i++){
        this.clSupplierRes.list[i].sFullD = this.clSupplierRes.list[i].supplierName+" - "+this.clSupplierRes.list[i].supplierNumber; 
      }

      if(this.clSupplierRes.status == '1'){
        this.loadContent = true;
      }

    },error => console.log(error));

  }

  supplierIdData = [];
  public onItemSelect(item: any) {
    //console.log(item);
    //this.supplierIdData.push(item.supplierId);
    this.supplierIdData.push({'supplierId':item.supplierId});
    //console.log(this.supplierIdData);
  }
  
  public onDeSelect(item: any) {
    console.log(item);
    for(var i=0; i < this.supplierIdData.length;i++){
      //if(this.supplierIdData[i] == item.supplierId){
      if(this.supplierIdData[i].supplierId == item.supplierId){  
          console.log(this.supplierIdData[i]);
          this.supplierIdData.splice(i,1);
      }
    }
    //console.log(this.supplierIdData);
  }

  public onSelectAll(items: any) {
    this.supplierIdData = [];
    for(var mp in items){
      //this.supplierIdData.push(items[mp].supplierId);
      this.supplierIdData.push({'supplierId':items[mp].supplierId});
    }
    //console.log(this.supplierIdData);
  }

  public onDeSelectAll(items: any) {
    console.log(items);
    this.supplierIdData = [];
  }

  addSupplierBlkShow(){
    this.addSupplier = true;
  }

  updatedSupplierIdData;
  SupplierIdCheck;
  getSelectedSupplierText(event){

    let selectedElementValue = event.target.value;
8
    let selectElementText = event.target['options']
      [event.target['options'].selectedIndex].text;

    console.log(selectElementText);  

    this.SupplierIdCheck = selectedElementValue;

    this.updatedSupplierIdData = [];
    this.updatedSupplierIdData.push({'supplierId':selectedElementValue});

  }

  addAdminUrlAccess(param){

    console.log(this.createAccessLinkForm.controls['accessLink'].value);
    console.log("roleId : "+this.roleId);
      
    if(this.createAccessLinkForm.invalid){
        return;
     }

     if(this.createAccessLinkForm.controls['accessLink'].value=="/userManagement" || 
     this.createAccessLinkForm.controls['accessLink'].value=="/createRole" || 
     this.createAccessLinkForm.controls['accessLink'].value=="/reports" || 
     this.createAccessLinkForm.controls['accessLink'].value=="/configuration"){
      return this.showNotification('top','right',"Access of "+this.createAccessLinkForm.controls['accessLink'].value+" not Allowed for this Role", '2');
     }

     this.requestData = {
      clientId : this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret,
      roleId : this.roleIdd,
      accessType : "1",
      accessLink : this.createAccessLinkForm.controls['accessLink'].value,
      currentLoginUsername : localStorage.getItem('userNameLocal'),
      currentLoginPassword : localStorage.getItem('userPasswordLocal')
    }
      console.log(this.requestData);
  
      this.ta.addAdminUrlAccess(JSON.stringify(this.requestData)).subscribe((data : {}) => {
        this.deleteSpFromGpRes = data;
        console.log(JSON.stringify(this.deleteSpFromGpRes));
        
        if(this.deleteSpFromGpRes.status == 1){
          this.showNotification('top','right',this.deleteSpFromGpRes.message, '1');
          var d = {
            roleMasterId : this.roleId
          }
          this.setGroupSupplierName(d,0);
          this.addSupplier = false;
          this.createAccessLinkForm.controls['accessLink'].setValue('');
        }else{
          this.showNotification('top','right',this.deleteSpFromGpRes.message, '2');
        }
  
      }, error => console.log(error));

  }

  deleteSpFromGpRes : any = [];
  dataPass : any; 
  removeAdminUrlAccess(param){

    this.requestData = {
      clientId : this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret,
      roleAccessUserId : param.roleAccessUserId,
      adminId : localStorage.getItem('adminIdLocal'),
      accessLink : param.accessLink,
      currentLoginUsername : localStorage.getItem('userNameLocal'),
      currentLoginPassword : localStorage.getItem('userPasswordLocal')
    }
    console.log(this.requestData);

    this.ta.deleteAdminUrlAccess(JSON.stringify(this.requestData)).subscribe((data : {}) => {
      this.deleteSpFromGpRes = data;
      console.log(JSON.stringify(this.deleteSpFromGpRes));
      
      if(this.deleteSpFromGpRes.status == 1){
        this.showNotification('top','right',this.deleteSpFromGpRes.message, '1');
        var d = {
          roleMasterId : this.roleId
        }
        this.setGroupSupplierName(d,0);
      }else{
        this.showNotification('top','right',this.deleteSpFromGpRes.message, '2');
      }

    }, error => console.log(error));

  }

  partialListVal : any;
  slGroupRes : any = [];
  responseData : any = [];
  supplierListErr;
  saveRoleName(){

    this.submitted = true;

    if(this.createRoleGroupForm.invalid){
       return;
    }

    this.requestData = {
      clientId : this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret,
      roleName : this.createRoleGroupForm.controls['roleName'].value,
      currentLoginUsername : localStorage.getItem('userNameLocal'),
      currentLoginPassword : localStorage.getItem('userPasswordLocal')
    }
    console.log(this.requestData);

    // currentLoginUsername : localStorage.getItem('userNameLocal'),
    // currentLoginPassword : localStorage.getItem('userPasswordLocal')

    return this.ta.addAdminRole(JSON.stringify(this.requestData)).subscribe((data : {}) => {

      this.responseData = data;
      console.log(this.responseData);

      if(this.responseData.status == '1'){
        this.slGroupListTbl = true;
        this.submitted = false;
        this.supplierIdData = [];
        this.createRoleGroupForm.reset();
        window.location.reload();
        this.showNotification('top','right',this.responseData.message, '1');
        this.getLoginRoleList();
      } else {
        this.showNotification('top','right',this.responseData.message, '3');
        this.getLoginRoleList();
      }

    }, error => console.log(error));

  }

  requestData : any = {};
  slGroupListRes : any = [];
  accessLinkRes : any = [];
  statusVal : any;
  getLoginRoleList(){

    this.requestData = {
      clientId : this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret
    }
    console.log(this.requestData);

    return this.ta.getLoginRoleList(JSON.stringify(this.requestData)).subscribe((data : {}) => {

      this.slGroupListRes = data;
      console.log(this.slGroupListRes);

      if(this.slGroupListRes.status == '1'){
        this.slGroupListTbl = true;
        this.dataNotFound = false;
        //this.showNotification('top','right',this.slGroupListRes.message, '1');
      } else {
        this.dataNotFound = true;
        this.showNotification('top','right',this.slGroupListRes.message, '3');
      }

    }, error => console.log(error));
  }

  gpStatusRes : any = [];
  updateRoleStatusFun(roleId, status){

    //if(e.traget.checked){}
    this.requestData = {
      clientId : this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret,
      roleId : roleId,
      currentLoginUsername : localStorage.getItem('userNameLocal'),
      currentLoginPassword : localStorage.getItem('userPasswordLocal'),
      isActive : status
    };
    console.log(this.requestData);

    return this.ta.updateRoleStatus(JSON.stringify(this.requestData)).subscribe((data : {}) => {
      this.gpStatusRes = data;
      console.log("updateRoleStatus : res : "+this.gpStatusRes);
      if(this.gpStatusRes.status == 1){
        this.getLoginRoleList();
      } else {
        this.showNotification('top','right',this.gpStatusRes.message, '3');
      }

    }, error => console.log(error));

  }

  groupIdVal : any;
  groupNameVal : any;
  gpIdNameIndex : any;
  setGroupIdName(data,index,action){
    this.groupIdVal = data.roleMasterId;
    this.groupNameVal = data.role;
    this.gpIdNameIndex = index;

    if(action == 'Update'){
      this.updateGroupNameForm.controls.groupName.setValue(this.groupNameVal)
    }
  }

  deleteGroupRes : any = [];
  deleteRoleFun(){

    this.requestData = {
      clientId : this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret,
      roleId : this.groupIdVal,
      currentLoginUsername : localStorage.getItem('userNameLocal'),
      currentLoginPassword : localStorage.getItem('userPasswordLocal'),
    };

    console.log(this.requestData);

    this.ta.deleteAdminRole(JSON.stringify(this.requestData)).subscribe((data : {}) => {
      this.deleteGroupRes = data;
      console.log(JSON.stringify(this.deleteGroupRes));
      
      this.closeDeleteGroupModal.nativeElement.click();

      if(this.deleteGroupRes.status == 1){
        this.showNotification('top','right',this.deleteGroupRes.message, '1');
        this.getLoginRoleList();
      }else{
        this.showNotification('top','right',this.deleteGroupRes.message, '2');
      }

    }, error => console.log(error));

  }

  submittedEdit = false;
  updateSPGroupNameRes : any = [];
  updateSupplierGroupNameFun(){

    this.submittedEdit = true;
    if(this.updateGroupNameForm.invalid == true){
      return;
    }

    this.requestData = {
      groupId : this.groupIdVal,
      groupName : this.updateGroupNameForm.controls.groupName.value, 
    };
    console.log(this.requestData);

    this.tfs.updateSupplierGroupName(JSON.stringify(this.requestData)).subscribe((data:{})=>{

      this.updateSPGroupNameRes = data;

      this.submittedEdit = false;
      this.closeUpdateGroupNameModal.nativeElement.click();

      if(this.updateSPGroupNameRes.status == 1){
        this.showNotification('top','right',this.updateSPGroupNameRes.message, '1');
        this.getLoginRoleList();
      } else {
        this.showNotification('top','right',this.updateSPGroupNameRes.message, '3');
      }

    },error => console.log(error));

  }

  listOfSupplierRes : any;
  gsGroupIdVal : any;
  gsGroupNameVal : any;
  removeIndex : any;
  roleId : any;
  totalRecords : any;
  setGroupSupplierName(data,i){

    this.requestData = {
      clientId : this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret,
      displayLength : 5,
      displayStartFrom :this.pPass,
      roleId : data.roleMasterId
    }
    console.log(this.requestData);
    this.roleIdd = data.roleMasterId;

    return this.ta.getUserAccessURLList(JSON.stringify(this.requestData)).subscribe((data : {}) => {

      this.accessLinkRes = data;
      console.log(this.accessLinkRes);

      if(this.accessLinkRes.status == '1'){
        this.slGroupListTbl = true;
        this.dataNotFound = false;
        if(this.accessLinkRes.roleList.length!=0){
          this.roleId = this.accessLinkRes.roleList[0].roleId;
        }
        this.totalRecords = this.accessLinkRes.totalRecord;
        console.log("roleId : "+this.roleId);
        //this.showNotification('top','right',this.slGroupListRes.message, '1');
      } else {
        this.dataNotFound = true;
        this.showNotification('top','right',this.accessLinkRes.message, '3');
      }

    }, error => console.log(error));
    
  }

  showNotification(from, align, messageDis, errorNo) {
    
    //const color = Math.floor(Math.random() * 5 + 1);
    const color = errorNo;
    //console.log(errorNo);

    switch (color) {
      
        case '1':
        this.toastr.success(
          '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">'+messageDis+'</span>',
          "",
          {
            timeOut: 500,
            // closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-success alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;
      
        case '2':
        this.toastr.warning(
        '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">'+messageDis+'</span>',
          "",
          {
            timeOut: 4000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-danger alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;
      
        case '3':
        this.toastr.error(
        '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">'+messageDis+'</span>',
          "",
          {
            timeOut: 4000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-danger alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;

        default:
        break;
    }

  }

  searchTerm: string;

  updateSearch(event) {
    this.searchTerm = event.target.value;
  }

}
