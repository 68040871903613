import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TruckLoad, cityMaster, masterBranch, materialMaster, masterMaterialPackaging, singleLoad, FormResponseData} from '../rest-service/truckie';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { APiProperties } from 'app/class/api-properties';


@Injectable({
  providedIn: 'root'
})
export class RestApiService {
  
  constructor(private http: HttpClient) { }

  apiProperties : APiProperties = new APiProperties();

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }  
 
  fetch_truck_model_staging(): Observable<TruckLoad> {
    return this.http.get<TruckLoad>(this.apiProperties.truckiefunctionsApi+'fetch_truck_model' , this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  } 
  
  fetch_city_master_staging(): Observable<cityMaster> {
    return this.http.get<cityMaster>(this.apiProperties.truckiefunctionsApi+'fetch_city_master', this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  } 
  
  fetch_master_branch_staging(data : {}){
    return this.http.post(this.apiProperties.truckiefunctionsApi+'fetch_master_branch', data, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  fetch_material_master_staging(): Observable<materialMaster> {
    return this.http.get<materialMaster>(this.apiProperties.truckiefunctionsApi+'fetch_material_master', this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  } 
  
  fetch_master_material_packaging_staging(): Observable<masterMaterialPackaging> {
    return this.http.get<masterMaterialPackaging>(this.apiProperties.truckiefunctionsApi+'fetch_master_material_packaging', this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  creating_single_load_staging(data : {}) : Observable<{}> {
    //console.log("service: "+data);
    return this.http.post(this.apiProperties.truckiefunctionsApi+'creating_single_load', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  } 
  

  // Error handling 
  handleError(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
 }

}

