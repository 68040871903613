export class SupplierDetails {   

    supplierId : any;
    supplierNumber : any;
    supplierName : any;
    supplierCity : any;
    supplierType : any;
    supplierStatus : any;
  
}
