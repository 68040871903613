import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable,throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { APiProperties } from 'app/class/api-properties';

@Injectable({
  providedIn: 'root'
})
export class TruckModelService {

  constructor(private http:HttpClient) { }

  apiProperties : APiProperties = new APiProperties();

  getTruckManufatcureHttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  getTruckManufacture() : Observable<{}> {
    return this.http.get<any>(this.apiProperties.truckiefunctionsApi+'fetch_manufecturer' , this.getTruckManufatcureHttpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  saveTruckModelHttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  saveTruckModel(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'add_truck_model', data ,this.saveTruckModelHttpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  } 

  getTruckModelHttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  getTruckModel() : Observable<{}> {
    return this.http.get<{}>(this.apiProperties.truckiefunctionsApi+'fetch_truck_model',this.getTruckModelHttpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }  

  handleError(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
 }
}