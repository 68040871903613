import { Component, OnInit, Renderer2, ViewChild, ElementRef, Input } from '@angular/core';
import { ROUTES } from '../../sidebar/sidebar.component';
import { Router } from '@angular/router';
import { Location} from '@angular/common';

@Component({
    moduleId: module.id,
    selector: 'navbar-cmp',
    templateUrl: 'navbar.component.html',
    styleUrls: ['./navbar.component.css']
})

export class NavbarComponent implements OnInit{
    private listTitles: any[];
    location: Location;
    private nativeElement: Node;
    private toggleButton;
    private sidebarVisible: boolean;

    public isCollapsed = true;
    @ViewChild("navbar-cmp", {static: false}) button;

    constructor(location:Location, private renderer : Renderer2, private element : ElementRef, private router: Router) {
        this.location = location;
        this.nativeElement = element.nativeElement;
        this.sidebarVisible = false;
    }

    @Input('title') title : String;
    ngOnInit(){
      
        this.listTitles = ROUTES.filter(listTitle => listTitle);
        var navbar : HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
        this.router.events.subscribe((event) => {
          this.sidebarClose();
       });
    }

    logout(){

      console.log("Logout");
      localStorage.setItem('isLoggedIn', "false");
      //localStorage.removeItem('userRole');
      localStorage.setItem('userRole','');
      this.router.navigate(['/login']);
      //window.location.reload();
    }
    
    getTitle(){
      var titlee = this.location.prepareExternalUrl(this.location.path());
      if(titlee.charAt(0) === '#'){
          titlee = titlee.slice( 1 );
      }

      var vbspl=titlee.slice(1).split("/");

      if(titlee.slice(1) == "new-load"){
      
        return "Create New Load";
      }
      if(titlee.slice(1) == "clubLoad"){
      
        return "Create Club Load";
      }
      if(titlee.slice(1) == "truckCategory"){
      
        return "Manage Truck Category";
      }
      if(titlee.slice(1) == "branch"){
      
        return "Manage Branch";
      }
      if(titlee.slice(1) == "truckModel"){
      
        return "Manage Truck Model";
      }
      if(titlee.slice(1) == "viewLiveLoad"){
      
        return "View Live Load";
      }
      if(titlee.slice(1) == "viewBidCompletedLoad"){
      
        return "View Bid Completed Load";
      }
      if(titlee.slice(1) == "combineLoadView"){
        return "Combine Load View";
      }
      if(titlee.slice(1) == "supplierList"){
      
        return "Supplier List";
      }
      
      if(this.title == "viewBidsSupplierPerLoad"){
      
        return "View Bids Suppliers Per Load";
      }

      if(titlee.slice(1) == "bulkUpload"){
      
        return "Bulk Upload Load";
      }

      if(titlee.slice(1) == "bulkDriverUpload"){
      
        return "Bulk Upload Driver";
      }

      if(titlee.slice(1) == "bulkBankUpload"){
      
        return "Bulk Upload Bank";
      }

      if(titlee.slice(1) == "bulkTruckUpload"){
      
        return "Bulk Upload Truck";
      }

      if(titlee.slice(1) == "createRole"){
      
        return "Create Role";
      }
      if(titlee.slice(1) == "interestedParticipant"){ return "Interested Participant"; }

      for(var item = 0; item < this.listTitles.length; item++){
          if(this.listTitles[item].path === titlee){
              return this.listTitles[item].title;
          }
      }
      return 'Dashboard';
    }
    sidebarToggle() {
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
      }
      sidebarOpen() {
          const toggleButton = this.toggleButton;
          const html = document.getElementsByTagName('html')[0];
          const mainPanel =  <HTMLElement>document.getElementsByClassName('main-panel')[0];
          setTimeout(function(){
              toggleButton.classList.add('toggled');
          }, 500);

          html.classList.add('nav-open');
          if (window.innerWidth < 991) {
            mainPanel.style.position = 'fixed';
          }
          this.sidebarVisible = true;
      };
      sidebarClose() {
          const html = document.getElementsByTagName('html')[0];
          const mainPanel =  <HTMLElement>document.getElementsByClassName('main-panel')[0];
          if (window.innerWidth < 991) {
            setTimeout(function(){
              mainPanel.style.position = '';
            }, 500);
          }
          this.toggleButton.classList.remove('toggled');
          this.sidebarVisible = false;
          html.classList.remove('nav-open');
      };
      collapse(){
        this.isCollapsed = !this.isCollapsed;
        const navbar = document.getElementsByTagName('nav')[0];
        console.log(navbar);
        if (!this.isCollapsed) {
          navbar.classList.remove('navbar-transparent');
          navbar.classList.add('bg-white');
        }else{
          navbar.classList.add('navbar-transparent');
          navbar.classList.remove('bg-white');
        }

      }

}
