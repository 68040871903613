import { Component, OnInit,  Input, Output, ViewChildren, ViewChild, QueryList, ElementRef } from '@angular/core';
import { FormBuilder , FormGroup , Validators } from '@angular/forms';
import { TruckieFunctionsService } from '../../service/truckie-functions.service';
import { APiProperties } from 'app/class/api-properties';
import { DatePipe,formatDate } from '@angular/common';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-city-management',
  templateUrl: './city-management.component.html',
  styleUrls: ['./city-management.component.css']
})
export class CityManagementComponent implements OnInit {

  apiProperties : APiProperties = new APiProperties();
  @ViewChild('closeUpdateCityModal') closeUpdateCityModal: ElementRef;

  constructor(private fb: FormBuilder,
    private tfs : TruckieFunctionsService, 
    private toastr : ToastrService,
    private datePipe: DatePipe,) { }

  ngOnInit(): void {
    this.getStateList();
  }

  citySaveForm = this.fb.group({
    stateId : ['' , Validators.required], 
    cityName : ['' , Validators.required],
    latitude : ['' , Validators.required],
    longitude : ['' , Validators.required]
  });

  get f() { return this.citySaveForm.controls; }

  requestData : any = {};
  stateListRes : any = {};

  getStateList(){

    this.requestData = {
      clientId : this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret
    };

    return this.tfs.fetchStateList(JSON.stringify(this.requestData)).subscribe((data : {}) => {
        this.stateListRes = data;
    }, error => console.log(error));
  }

  cityListBlk = false;
  stateIdVal : any;
  stateIdM : any;
  cityByStateRes : any = [];
  getCityListBasedOnStateWithStatus(e,source){

    if(source == 'byHtml'){
      this.stateIdVal = e.target.value;
    } else {
      this.stateIdVal = e;
    }

    this.requestData = {
      clientId : this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret,
      stateId : this.stateIdVal
    };
    console.log(this.requestData);
    
    this.tfs.fetchCityListBasedOnStateWithStatus(JSON.stringify(this.requestData)).subscribe((data:{})=>{
        
        this.cityByStateRes = data;

        if(this.cityByStateRes.status == 1){
          this.stateIdM = this.stateIdVal;
          this.cityListBlk = true;
        } else {
          this.cityListBlk = false;
          this.showNotification('top','right',this.cityByStateRes.message, '3');
        }

    },error => {
      console.log("Error: "+error);
    });

  }

  setCityLatitudeLongitude(e){

    var onst = e.target.value;
    
    for(var m in this.cityByStateRes.list){

      if(onst == this.cityByStateRes.list[m].city_id){
        
        this.citySaveForm.controls['latitude'].setValue(this.cityByStateRes.list[m].city_latitude);
        this.citySaveForm.controls['longitude'].setValue(this.cityByStateRes.list[m].city_longitude);

      } else {
        //console.log("else");
      }
         
    }
    
  }

  keyPressCharNotStartingSpace(event: any) {
    const pattern = /^[a-zA-Z ]/;
    let inputChar = String.fromCharCode(event.charCode);

    if((event.keyCode != 8 && !pattern.test(inputChar)) || (event.which === 32 && !event.target.value.length)) {
      //alert(inputChar);
      event.preventDefault();
    }
    
  }

  keyPressNumberWithDot(event: any, data, type) {
    const pattern = /[0-9.]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }

    let checkData;

    if(type == 'create'){
      checkData = this.citySaveForm.get(data).value;
    } else if(type == 'edit'){
      checkData = this.updateCityForm.get(data).value;
    }

    if(event.charCode == 46){
      if(!this.checkDataContainDot(checkData)){
        event.preventDefault();
      }
    }
    else if(event.charCode != 46){
      if(!this.checkDataValidForPrice(checkData)){
        event.preventDefault();
      }
    }

  }

  checkDataContainDot(data){
    console.log(data.split(".").length);

    if(data.split(".").length > 1) return false;
    return true;
  }

  checkDataValidForPrice(data){
      if(data.length >= 2 && !data.includes(".")) return false;
      else if(data.includes(".")){
        let dotIndex = data.indexOf(".");
        if(dotIndex+8 < data.length) return false;
      }
      return true;
  }

  submitted = false;
  saveCity(){

    this.submitted = true;
    if(this.citySaveForm.invalid){
      return false;
    }

    this.requestData = {
      cityName : this.citySaveForm.controls['cityName'].value,
      stateId : this.citySaveForm.controls['stateId'].value,
      latitude : this.citySaveForm.controls['latitude'].value,
      longitude : this.citySaveForm.controls['longitude'].value,
    };
    console.log(this.requestData);
    
    this.tfs.addCity(JSON.stringify(this.requestData)).subscribe((data:{})=>{
        this.cityByStateRes = data;

        if(this.cityByStateRes.status == 1){
          this.showNotification('top','right',this.cityByStateRes.message, '1');
          //this.getCityListBasedOnStateWithStatus(this.citySaveForm.controls['stateId'].value,'byTs');
          this.citySaveForm.reset();
          this.submitted = false;
        } else {
          this.showNotification('top','right',this.cityByStateRes.message, '3');
        }

    },error => {
      console.log("Error: "+error);
    });

  }

  resetCity(){
    this.submitted = false;
    this.citySaveForm.reset();
  }

  upCityStatusRes : any = [];
  changeCityStatus(e,city_id,statusVal){

    this.requestData = {
      cityId : city_id,
      status : statusVal
    }; 
    console.log(this.requestData);

    this.tfs.updateCityStatus(JSON.stringify(this.requestData)).subscribe((data : {}) => {
      
      this.upCityStatusRes = data;
      console.log(JSON.stringify(this.upCityStatusRes));
      //this.RouteList();

      if(this.upCityStatusRes.status == 1){
       // this.RouteList();
       this.getCityListBasedOnStateWithStatus(this.stateIdM,'byTs');
       this.showNotification('top','right',this.upCityStatusRes.message, '1');
      }
      else{
        this.getCityListBasedOnStateWithStatus(this.stateIdM,'byTs');
        this.showNotification('top','right',this.upCityStatusRes.message, '3');
      }
    
    }, error => console.log(error));

  }

  updateCityForm = this.fb.group({
    cityName : ["",Validators.required],
    latitude : ["",Validators.required],
    longitude : ["",Validators.required]
  });

  get fEdit(){ return this.updateCityForm.controls; }

  cityIdVal : any;
  stateTextM : any;
  setCityData(city_id,city_name,city_latitude,city_longitude){
    this.updateCityForm.controls.cityName.setValue(city_name);
    this.updateCityForm.controls.latitude.setValue(city_latitude);
    this.updateCityForm.controls.longitude.setValue(city_longitude);

    this.cityIdVal = city_id;

    //console.log(this.stateIdM);
    //console.log(this.stateListRes.list);

    for(var m in this.stateListRes.list){

      //console.log(m);
      //console.log(this.stateListRes.list[m].state_id);

      if(this.stateIdM == this.stateListRes.list[m].state_id){
        this.stateTextM  = this.stateListRes.list[m].state_name;
      }

    }
  }

  submittedEdit = false;
  updateCityRes : any = [];
  editCityDetails(){

    this.submittedEdit = true;
    if(this.updateCityForm.invalid == true){
      return;
    }

    this.requestData = {
      stateId : this.stateIdM,
      cityId : this.cityIdVal,
      cityName : this.updateCityForm.get("cityName").value,
      latitude : this.updateCityForm.get("latitude").value,
      longitude : this.updateCityForm.get("longitude").value, 
    };
    console.log(this.requestData);

    this.tfs.updateCity(JSON.stringify(this.requestData)).subscribe((data:{})=>{

      this.updateCityRes = data;

      this.submittedEdit = false;
      //this.updateCityForm.reset();
      this.closeUpdateCityModal.nativeElement.click();

      if(this.updateCityRes.status == 1){
        this.getCityListBasedOnStateWithStatus(this.stateIdM,'byTs');
        this.showNotification('top','right',this.updateCityRes.message, '1');
      } else {
        this.getCityListBasedOnStateWithStatus(this.stateIdM,'byTs');
        this.showNotification('top','right',this.updateCityRes.message, '3');
      }

    },error => console.log(error));

  }

  showNotification(from, align, messageDis, errorNo) {
    
    //const color = Math.floor(Math.random() * 5 + 1);
    const color = errorNo;
    //console.log(errorNo);

    switch (color) {
      
        case '1':
        this.toastr.success(
          '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">'+messageDis+'</span>',
          "",
          {
            timeOut: 500,
            // closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-success alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;
      
        case '2':
        this.toastr.warning(
        '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">'+messageDis+'</span>',
          "",
          {
            timeOut: 4000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-danger alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;
      
        case '3':
        this.toastr.error(
        '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">'+messageDis+'</span>',
          "",
          {
            timeOut: 4000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-danger alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;

        default:
        break;
    }

  }

}