import { Component, OnInit, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import { FormGroup , FormBuilder , Validators } from '@angular/forms';
import { APiProperties } from 'app/class/api-properties';
import { TruckieApiService } from 'app/service/truckie-api.service';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.css']
})
export class ImageUploadComponent implements OnInit {
  
  @Input("supplierId") supplierId;
  @Input("documentFor") documentFor;
  @Input("truckId") truckId;
  @Input("driverId") driverId;
  @Input("paramPass") paramPass;
  @Output('hideImageupload') hideImageupload = new EventEmitter<{}>();
  
  properties : APiProperties = new APiProperties();
  
  constructor(private fb: FormBuilder, 
    private tas: TruckieApiService, 
    private toastr: ToastrService) { }

  truckOrDriver : any;
  image : any;
  upImageDisplay;
  fileToUpload;

  //fileData : any;
  requestData : any = {};
  uploadImageRes : any = [];

  iuBack;
  iuBlk;

  fileData : any;
  url : any;
  beforeUpload;
  afterUpload;

  ngOnInit(): void {

    this.iuBack = true;
    this.iuBlk = true;

    if(this.documentFor == 'truck'){
       this.truckOrDriver = this.truckId; 
    }

    if(this.documentFor == 'driver'){
      this.truckOrDriver = this.driverId; 
    }

  }

  // updateImage(ev) {
  //   // this.image = this.sanitizer.bypassSecurityTrustUrl(
  //   //   window.URL.createObjectURL(ev.target.files[0])
  //   // );

  //   this.image = ev.target.files[0];
  //   console.log(this.image);

  //   this.upImageDisplay = true;
  // }

  // handleFileInput(files: FileList) {
    
  //   this.fileToUpload = files.item(0);
  //   console.log(this.fileToUpload);

  //   this.upImageDisplay = true;
  // }

  processFile(event) {
    
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => { // called once readAsDataURL is completed
        this.url = event.target.result;

        this.beforeUpload = true;
      }
    }

    //const fileval : File = imageInput.target.files[0];
    const fileval = event.target.files[0];
    this.fileData = event.target.files[0];
    //console.log(event.target.files.length);
    
  }
  
  uploadImageData(){
    this.beforeUpload = false;
    this.afterUpload = true;

    if(this.documentFor == 'driver'){
      var formData = new FormData();
      formData.append('uploadImage', this.fileData, this.fileData.name);
      formData.append('documentFor', this.documentFor);
      formData.append('supplierId', this.supplierId);
      formData.append('driverId', this.driverId);
    }

    if(this.documentFor == 'truck'){
      var formData = new FormData();
      formData.append('uploadImage', this.fileData, this.fileData.name);
      formData.append('documentFor', this.documentFor);
      formData.append('supplierId', this.supplierId);
      formData.append('truckId', this.truckId);
    }

    // formData.forEach((value,key) => {
    //   console.log(key+" "+value)
    // });

    this.tas.uploadImage(formData).subscribe((data : {}) => {

      this.uploadImageRes = data;
      console.log(this.uploadImageRes);

      if(this.uploadImageRes.status ==1 ){
        console.log(this.uploadImageRes.imageUrl);

        this.hideImageupload.emit({"finalImageUp": this.uploadImageRes.imageUrl,"paramPassFinal": this.paramPass});

      } else {
        alert(this.uploadImageRes.message);
      }

    }, error => console.log(error));

  }
  
  back(){
    this.hideImageupload.emit();
  }
  
}