import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { APiProperties } from 'app/class/api-properties';
import { TruckieApiService } from 'app/service/truckie-api.service';

export interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}

export let ROUTES: RouteInfo[] = [];

console.log(localStorage.getItem('userRole'));
if(localStorage.getItem('userRole') == "ADMIN"){
 
    ROUTES = [
        //{ path: '/dashboard',     title: 'Dashboard',         icon:'nc-bank',       class: '' },
        { path: '/configuration',     title: 'Configuration',         icon:'nc-bank',       class: '' },
        { path: '/load',     title: 'Load',         icon:'nc-bank',       class: '' },
        { path: '/supplierManagement',     title: 'Supplier Management',         icon:'nc-bank',       class: '' },
        { path: '/branchManagement',     title: 'Branch Management',         icon:'nc-bank',       class: '' },
        { path: '/reports',     title: 'Reports',         icon:'nc-bank',       class: '' },
        { path: '/userManagement',     title: 'Manage Login User',         icon:'nc-bank',       class: '' },
        { path: '/createRole',     title: 'Manage Role',         icon:'nc-bank',       class: '' },
        { path: '/interestedParticipant',     title: 'Interested Participant',         icon:'nc-bank',       class: '' }
    ];

} else if(localStorage.getItem('userRole') == "SUB_ADMIN"){

    ROUTES = [
        { path: '/configuration',     title: 'Configuration',         icon:'nc-bank',       class: '' },
        { path: '/load',     title: 'Load',         icon:'nc-bank',       class: '' },
        { path: '/supplierManagement',     title: 'Supplier Management',         icon:'nc-bank',       class: '' },
        { path: '/branchManagement',     title: 'Branch Management',         icon:'nc-bank',       class: '' },
        { path: '/reports',     title: 'Reports',         icon:'nc-bank',       class: '' },
        { path: '/userManagement',     title: 'Manage Login User',         icon:'nc-bank',       class: '' },
        { path: '/interestedParticipant',     title: 'Interested Participant',         icon:'nc-bank',       class: '' }
    ];

} else if(localStorage.getItem('userRole') == "BranchUser"){

    ROUTES = [
        { path: '/load',     title: 'Load',         icon:'nc-bank',       class: '' },
        { path: '/supplierManagement',     title: 'Supplier Management',         icon:'nc-bank',       class: '' },
        { path: '/branchManagement',     title: 'Branch Management',         icon:'nc-bank',       class: '' },
        { path: '/reports',     title: 'Reports',         icon:'nc-bank',       class: '' },
        { path: '/interestedParticipant',     title: 'Interested Participant',         icon:'nc-bank',       class: '' }
    ];

}  else {    
    ROUTES = [
        { path: '/configuration',     title: 'Configuration',         icon:'nc-bank',       class: '' },
        { path: '/load',     title: 'Load',         icon:'nc-bank',       class: '' },
        { path: '/supplierManagement',     title: 'Supplier Management',         icon:'nc-bank',       class: '' },
        { path: '/branchManagement',     title: 'Branch Management',         icon:'nc-bank',       class: '' },
        { path: '/reports',     title: 'Reports',         icon:'nc-bank',       class: '' },
        { path: '/userManagement',     title: 'Manage Login User',         icon:'nc-bank',       class: '' },
    ];
}

@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    
    apiProperties : APiProperties = new APiProperties();
    public menuItems: any[];

    constructor(private tas : TruckieApiService, private router: Router) { }

    requestData : any = {};
    responseData : any = [];

    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        this.getUserAccessURLList();
    }

    getUserAccessURLList(){

        this.requestData = {
            clientId : this.apiProperties.clientId,
            clientSecret : this.apiProperties.clientSecret,
            displayLength : 100,
            displayStartFrom : 0,
            roleId : localStorage.getItem('roleIdLocal')
        };

        this.tas.getUserAccessURLList(JSON.stringify(this.requestData)).subscribe((data :{}) =>{
            this.responseData = data;

            if(this.responseData.status == 1 && this.responseData.roleList.length !=0)
                this.setSideBar();
            
        },error => console.error("getUserAccessURLList Error : ",error));
    }

    setSideBar(){
        if(localStorage.getItem('userRole') != "ADMIN" && localStorage.getItem('userRole') != "SUB_ADMIN" && 
           localStorage.getItem('userRole') != "BranchUser"){
            
            this.menuItems = [];
            for(const data of this.responseData.roleList){
                ROUTES.forEach(item =>{

                    if(item.path == data.accessLink)
                        this.menuItems.push(item);
                    
                });
            }

            if(this.menuItems.length !=0)
                this.router.navigate([this.menuItems[0].path]);
        }
    }
}
