export class Tabledata {

    loadId : any;
    supplyId : any;
    supplierName : any;
    supplierCity : any;
    supplierContectNumber : any;
    bidRate : any;
    count : any;
    weight : any;
}

export class DisplayLoadData {
    loadId : any;
    fromCityName : any;
    toCityName : any;
    branchName : any;
    order_type : any;
    loadStatus: any;
    completeLoadStatus: any;
}