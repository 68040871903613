import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder , FormGroup , Validators } from '@angular/forms'
import { APiProperties } from '../../class/api-properties';
import { BranchService } from "../../service/branch.service";
import { RestApiService } from "../../rest-service/rest-api.service";
import { TruckieFunctionsService } from '../../service/truckie-functions.service';
import { MappedCity } from 'app/class/branch-mapped-city';

@Component({
  selector: 'app-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.css']
})
export class BranchComponent implements OnInit {
  apiProperties : APiProperties = new APiProperties();
  @ViewChild('closeCityModal') closeCityModal: ElementRef;
  @ViewChild('closePopupModal') closePopupModal: ElementRef;
  @ViewChild('closeDispatchTypeModal') closeDispatchTypeModal: ElementRef;
  
  mappedCity : MappedCity = new MappedCity();

  constructor(private formBuilder: FormBuilder,
              private branchService : BranchService,
              public restApi: RestApiService,
              private tfs: TruckieFunctionsService) { }

  branchSaveForm : FormGroup;

  submitted = false;

  Branch : any =[];
  BranchMappedCity : any = [];
  MappedCity = [];

  City : any = [];
  FormResponseData : any =[];
  formData : {};
  requestData : {};
  responseData : any = [];

  index =0;
  isViewable;
  displayBranchUserManagement;

  branchId;
  cityId;
  branchName;
  cityName;
  addCity;
  addCityDetail = [];
  removeCityDetail = [];
  removeIndex;

  dataNotFoundCity;
  loadingCity;
  serverErrorCity;

  ngOnInit(): void {

    this.submitted = false;
    this.isViewable = true;
    this.addCity = false;

    this.displayBranchUserManagement = false;

    this.dataNotFoundCity = false;
    this.loadingCity = true;
    this.serverErrorCity = false;

    this.getBranch();
    this.getCity();
    this.removeArrayData();

    this.branchSaveForm = this.formBuilder.group({
      branchName : ['' , Validators.required],
      mappingCity : ['' , Validators.required]
    });
  }

  removeArrayData(){

    while(this.MappedCity.length !=0){

      this.MappedCity.shift();
    }

    while(this.addCityDetail.length !=0){

      this.addCityDetail.shift();
    }

    while(this.removeCityDetail.length !=0){

      this.removeCityDetail.shift();
    }
  }

  getBranch(){

    if(localStorage.getItem('userRole') == "SUB_ADMIN"){
      this.requestData = {
        adminId : localStorage.getItem('adminIdLocal')
      }
    } else {
      this.requestData = {
        adminId : ""
      }
    }
    console.log(this.requestData);

    this.restApi.fetch_master_branch_staging(JSON.stringify(this.requestData)).subscribe(data => {
      this.Branch = data;
      //console.log(this.Branch);

    }, error => console.log(error));
  }

  // getBranch(){

  //   return this.branchService.getBranch().subscribe((data : {}) => {
  //     this.Branch = data;
  //   }, error => console.log(error));
  // }

  getCity(){
    return this.branchService.getCity().subscribe((data : {}) => {
        this.City = data;
    }, error => console.log(error));
  }

  get formValidation(){
    return this.branchSaveForm.controls;
  }

  keyPress(event: any) {
    const pattern = /[0-9a-zA-Z ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  inputValidation(){

    var data = this.branchSaveForm.get("branchName").value.trim();
    if(data == ''){
      this.branchSaveForm.controls.branchName.setValue('');
      return;
    }
    
  }

  onSubmit() {

    this.submitted = true;
    if(this.branchSaveForm.invalid){
        return;
    }

    this.isViewable = false;

    this.formData = {   
      branchName : this.branchSaveForm.get("branchName").value.trim(),
      cityId : this.branchSaveForm.get("mappingCity").value,
      adminId : localStorage.getItem('adminIdLocal')
    };
    console.log(this.formData);

  this.branchService.saveBranch(JSON.stringify(this.formData)).subscribe((data : {}) => {
    this.FormResponseData = data;
    console.log("response :"+this.FormResponseData.status);

      if(this.FormResponseData.status ==1 ){
        //display form values on success
        this.isViewable = true;
        alert("Branch Add Successfully!");
        this.ngOnInit();
      } else {
        this.isViewable = true;
        alert(this.FormResponseData.message);
      }
    }, error => console.log(error));
  }

  onReset() {
    this.submitted = false;
    this.branchSaveForm.reset();
  }

  back(){
    this.displayBranchUserManagement = false;
  }

  viewBranchUserManagement(data){
    
    this.branchId = data;
    this.displayBranchUserManagement = true;

  }

  setBranchName(data){

    this.branchName = data.branch_name;
    this.branchId = data.branch_id;
    
    this.removeArrayData();
    this.getBranchMappedCity(data.branch_id);
  }

  getBranchMappedCity(data){

    this.requestData = {
      branchId : data
    };

    this.branchService.getBranchMappedCity(JSON.stringify(this.requestData)).subscribe((data : {})=>{

      this.BranchMappedCity = data;

      if(this.BranchMappedCity.status ==1){

        if(this.BranchMappedCity.list.length !=0){

          this.loadingCity = false;
          
          for(let i=0; i<this.BranchMappedCity.list.length; i++){

            this.mappedCity = new MappedCity();
            this.mappedCity.cityId = this.BranchMappedCity.list[i].city_id;
            this.mappedCity.cityName = this.BranchMappedCity.list[i].city_name;

            this.MappedCity.push(this.mappedCity);
          }
         
        }
        else{
          this.loadingCity = false;
          this.dataNotFoundCity = true;
        }
      }
      else{
        this.loadingCity = false;
        this.dataNotFoundCity = true;
      }
    },error =>{

      this.loadingCity = false;
      this.serverErrorCity =true;
      console.log("Error: "+error);
    });

  }

  setCityName(data,index){
    this.cityName = data.cityName;
    this.cityId = data.cityId;
    this.removeIndex = index;
  }

  addBranchCity(){
    this.addCity = true;
  }

  getSelectedOptionText(event) {
    let selectedElementValue = event.target.value;

    let selectElementText = event.target['options']
      [event.target['options'].selectedIndex].text;

    if(this.MappedCity.length ==0){

      this.dataNotFoundCity = false;
      
      this.mappedCity = new MappedCity();
      this.mappedCity.cityId = selectedElementValue;
      this.mappedCity.cityName = selectElementText;

      this.MappedCity.push(this.mappedCity);

      let resp = {
        branch_id : this.branchId,
        city_id : selectedElementValue
      };

      this.addCityDetail.push(resp);

    }
    else{

      let cityExist = false;
      for(let data of this.MappedCity){

        if(data.cityId == selectedElementValue){       
          cityExist = true;
        }
      }

      if(!cityExist){

        this.mappedCity = new MappedCity();
        this.mappedCity.cityId = selectedElementValue;
        this.mappedCity.cityName = selectElementText;

        this.MappedCity.push(this.mappedCity);

        let resp = {
          branch_id : this.branchId,
          city_id : selectedElementValue
        };
  
        let removedCity = false;
        let index=0;
        for(let data of this.removeCityDetail){

          if(data.city_id == selectedElementValue){
            removedCity = true;
            this.removeCityDetail.splice(index,1);
          }

          index++;
        }

        if(!removedCity){
          this.addCityDetail.push(resp);
          console.log("added branch city details::"+selectedElementValue+"::::"+selectElementText);          
        }
        
      }
    }

    this.addCity = false;
                            
  }

  addMappedCity(){

    this.requestData = {
      addCity : this.addCityDetail
    };

    this.branchService.addMappedCity(JSON.stringify(this.requestData)).subscribe((data : {})=>{

      this.responseData = data;

      if(this.responseData.status == 1){
         
        alert("Branch City Mapped Add Successfully!");

        //this.closeCityModal.nativeElement.click();
      }
      else{
        alert(this.responseData.message);
      }
    },error =>{
      console.log("Error: "+error);
    });
  }

  removeCity(){
    let resp = {
      branch_id : this.branchId,
      city_id : this.cityId
    };

    let addedCity = false;
    this.MappedCity.splice(this.removeIndex,1);
    let index=0;
    for(let data of this.addCityDetail){

      if(data.city_id == this.cityId){
          addedCity = true;
          this.addCityDetail.splice(index,1);
      }

      index++;
    }

    if(!addedCity){
      this.removeCityDetail.push(resp);
    }
  }

  UpdateMappedCity(){

    if(this.addCityDetail.length!=0){
      this.addMappedCity();
    }

    if(this.removeCityDetail.length!=0){
      this.removeMappedCity();
    }
  }

  removeMappedCity(){

    this.requestData = {
      removeCity : this.removeCityDetail
    };

    this.branchService.removeMappedCity(JSON.stringify(this.requestData)).subscribe((data : {})=>{

      this.responseData = data;

      if(this.responseData.status == 1){
         
        alert("Branch Mapped City Removed Successfully!");

        //this.closeCityModal.nativeElement.click();
      }
      else{
        alert(this.responseData.message);
      }
    },error =>{
      console.log("Error: "+error);
    });

  }

  dispatchObj = [
    { id:1, dtVal:'Auto'},
    { id:2, dtVal:'Manual'},
    { id:3, dtVal:'Custom'},
  ];

  dispatchTypeForm = this.formBuilder.group({
    dispatchType : ["",Validators.required]
  });

  branchIdVal: any;
  dispatch_type_id: any;
  dispatchTypeDataSet(branchData){
    this.branchIdVal = branchData.branch_id;
    this.dispatch_type_id = branchData.dispatch_type_id;

    this.dispatchTypeForm.controls.dispatchType.setValue(this.dispatch_type_id);
  }

  dispatchTypeChange(e){
    this.dispatch_type_id = e.target.value;
  }

  updateDispatchForBranchRes: any=[];
  updateDispatchForBranch(){

    this.requestData = {
      clientId : this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret,
      branchId: this.branchIdVal,
      dispatchType: this.dispatch_type_id, //Auto=1, Manual=2 and Custom=3
      actingUser: localStorage.getItem('userNameLocal'),
    }
    console.log(this.requestData);
    //return false;

    this.tfs.updateDispatchForBranch(JSON.stringify(this.requestData)).subscribe(data => {
      this.updateDispatchForBranchRes = data;
      
      if(this.updateDispatchForBranchRes.status == 1){
        console.log(this.updateDispatchForBranchRes);
        this.getBranch();
      } else {
        alert(this.updateDispatchForBranchRes.message);
      }
      this.closeDispatchTypeModal.nativeElement.click();

    }, error => console.log(error));
  }

}