import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ViewLiveLoadService } from "../../service/view-live-load.service";
import { ViewLiewLoadTableData } from './view-live-load-table-data';
import { zip } from 'rxjs';
import { DisplayLoadData } from '../view-bids-supplier-per-load/tabledata';

@Component({
  selector: 'app-view-live-load',
  templateUrl: './view-live-load.component.html',
  styleUrls: ['./view-live-load.component.css']
})
export class ViewLiveLoadComponent implements OnInit {

  constructor(private viewLiveLoadService : ViewLiveLoadService,
              private router: Router
    ) { }

  viewLiveLoadTableData : ViewLiewLoadTableData = new ViewLiewLoadTableData();
  displayLoadData : DisplayLoadData = new DisplayLoadData();

  ViewLiveLoad : any = [];
  loadIds : any=[];
  countOfBidPerLoad : any=[];
  loadStatus : any=[];
  requestCountOfBidPerLoad : {};
  CountBidPerLoad : any=[];
  TableData=[];
  index=0;

  completeLoadResponse : any = [];

  dataNotFound;
  loading;
  serverError;

  requestData:{};

  showSupplier;

  ngOnInit(): void {

    this.showSupplier = false;
    this.dataNotFound=false;
    this.loading = true;
    this.serverError = false;
    this.index=0;

    this.getViewLiveLoad();
  }

  getViewLiveLoad(){

    return this.viewLiveLoadService.getViewLiveLoad().subscribe((data : {}) => {
      this.ViewLiveLoad = data;

      if(this.ViewLiveLoad.status == 1){

        for(let i=0; i< this.ViewLiveLoad.list.length; i++){

          this.requestCountOfBidPerLoad = {
            loadId : this.ViewLiveLoad.list[i].loadId
          };
        this.loadIds.push(JSON.stringify(this.requestCountOfBidPerLoad));  

        }

        if(this.ViewLiveLoad.list.length ==0){
          this.dataNotFound=true;
          this.loading = false;

          this.TableData = null;
         /* while(this.TableData.length > 0){
            this.TableData.shift();
          }*/
        }
        else {

        zip(this.viewLiveLoadService.getCountOfBidPerLoad(this.loadIds),this.viewLiveLoadService.getLoadStatus(this.loadIds)).subscribe(([response1,response2]) => {

          this.countOfBidPerLoad = response1;
          this.loadStatus = response2;

          for(let i=0; i< this.ViewLiveLoad.list.length; i++){

            this.viewLiveLoadTableData = new ViewLiewLoadTableData();
            this.viewLiveLoadTableData.loadId = this.ViewLiveLoad.list[i].loadId;
            this.viewLiveLoadTableData.fromCityName = this.ViewLiveLoad.list[i].fromCityName;
            this.viewLiveLoadTableData.toCityName = this.ViewLiveLoad.list[i].toCityName;
            this.viewLiveLoadTableData.bidCloseDateTime = this.ViewLiveLoad.list[i].bidCloseDateTime;
            this.viewLiveLoadTableData.branchName = this.ViewLiveLoad.list[i].branchName;
            this.viewLiveLoadTableData.reportingDate = this.ViewLiveLoad.list[i].reportingDate;
            this.viewLiveLoadTableData.countOfBidsReceived = this.countOfBidPerLoad[i].countOfBidReceived;
            //this.viewLiveLoadTableData.loadStatus = this.loadStatus[i].code;

            if(this.loadStatus[i] ==null){
              console.log(this.ViewLiveLoad.list[i].loadId+" null status:"+this.loadStatus[i]);
            }
            else{
              this.viewLiveLoadTableData.loadStatus = this.loadStatus[i].code;
              console.log(this.ViewLiveLoad.list[i].loadId+"status:"+this.loadStatus[i]);
            }
            
  
            this.TableData.push(this.viewLiveLoadTableData);
          }

            this.loading = false;
          }
          )
      }

      }
      else{

        this.loading = false;
        this.serverError = true;
      }

    }, error => console.log(error));
  }

  showBid(load){

    this.showSupplier = true;

    this.displayLoadData = new DisplayLoadData ();
    this.displayLoadData.loadId = load.loadId;
    this.displayLoadData.fromCityName = load.fromCityName;
    this.displayLoadData.toCityName = load.toCityName;
    this.displayLoadData.branchName = load.branchName;
   //this.router.navigate(['/viewBidsSupplierPerLoad/'+id]);
  
  }

  onCheckboxChange(e,id) {
    // const checkArray: FormArray = this.registrationForm.get('checkArray') as FormArray;
 
     if (e.target.checked) {
      // checkArray.push(new FormControl(e.target.value));

      this.requestData = {
        loadId : id,
        loadStatus : 1
      };

       this.viewLiveLoadService.updateLoadStatus(JSON.stringify(this.requestData)).subscribe((data : {}) => {
        console.log("response :"+JSON.stringify(data));
        
      }, error => console.log(error));
     }
     else{

      this.requestData = {
        loadId : id,
        loadStatus : 2
      };

       this.viewLiveLoadService.updateLoadStatus(JSON.stringify(this.requestData)).subscribe((data : {}) => {
        console.log("response :"+JSON.stringify(data));
        
      }, error => console.log(error));

     }
   }

   addLoadDetail(load){

    console.log("Live load details for duplicate : loadId : "+load.loadId+" : fromCityName : "+load.fromCityName+" : toCityName : "+load.toCityName+" : branchName : "+load.branchName);

    localStorage.setItem('loadId', load.loadId);
    localStorage.setItem('fromCityName', load.fromCityName);
    localStorage.setItem('toCityName', load.toCityName);
    localStorage.setItem('branchName', load.branchName);
   }


   completeLoad(loadId,index){

    this.requestData = {
      loadId : loadId
    };

    this.viewLiveLoadService.completeLoad(JSON.stringify(this.requestData)).subscribe((data : {}) => {

      console.log("complete load response :"+data);

      this.completeLoadResponse = data;

      if(this.completeLoadResponse.status == 1){

        alert(this.completeLoadResponse.message);

        this.TableData.splice(index,1);
       // this.ngOnInit();
      }
      else{
        alert(this.completeLoadResponse.message);
      }

    }, error => {
      console.log("error:"+error);
    });

   }

}
