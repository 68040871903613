import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable,throwError,forkJoin  } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { APiProperties } from 'app/class/api-properties';

@Injectable({
  providedIn: 'root'
})
export class ViewBidsSupplierPerLoadService {

  constructor(private http:HttpClient) { }

  apiProperties : APiProperties = new APiProperties();

  getSpecificListOfSupplierPerLoadHttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  getSpecificListOfSupplierPerLoad(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'list_of_supplier_bid_par_load', data ,this.getSpecificListOfSupplierPerLoadHttpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  } 

  getTruckAvailabilityPerBidPerLoadHttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  getTruckAvailabilityPerBidPerLoad(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'truck_availability_per_bid_per_load', data ,this.getTruckAvailabilityPerBidPerLoadHttpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }
  
  decideBidWinnerHttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  decideWinnerOfBid(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'decide_winner_of_bid', data ,this.decideBidWinnerHttpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  winningBidTDBDetailsHttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  winningBidTDBDetails(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'see_truck_driver_bank_details_against_winning_bid', data ,this.winningBidTDBDetailsHttpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  getCountWeigthSpecificListOfSupplierPerLoadHttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  countWeightResponseData : any=[];
  getCountWeigthSpecificListOfSupplierPerLoad(data : any[]) : Observable<any[]> {
   
    for(let i=0; i< data.length; i++){
      console.log("data "+i+":"+data[i]);
      this.countWeightResponseData[i]= this.http.post(this.apiProperties.truckiefunctionsApi+'count_weight_provided_by_supplier_as_per_load', data[i] ,this.getCountWeigthSpecificListOfSupplierPerLoadHttpOptions).pipe(
        retry(1),
        catchError(this.handleError)
      )
    }
  
    return forkJoin(this.countWeightResponseData);
  }

  fetchAllTrucksOfSupplierHttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  fetchAllTrucksOfSupplier(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'fetch_all_trucks_added_by_supplier', data ,this.fetchAllTrucksOfSupplierHttpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  submitSupplyBidHttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  submitSupplyBid(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'submit_supply_bid_for_load', data ,this.submitSupplyBidHttpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  resubmitSupplyBidHttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  resubmitSupplierBid(data : {}) : Observable<{}>{
    console.log("resubmitSupplierBid service: "+data);
    return this.http.post(this.apiProperties.truckiefunctionsApi+'resubmit_bid_details_new', data ,this.resubmitSupplyBidHttpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  notificationInformBidWinnerHttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  notificationInformBidWinner(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.truckiefunctionsApi+'notification_inform_bid_winner', data ,this.notificationInformBidWinnerHttpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  handleError(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
 }
}
