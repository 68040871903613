import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BranchService } from 'app/service/branch.service';
import { BranchUserManagementService } from 'app/service/branch-user-management.service';
import { RestApiService } from "../../rest-service/rest-api.service";
import { JsonPipe } from '@angular/common';

@Component({
  selector: 'app-branch-user-management',
  templateUrl: './branch-user-management.component.html',
  styleUrls: ['./branch-user-management.component.css']
})
export class BranchUserManagementComponent implements OnInit {

  @Input() branchId : any;
  
  constructor(private formBuilder: FormBuilder, 
              private branchUserManagementService : BranchUserManagementService, 
              private branchService : BranchService,
              public restApi: RestApiService) { }

  requestData : any = {};
  responseData : any = [];

  Branch : any =[];
  BranchUserDetails : any = [];

  branchName;
  number;

  submitted;
  isViewable;
  dataNotFound;

  ngOnInit(): void {

    this.submitted = false;
    this.isViewable = true;
    this.dataNotFound = true;

    this.getBranch();

    this.setBranchUserDetails();
  }

  branchUserForm = this.formBuilder.group({
    branch : ['', Validators.required],
    branchUserName : ['', Validators.required],
    branchUserNumber : ['', Validators.required]
   // branchUserPassword : ['', Validators.required]
  });

  get formValidation(){
    return this.branchUserForm.controls;
  }

   //only number will be add
   keyPressForNumber(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  keyPress(event: any) {
    const pattern = /[0-9a-zA-Z ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  inputValidation(type){

    if(type == 'branchUserName'){

      var data = this.branchUserForm.get("branchUserName").value.trim();
      if(data == ''){
        this.branchUserForm.controls.branchUserName.setValue('');
        return;
      }

    }

    if(type == 'branchUserPassword'){
      
      var data = this.branchUserForm.get("branchUserPassword").value.trim();
      if(data == ''){
        this.branchUserForm.controls.branchUserPassword.setValue('');
        return;
      }
    }
    
    
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.branchUserForm.invalid) {
        return;
    } 

    this.isViewable = false;

    this.requestData = {
      branchId : this.branchId,
      mobileNumber : this.branchUserForm.get('branchUserNumber').value,
      userName : this.branchUserForm.get("branchUserName").value.trim()
    };

  

    this.branchUserManagementService.saveBranchUserDetails(JSON.stringify(this.requestData)).subscribe((data : {})=>{

      this.responseData = data;

      if(this.responseData.status == 1){

        this.isViewable = true;
        alert("Branch User Detalis Add Successfully!");
this.number = this.branchUserForm.get('branchUserNumber').value;
        this.onReset();
        this.setBranchUserDetails();
        this.forgetPassword();
      }
      else{

        this.isViewable = true;
        alert(this.responseData.message);
      }

    },error =>{
      this.isViewable = true;
      this.onReset();
      console.log("Error : "+error);
    });

  }

  onReset() {
    this.submitted = false;

    this.branchUserForm.reset();

    if(this.branchId !=null){
      this.branchUserForm.controls.branch.setValue(this.branchId);
    }

   // this.branchUserForm.controls.action.setValue('');
  // this.setSupplierId();
  }

  setBranchUserDetails(){

    if(this.branchId !=null){
      this.dataNotFound = false;
      this.branchUserForm.controls.branch.setValue(this.branchId);
      this.getBranchUserDetails(this.branchId);
     
    }
  }

  getBranch(){

    if(localStorage.getItem('userRole') == "SUB_ADMIN"){
      this.requestData = {
        adminId : localStorage.getItem('adminIdLocal')
      }
    } else {
      this.requestData = {
        adminId : ""
      }
    }
    console.log(this.requestData);

    this.restApi.fetch_master_branch_staging(JSON.stringify(this.requestData)).subscribe(data => {
      this.Branch = data;
      //console.log(this.Branch);

    }, error => console.log(error));
  }

  // getBranch(){
  //   return this.branchService.getBranch().subscribe((data : {}) => {
  //     this.Branch = data;
  //   }, error => console.log(error));
  // }

  getBranchUserDetails(data){

    this.requestData = {
      branchId : data
    };

    this.branchUserManagementService.getBranchUserDetails(JSON.stringify(this.requestData)).subscribe((data : {})=>{

      this.BranchUserDetails = data;

      if(this.BranchUserDetails.status ==1){

        if(this.BranchUserDetails.list.length !=0){

          if(this.BranchUserDetails.list[0].user_name == null && this.BranchUserDetails.list[0].user_password == null && this.BranchUserDetails.list[0].mobile_number == null){
            this.dataNotFound = true;
            
          }
          else{
            this.number = this.BranchUserDetails.list[0].mobile_number;
            this.branchUserForm.controls.branchUserName.setValue(this.BranchUserDetails.list[0].user_name);
            this.branchUserForm.controls.branchUserNumber.setValue(this.BranchUserDetails.list[0].mobile_number);
          }
        }
      }
    },error =>{
      console.log("Error: "+error);
    });
  }

  forgetPassword(){

    this.requestData = {
      branchId : this.branchId,
      number : this.number
      //mobileNumber : this.number
    };

    this.branchUserManagementService.forgetPasswordBranchUser(JSON.stringify(this.requestData)).subscribe((data : {}) => {

      this.responseData = data;

      if(this.responseData.status ==1){

        let branchName = this.Branch.list.filter(branch => branch.branch_id == this.branchId)[0].branch_name;

        alert("Password Change Successfully. Branch Name: "+branchName+", User Name: "+this.responseData.userName+
        ", Password: "+this.responseData.password);

        this.passwordSmsSend(this.number,this.responseData.userName,this.responseData.password);
      }
      else{
        alert(this.responseData.message);
      }

    },error =>{
      console.log("Error : "+error);
    });

  }

  passwordSmsSend(number,name,password){

    this.requestData = {
      number : number,
      userName : name,
      password : password
    };

    this.branchUserManagementService.passwordSmsSend(JSON.stringify(this.requestData)).subscribe((data : {})=>{

      this.responseData = data;

      if(this.responseData.status ==1){
        alert("Password SMS Send Successfully to "+number);
      }
      else{
        alert(this.responseData.message);
      }

    },error =>{
      console.log("Error : "+error);
    });

  }

  updateBranchUserDetail(){
    this.dataNotFound = true;
  }

}
