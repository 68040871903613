import { Component, OnInit , ViewChild, Input, Output, EventEmitter} from '@angular/core';
import { FormGroup , FormBuilder , Validators } from '@angular/forms';
import { APiProperties } from '../../class/api-properties';
import { BranchService } from 'app/service/branch.service';
import { SupplierListService } from 'app/service/supplier-list.service';
import { SupplierBankService } from '../../service/supplier-bank.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import * as S3 from 'aws-sdk/clients/s3';
import { SafeUrl, DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-supplier-bank',
  templateUrl: './supplier-bank.component.html',
  styleUrls: ['./supplier-bank.component.css']
})
export class SupplierBankComponent implements OnInit {
  @Input() ssvData;
  @Output() hideSupplierBank = new EventEmitter();

  supplierBankDataForm : FormGroup;
  submitted = false;
  dbwResult = false;

  City : any=[];
  requestDataLSC : any={};
  supplierListRes : any=[];
  supplierBankRequestData : {};
  supplierBankDataRes : any=[];

  ssvDataVal;

  constructor(private fb: FormBuilder, 
              private branchService : BranchService,
              private supplierListService : SupplierListService, 
              private sanitizer: DomSanitizer,
              private sbs: SupplierBankService) { }

    ngOnInit(): void {

      if(this.ssvData == "1"){
         this.ssvDataVal = true;
      }

      this.supplierBankDataForm = this.fb.group({
        SupplierCity : ['' , Validators.required], 
        supplierId : ['' , Validators.required],
        IFSCCode : ['' , Validators.required],
        bankName : ['' , Validators.required],
        bankAccountName : ['' , Validators.required],
        bankAccountNumber : ['' , [Validators.required, Validators.maxLength(17)]],
      });
  
      this.submitted = false;
  
      this.getCity();
    
    }

    getCity(){
      return this.branchService.getCity().subscribe((data : {}) => {
          this.City = data;
          //console.log(this.City);
      }, error => console.log(error));
    }
  
    onChangeGetValue(event){
  
      const value = event.target.value;
      
      this.requestDataLSC = {
        supplierCityId : value
      };
      
      this.supplierListService.getListOfSupplierPerCity(JSON.stringify(this.requestDataLSC)).subscribe((data:{})=>{
          this.supplierListRes = data;
      },error => {
        console.log("Error: "+error);
      });
  
    }

    get f() { return this.supplierBankDataForm.controls; }
  
    savesupplierBankData(){

    this.submitted = true; 

    if(this.supplierBankDataForm.invalid){
          return;
    }

    this.supplierBankRequestData = {
      supplierId : this.supplierBankDataForm.get("supplierId").value,
      IFSCCode : this.supplierBankDataForm.get("IFSCCode").value,
      bankName : this.supplierBankDataForm.get("bankName").value,
      bankAccountName : this.supplierBankDataForm.get("bankAccountName").value,
      bankAccountNumber : this.supplierBankDataForm.get("bankAccountNumber").value,
    }

    console.log(this.supplierBankRequestData);

    this.sbs.saveSupplierBank(JSON.stringify(this.supplierBankRequestData)).subscribe((data : {}) => {

      this.supplierBankDataRes = data;

      //console.log(this.supplierBankDataRes);

      if(this.supplierBankDataRes.status == 1 ){
        
        alert("Supplier Bank Detail Added Successfully!");
        this.dbwResult = true;
        this.ngOnInit();
      }
      else{
        
        alert(this.supplierBankDataRes.message);
      }


    }, error => console.log(error));
  }

  keyPressForNumber(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  keyPressForName(event: any){
    var filter = /[a-zA-Z ]/;
    var ch = String.fromCharCode(event.keyCode);
    if(!filter.test(ch)){
         event.returnValue = false;
    }
  }

  back(){
    this.hideSupplierBank.emit();
  }

}
