export class Bidperloaddata {
          status: any;
          supplyId: any;
          loadId: any;
          bidRate: any;
          supplierName: any;
          supplierContectNumber: any;
          supplierCity: any;
}

export class DisplayLoadData {
          loadId : any;
          fromCityName : any;
          toCityName : any;
          branchName : any;
          order_type : any;
          loadStatus : any;
}
