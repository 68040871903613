import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder , FormGroup , Validators } from '@angular/forms';
import { TruckieFunctionsService } from 'app/service/truckie-functions.service';
import { APiProperties } from 'app/class/api-properties';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-material-update',
  templateUrl: './material-update.component.html',
  styleUrls: ['./material-update.component.css']
})
export class MaterialUpdateComponent implements OnInit {
  @Input() materialId;
  @Input() materialName;
  @Output() hideMaterialUpdate = new EventEmitter();
  
  apiProperties : APiProperties = new APiProperties();
  constructor(private fb: FormBuilder, private tfs : TruckieFunctionsService, private toastr : ToastrService) { }

  ngOnInit(): void {
    this.materialUpdateForm.patchValue({
      'upMaterialName' : this.materialName
    });
  }

  materialUpdateForm = this.fb.group({
    upMaterialName : ['' , [Validators.required, Validators.minLength(3), Validators.maxLength(30)]]
  });

  get fm(){ return this.materialUpdateForm.controls; }

  keyPress(event: any) {
    const pattern = /[0-9a-zA-Z ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  inputValidation(){

    var data = this.materialUpdateForm.get("upMaterialName").value.trim();
    if(data == ''){
      this.materialUpdateForm.controls.upMaterialName.setValue('');
      return;
    }
    
  }

  requestData : any = {};
  materialUpdateData : any = [];
  
  materialUpdate() {

    if(this.materialUpdateForm.invalid) {
      console.log("here");
      return;
    }

    //this.isViewable = false;

    this.requestData = {
      clientId : this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret,
      materialId : this.materialId,
      materialName : this.materialUpdateForm.get("upMaterialName").value,
    };
    console.log(this.requestData);

    this.tfs.updateMaterialType(JSON.stringify(this.requestData)).subscribe((data : {}) => {
      
      this.materialUpdateData = data;
      console.log(this.materialUpdateData);
      
      if(this.materialUpdateData.status ==1 ){
        
        this.showNotification('top','right',this.materialUpdateData.message, '1');
        this.back();
      }
      else{
        
        this.showNotification('top','right',this.materialUpdateData.message, '2');
      }
    }, error => console.log(error));
    
  }

  showNotification(from, align, messageDis, errorNo) {
    
    // const color = Math.floor(Math.random() * 5 + 1);
    const color = errorNo;
    
    //console.log(errorNo);

    switch (color) {
      
      case '1':
        this.toastr.success(
          '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">'+messageDis+'</span>',
          "",
          {
            timeOut: 500,
            // closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-success alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;
      
        case '2':
        this.toastr.warning(
        '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">'+messageDis+'</span>',
          "",
          {
            timeOut: 4000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-danger alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;
      
        default:
        break;
    }

  }

  back(){
    this.hideMaterialUpdate.emit();
  }

}
