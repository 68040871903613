import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToastrModule } from "ngx-toastr";
import { NgSelectModule } from '@ng-select/ng-select';

import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule} from './shared/navbar/navbar.module';

import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';

import { FormsModule , ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { LoadComponent } from './pages/load/load.component';
import { ConfigurationComponent } from './pages/configuration/configuration.component';
import { LoginComponent } from './pages/login/login/login.component';
import { ViewBidsSupplierPerLoadComponent } from './pages/view-bids-supplier-per-load/view-bids-supplier-per-load.component';
import { ViewBidCompletedLoadComponent } from './pages/view-bid-completed-load/view-bid-completed-load.component';
import { SupplierListComponent } from './pages/supplier-list/supplier-list.component';
import { SupplierSingleViewComponent } from './pages/supplier-single-view/supplier-single-view.component';
import { BankDetailsOfSupplierComponent } from './pages/bank-details-of-supplier/bank-details-of-supplier.component';
import { ViewLiveLoadComponent } from './pages/view-live-load/view-live-load.component';
import { WalletRechargeComponent } from './pages/wallet-recharge/wallet-recharge.component';
import { WalletTransactionHistoryComponent } from './pages/wallet-transaction-history/wallet-transaction-history.component';
import { SupplierManagementComponent } from './pages/supplier-management/supplier-management.component';
import { BranchUserManagementComponent } from './pages/branch-user-management/branch-user-management.component';
import { BranchComponent } from './pages/branch/branch.component';
import { DriverListForSupplierComponent } from './pages/driver-list-for-supplier/driver-list-for-supplier.component';
import { TruckListForSupplierComponent } from './pages/truck-list-for-supplier/truck-list-for-supplier.component';
import { SupplierBidParLoadComponent } from './pages/supplier-bid-par-load/supplier-bid-par-load.component';
import { BranchManagementComponent } from './pages/branch-management/branch-management.component';
import { TruckComponent } from './pages/truck/truck.component';
import { DriverComponent } from './pages/driver/driver.component';
import { BankAccountComponent } from './pages/bank-account/bank-account.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TruckBankComponent } from './pages/truck-bank/truck-bank.component';
import { SupplierBankComponent } from './pages/supplier-bank/supplier-bank.component';
import { ImageUploadCropComponent } from './pages/image-upload-crop/image-upload-crop.component';
import { ImageUploadComponent } from './pages/image-upload/image-upload.component';
import { CombineLoadComponent } from './pages/combine-load/combine-load.component';
import { ClubLoadComponent } from './pages/club-load/club-load.component';
import { BulkUploadComponent } from './pages/bulk-upload/bulk-upload.component';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DriverUpdateComponent } from './pages/driver-update/driver-update.component';
import { TruckUpdateComponent } from './pages/truck-update/truck-update.component';
import { SupplierBankUpdateComponent } from './pages/supplier-bank-update/supplier-bank-update.component';
import { MaterialComponent } from './pages/material/material.component';
import { MaterialUpdateComponent } from './pages/material-update/material-update.component';
import { TruckInsuranceAddComponent } from './pages/truck-insurance-add/truck-insurance-add.component';
import { TruckPancardAddComponent } from './pages/truck-pancard-add/truck-pancard-add.component';
import { TruckBankAddComponent } from './pages/truck-bank-add/truck-bank-add.component';
import { CityManagementComponent } from './pages/city-management/city-management.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { OrderPlacementReportComponent } from './pages/order-placement-report/order-placement-report.component';
import { SupplierGroupComponent } from './pages/supplier-group/supplier-group.component';
import { UserManagementComponent } from './pages/user-management/user-management.component';
import { SupplierCityManagementComponent } from './pages/supplier-city-management/supplier-city-management.component';
import { BulkDriverUploadComponent } from './pages/bulk-driver-upload/bulk-driver-upload.component';
import { BulkBankUploadComponent } from './pages/bulk-bank-upload/bulk-bank-upload.component';
import { BulkTruckUploadComponent } from './pages/bulk-truck-upload/bulk-truck-upload.component';
import { CreateRoleComponent } from './pages/create-role/create-role.component';
import { SearchPipe } from './search.pipe';
import {NgxPaginationModule} from 'ngx-pagination';
import { InterestedParticipantComponent } from './pages/interested-participant/interested-participant.component';

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoadComponent,
    ConfigurationComponent,
    LoginComponent,
    ViewLiveLoadComponent,
    ViewBidsSupplierPerLoadComponent,
    ViewBidCompletedLoadComponent,
    SupplierListComponent,
    SupplierSingleViewComponent,
    BankDetailsOfSupplierComponent,
    WalletRechargeComponent,
    WalletTransactionHistoryComponent,
    SupplierManagementComponent,
    BranchUserManagementComponent,
    BranchComponent,
    DriverListForSupplierComponent,
    TruckListForSupplierComponent,
    SupplierBidParLoadComponent,
    BranchManagementComponent,
    TruckComponent,
    DriverComponent,
    BankAccountComponent,
    TruckBankComponent,
    SupplierBankComponent,
    ImageUploadCropComponent,
    ImageUploadComponent,
    CombineLoadComponent,
    ClubLoadComponent,
    BulkUploadComponent,
    DriverUpdateComponent,
    TruckUpdateComponent,
    SupplierBankUpdateComponent,
    MaterialComponent,
    MaterialUpdateComponent,
    TruckInsuranceAddComponent,
    TruckPancardAddComponent,
    TruckBankAddComponent,
    CityManagementComponent,
    ReportsComponent,
    OrderPlacementReportComponent,
    SupplierGroupComponent,
    UserManagementComponent,
    SupplierCityManagementComponent,
    BulkDriverUploadComponent,
    BulkBankUploadComponent,
    BulkTruckUploadComponent,
    CreateRoleComponent,
    SearchPipe,
    InterestedParticipantComponent
  ],
  imports: [
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes,{
      useHash: true
    }),
    NgSelectModule,
    NgxPaginationModule,
    SidebarModule,
    NavbarModule,
    ToastrModule.forRoot(),
    FooterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ImageCropperModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
