import { Component, OnInit, Input , Output , EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup,FormControl , FormArray , FormBuilder, Validators} from '@angular/forms';
import { DisplayLoadData } from './bidperloaddata';
import { SupplierDetails } from 'app/class/supplier-details';
import { ViewBidsSupplierPerLoadService } from "../../service/view-bids-supplier-per-load.service";
import { SupplierSingleViewService } from 'app/service/supplier-single-view.service';
import { TruckieFunctionsService } from 'app/service/truckie-functions.service';
import { APiProperties } from '../../class/api-properties';
import { zip } from 'rxjs';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-supplier-bid-par-load',
  templateUrl: './supplier-bid-par-load.component.html',
  styleUrls: ['./supplier-bid-par-load.component.css']
})
export class SupplierBidParLoadComponent implements OnInit {
  
  @Input() loadData : DisplayLoadData;
  @Output() valueChange = new EventEmitter();
  @ViewChild('closeModal') closeModal: ElementRef;

  apiProperties : APiProperties = new APiProperties();

  supplierBidsPerLoadTableData : supplierBidsPerLoadTableData = new supplierBidsPerLoadTableData();
  
  requestData : {};
  truckRequestData : {};

  ViewBidsSupplierPerLoad : any=[];
  ViewBidsSupplierPerLoadList: any;

  TruckList: any;
  TruckListData: any;
  showSupplier: boolean;
  truckListWinForm: FormGroup;
  bidWinnerData: { loadId: any; supplierId: any; truckModelId: any; countOfAcceptedTrucks: string; };

  viewBidWinnerData : any=[];
  dbwResult = false;
  dbwData: any;

  WinBigRequestData: { loadId: any; supplierId: any; };
  winBidTDBList: any=[];
  singleMNSearch =  false;
  singleMNBlk = true;

  //requestData : any = {};
  responseData : any = [];
  displaySupplierDetails = false;
  supplierNotAvailable = false;
  displaySupplierBankDetails = false;
  searched = false;
  readonlyInput = false;
  displayWalletRecharge = false;

  supplierIdP: any;
  supplierNameP: any;
  supplierStatusP: any;
  supplierCityNameP: any;
  supplierTypeP: string;

  supplierIdData: {};
  SupplierAllTrucksList: any;
  SupplierAllTrucks: any;
  listOfTrucks: any;

  loadApplyData: {};

  displaySupplierTrucks = false;
  addTruckList: any;
  resp: { truck_model_id: any; count: any; };
  SupplierManualSubmit: {};

  SupplierTrucksSuccess= false;
  SupplierTrucksError= false;
  pdata: any;

  //supplierSubmitData: { loadId: any; supplyUserId: any; clientId: any, clientSecret: any, submittedSupplyRate: any; listOfTrucks: any; };
  //supplierSubmitData: { loadId: any; supplyUserId: any; clientId: any, clientSecret: any, listOfTrucks: any; };
  supplierSubmitData : any = {};

  oldBidRate : any;
  resubmitSupplierId : any;
  responseResubmitData : any = [];
  responseNewLoadDetails : any = [];
  responseDeviceDetails : any = [];
  responseNotificatiion : any = [];

  notificationButton : boolean = true;
  interval;


  constructor( private rest: ViewBidsSupplierPerLoadService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder, 
    private supplierSingleViewService: SupplierSingleViewService,
    private tfs: TruckieFunctionsService) { }

  ngOnInit() {
    this.getSpecificListOfSupplierPerLoad();
    this.setLoadDetails();

    this.getListOfSupplierBidsPerLoad();
    this.getLoadDetail();

    console.log(this.loadData);
    console.log("Load Status : "+this.loadData.loadStatus);
  }

  loadResData : any = [];
  loadDetailsRes : any = [];

  getLoadDetail(){

    this.requestData= {
      clientId: this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret,
      loadId : this.loadData.loadId
    };
    //console.log(JSON.stringify(this.requestData));
    
    this.tfs.loadDetailsForAdmin(JSON.stringify(this.requestData)).subscribe((data : {}) => {
      this.loadResData =  data;
      this.loadDetailsRes = this.loadResData.loadDetails[0];
      //console.log("MR Data : "+JSON.stringify(this.loadDetailsRes));
    });

  }

  loadDetailForm = new FormGroup({
    loadId: new FormControl(),
    fromCity: new FormControl(),
    toCity : new FormControl(),
    branch : new FormControl(),
    orderType : new FormControl()
  });

  // truckListForm = new FormGroup({
  //   supplierName: new FormControl(),
  //   supplierNumber: new FormControl(),
  //   submittedRate : new FormControl()
  // });

  searchForm = this.formBuilder.group({
    supplierNumber : ['', Validators.required]
  });
  
  supplierDetailForm = new FormGroup({
    name: new FormControl(),
    city: new FormControl(),
    type : new FormControl(),
    status : new FormControl()
  });

  getFormGroupForLine(orderLine: any): FormGroup {
    return new FormGroup({
      price: new FormControl(orderLine.price)
    })
  }

  subRequestData : any = {};
  listOfSupplierBidsPerLoadRes : any = [];
  listOfSupplierBidsPerLoadResList : any = [];
  finalList : any;

  loadIds : any=[];
  TableData = [];
  cwslResponse : any = [];
  
  getListOfSupplierBidsPerLoad(){
      
    this.requestData= {
      clientId: this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret,
      loadId : this.loadData.loadId
    };
    //console.log(this.requestData);
    
    this.tfs.listOfSupplierBidsPerLoad(JSON.stringify(this.requestData)).subscribe((data : {}) => { 
      this.listOfSupplierBidsPerLoadRes =  data;
      console.log("listOfSupplierBidsPerLoad ::"+ JSON.stringify(this.listOfSupplierBidsPerLoadRes));
      
      if(this.listOfSupplierBidsPerLoadRes.status == 1){
        
        if(this.listOfSupplierBidsPerLoadRes.list.length > 0){

          this.listOfSupplierBidsPerLoadResList = this.listOfSupplierBidsPerLoadRes.list;
          console.log("Length :: "+this.listOfSupplierBidsPerLoadResList.length);

          for(var i in this.listOfSupplierBidsPerLoadResList){
            
            this.subRequestData = {
              loadId : this.loadData.loadId,
              supplyId : this.listOfSupplierBidsPerLoadResList[i].supplyId
            };

            this.loadIds.push(JSON.stringify(this.subRequestData));
          }

            console.log(i);
            
            this.tfs.countWeightProvidedBySupplierAsPerLoad(this.loadIds).subscribe((data : {}) => {
                
              this.cwslResponse =  data;
              //console.log(this.cwslResponse.weight);

              for(var i in this.listOfSupplierBidsPerLoadResList){
               
                      this.supplierBidsPerLoadTableData = new supplierBidsPerLoadTableData();
                      this.supplierBidsPerLoadTableData.supplyId = this.listOfSupplierBidsPerLoadRes.list[i].supplyId;
                      this.supplierBidsPerLoadTableData.loadId = this.listOfSupplierBidsPerLoadRes.list[i].loadId;
                      this.supplierBidsPerLoadTableData.supplierName = this.listOfSupplierBidsPerLoadRes.list[i].supplierName;
                      this.supplierBidsPerLoadTableData.supplierCity = this.listOfSupplierBidsPerLoadRes.list[i].supplierCity;
                      this.supplierBidsPerLoadTableData.supplierContectNumber = this.listOfSupplierBidsPerLoadRes.list[i].supplierContectNumber;
                      this.supplierBidsPerLoadTableData.submittedTrucksList = this.listOfSupplierBidsPerLoadRes.list[i].submittedTrucksList;
                      this.supplierBidsPerLoadTableData.weight = this.cwslResponse[i].weight;
                      this.supplierBidsPerLoadTableData.count = this.cwslResponse[i].count;
                      
                      // if(this.loadStatus[i] ==null){
                      //   console.log(this.ViewLiveLoad.list[i].loadId+" null status:"+this.loadStatus[i]);
                      // }
                      // else{
                      //   this.viewLiveLoadTableData.loadStatus = this.loadStatus[i].code;
                      //   console.log(this.ViewLiveLoad.list[i].loadId+"status:"+this.loadStatus[i]);
                      // }
                      
                     this.TableData.push(this.supplierBidsPerLoadTableData);
              }
              console.log(this.TableData);

            });

        }
      }
    });

  }

  getSpecificListOfSupplierPerLoad(){

    this.requestData= {
      loadId : this.loadData.loadId
    };
    
    //console.log(JSON.stringify(this.requestData));
    //console.log(this.requestData);
    //console.log(this.loadData.loadId);

    this.rest.getSpecificListOfSupplierPerLoad(JSON.stringify(this.requestData)).subscribe((data : {}) => {
                                       this.ViewBidsSupplierPerLoad =  data;
                                       this.ViewBidsSupplierPerLoadList = this.ViewBidsSupplierPerLoad.list;
                                       //console.log(this.ViewBidsSupplierPerLoadList);
                            }
                    )
  }
   
  setLoadDetails(){
    this.loadDetailForm.controls.loadId.setValue(this.loadData.loadId);
    this.loadDetailForm.controls.fromCity.setValue(this.loadData.fromCityName);
    this.loadDetailForm.controls.toCity.setValue(this.loadData.toCityName);
    this.loadDetailForm.controls.branch.setValue(this.loadData.branchName);
    this.loadDetailForm.controls.orderType.setValue(this.loadData.order_type);
  }

  getTruckList(loadId,supplyId,supplierName,supplierNumber,submittedRate){

    this.hideNotificationButton(loadId,supplyId);

    this.truckRequestData={
      clientId: this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret,
      loadId : loadId ,
      supplyId : supplyId
    };

    //console.log(this.truckRequestData);

    this.tfs.truckAvailabilityPerBidPerLoad(JSON.stringify(this.truckRequestData)).subscribe((data : {}) => {  
      
      this.TruckList = data;
      this.TruckListData = this.TruckList.list;
      //console.log(this.TruckListData);

    }, error => console.log(error));
  }

  winningBidTDB(loadId,supplyId){

    this.WinBigRequestData={
      loadId : loadId ,
      supplierId : supplyId
    };

    // this.WinBigRequestData={
    //   loadId : 10 ,
    //   supplierId : 1
    // };

    //console.log(JSON.stringify(this.WinBigRequestData));

    this.rest.winningBidTDBDetails(JSON.stringify(this.WinBigRequestData)).subscribe((data : {}) => {  
      
      this.winBidTDBList = data;
      
      //this.TruckListData = this.TruckList.list;
      //console.log(this.winBidTDBList.list);

    }, error => console.log(error));
  }

  decideBidWinner(loadId,supplyId,modelId){
    
    this.bidWinnerData= {
      loadId : loadId,
      supplierId : supplyId,
      truckModelId : modelId,
      countOfAcceptedTrucks : "7"
    };
    
    //console.log(JSON.stringify(this.bidWinnerData));
    
    // this.rest.decideWinnerOfBid(JSON.stringify(this.bidWinnerData)).subscribe((data : {}) => {
    //   this.viewBidWinnerData =  data;
    //   console.log(this.viewBidWinnerData);
    // })

  }

  onSubmit(decideBidWinnerForm){

    this.bidWinnerData = decideBidWinnerForm.value;
    //console.log(this.bidWinnerData);
    //console.log(JSON.stringify(this.bidWinnerData));

    this.rest.decideWinnerOfBid(JSON.stringify(this.bidWinnerData)).subscribe((data : {}) => {
      this.viewBidWinnerData =  data;
      //console.log(this.viewBidWinnerData);

      this.dbwData = this.viewBidWinnerData.message;
      //this.dbwResult = true;

      this.showNotification('top','right',this.viewBidWinnerData.message, '1');

      // if(this.dbwData == "Success"){
      //   //console.log("hello");
      //   this.showNotification('top','right','Truck Count Updated', '1');
      // } else {
      //   //this.showNotification('top','right',this.dbwData , '2');
      //   this.showNotification('top','right','Please Enter Valid Win Count', '2');
      // }

    })

  }

  showBtn=-1;
  showUndoBtn(index){
    this.showBtn=index;
    //this.dbwResult = false;
    this.showTDBBlock=-1;
  }

  showTDBBlock=-1;
  showTDBBtn(index){
    this.showTDBBlock=index;
    this.showBtn=-1;
  }

  GOBack(){
    this.valueChange.emit();
  }


  showNotification(from, align, messageDis, errorNo) {
    
    // const color = Math.floor(Math.random() * 5 + 1);
    const color = errorNo;
    
    //console.log(errorNo);

    switch (color) {
      
      case '1':
        this.toastr.success(
          '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">'+messageDis+'</span>',
          "",
          {
            timeOut: 500,
            // closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-success alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;
      
        case '2':
        this.toastr.warning(
        '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">'+messageDis+'</span>',
          "",
          {
            timeOut: 4000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-danger alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;
      
        default:
        break;
    }

  }

  singleMNCall(){
    this.singleMNBlk = false;
    this.singleMNSearch = true;
  }

  get formValidation(){
    return this.searchForm.controls;
  }

  keyPressForNumber(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  searchSupplier(){

    this.searched = true;

    this.readonlyInput = true;

    if (this.searchForm.invalid) {
      return;
    }

    this.requestData = {
      supplierNumber : this.searchForm.get("supplierNumber").value
    };

    this.supplierSingleViewService.getSupplierDetailsByNumber(JSON.stringify(this.requestData)).subscribe((data:{}) => {

      this.responseData = data;

      //console.log(this.responseData);

      if(this.responseData.status==1){

        if(this.responseData.details !=0){

          this.displaySupplierDetails = true;

          this.supplierIdP = this.responseData.details[0].supplierId;
          this.supplierNameP = this.responseData.details[0].supplierName;
          this.supplierCityNameP = this.responseData.details[0].supplierCityName;
          

          if(this.responseData.details[0].supplierType == 1){
            this.supplierTypeP = "DCO" ; 
          }
          else if(this.responseData.details[0].supplierType == 2){
            this.supplierTypeP = "Broker" ;
            
          }
          else if(this.responseData.details[0].supplierType == 3){
            this.supplierTypeP = "Both" ;
          }

          if(this.responseData.details[0].supplierStatus == 0){
            this.supplierStatusP = "Disable";
          }
          else if(this.responseData.details[0].supplierStatus == 1){
            this.supplierStatusP = "Active";
          }
          else if(this.responseData.details[0].supplierStatus == 2){
            this.supplierStatusP = "Suspend";
          }
          else if(this.responseData.details[0].supplierStatus == 3){
            this.supplierStatusP = "In Approval";
          }
          
            // this.loadApplyData = {
            //   loadId : this.loadData.loadId,
            //   supplierId : this.responseData.details[0].supplierId
            // };

            this.loadApplyData = {
              clientId: this.apiProperties.clientId,
              clientSecret : this.apiProperties.clientSecret,
              loadId : this.loadData.loadId
            };

            console.log(this.loadApplyData);

            //this.supplierSingleViewService.newLoadDetail(JSON.stringify(this.loadApplyData)).subscribe((data:{}) => { 
            this.tfs.loadDetailsForAdmin(JSON.stringify(this.loadApplyData)).subscribe((data:{}) => {   

            //console.log(data);
            this.SupplierAllTrucks = data;
            //this.SupplierAllTrucksList = this.SupplierAllTrucks.list;
            //this.listOfTrucks = this.SupplierAllTrucks.list[0].listOfTrucks;

            this.SupplierAllTrucksList = this.SupplierAllTrucks.loadDetails[0];
            this.listOfTrucks = this.SupplierAllTrucks.loadDetails[0].listOfTrucks;
            console.log(this.SupplierAllTrucks);

            this.displaySupplierTrucks = true;
    
          },error =>{
          console.log("Error: "+error);
        });


        // this.supplierIdData = {
        //   supplierId : this.responseData.details[0].supplierId,
        // };
        //console.log(JSON.stringify(this.supplierIdData));
        
        //   this.rest.fetchAllTrucksOfSupplier(JSON.stringify(this.supplierIdData)).subscribe((data:{}) => { 

        //     //console.log(data);
        //     this.SupplierAllTrucks = data;
        //     this.SupplierAllTrucksList = this.SupplierAllTrucks.list;
        //     console.log(this.SupplierAllTrucksList);

        //     this.displaySupplierTrucks = true;
    
        //   },error =>{
        //   console.log("Error: "+error);
        // });

        }
        
      } else {

        this.supplierNotAvailable = true;

      }

    },error =>{
      console.log("Error: "+error);
    });

  }

  abc = [];
  ratePerTonPush(index,e){
        console.log("truckModelId : "+index+" , submittedSupplyRate :"+e.target.value);
        var a = {
          truckModelId : index,
          submittedSupplyRate: e.target.value
        };
        
        if(this.abc.length == 0){
          this.abc.push(a);
        } else {
          
          var mn = false;
          for(var i in this.abc){
            if(this.abc[i].truckModelId == index){
              this.abc[i].submittedSupplyRate = e.target.value;
              mn = true; 
            } 
          }
          
          if(!mn){
            this.abc.push(a);
          }
        }

        //console.log(JSON.stringify(this.abc));
  }
  
  pushdata(index,e){
    //console.log("truckModelId : "+index+" , count :"+e.target.value);
    var a = {
      truckModelId : index,
      count: e.target.value
    };

    if(this.abc.length == 0){
      this.abc.push(a);
    } else { 
        
      var mn = false;
      for(var i=0; i < this.abc.length;i++){
        if(this.abc[i].truckModelId == index){
          this.abc[i].count = e.target.value; 
          mn = true;
        } 
      }

      if(!mn){
        this.abc.push(a);
      }
      
      //console.log(JSON.stringify(this.abc));
    }
  }

  bidPRate = [];

  BidRateChange(bidRateEv){
    var bidRateFin = bidRateEv.target.value;
    this.bidPRate.push(bidRateFin); 
  }

  submitBid(sid){

    if((this.abc.length < this.SupplierAllTrucksList.length) || (this.bidPRate[0] < 0)){
      
      this.SupplierTrucksError = true;
      
    } else {

      //console.log("data:"+JSON.stringify(this.abc));
        var hello = this.abc; 

        this.supplierSubmitData = {
            loadId: this.loadData.loadId,
            supplyUserId: sid,
            clientId: this.apiProperties.clientId,
            clientSecret : this.apiProperties.clientSecret,
            listOfTrucks : hello
        };
        //submittedSupplyRate: this.bidPRate[0],
      
      console.log(JSON.stringify(this.supplierSubmitData));
      
      this.tfs.submitSupplyBidForLoad(JSON.stringify(this.supplierSubmitData)).subscribe((data:{}) => { 
  
        this.SupplierManualSubmit = data;
        //console.log(this.SupplierManualSubmit);
        
        this.displaySupplierTrucks = false;
        this.SupplierTrucksSuccess = true;
  
      },error =>{
        console.log("Error: "+error);
      });

    }
  } 
  
  resetSearch(){
    this.displaySupplierDetails = false;
    this.supplierNotAvailable = false;
    this.displaySupplierTrucks = false;
    this.readonlyInput = false;
    this.searched = false;
    this.SupplierTrucksSuccess = false;
    this.searchForm.reset();
  }

  reSubmitBidDetails(data){

  //  alert(data.supplyId+":::::::"+data.loadId);

  this.clearAbcData();

  this.newBidRate = 0;

  this.oldBidRate = data.bidRate;
  this.resubmitSupplierId = data.supplyId;

    this.supplierIdData = {
      supplierId : data.supplyId
    };

    this.getTruckList(data.loadId,data.supplyId,null,null,null);
    
  }
  
  newBidRate :any =0;

  NewBidRateChange(event){

    var bidRate = event.target.value;

    if(bidRate >= this.oldBidRate){
      alert("NEW Bid Rate can be only lower than existing Bid Rate.");
      this.newBidRate = 0;
    }
    else{
      this.newBidRate = bidRate;
    }
    
    //this.bidPRate.push(bidRateFin); 
  }

  reSubmitBid(){

    if(this.abc.length < this.TruckListData.length){
      alert("Please enter valid data.");
      return;
    }

    this.supplierSubmitData = {
      loadId: this.loadData.loadId,
      supplyUserId: this.resubmitSupplierId,
      clientId: this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret,
      listOfTrucks : this.abc
    };

    //console.log("resubmit request data:",JSON.stringify(this.supplierSubmitData));

    this.tfs.resubmitSupplyBidForLoad(JSON.stringify(this.supplierSubmitData)).subscribe((data:{}) => { 
  
      this.responseResubmitData = data;

      if(this.responseResubmitData.status ==1){

        alert("Bid Resubmit Successfully!");
        this.closeModal.nativeElement.click();
        this.getSpecificListOfSupplierPerLoad();
      }else{
        //console.log("responseResubmitData :",this.responseResubmitData);
        alert(this.responseResubmitData.message);
      }

    },error =>{
      console.log("Error: "+error);
    });

  }

  nldResFromList : any = [];

  informSupplier(data){
  //  alert(data.supplyId+":::::::"+data.loadId);
  const loadId = data.loadId;
  const supplierId = data.supplyId;

  this.notificationButton = false;

    this.loadApplyData = {
      clientId: this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret,
      loadId : data.loadId,
      supplierId : data.supplyId
    };

    console.log("loadApplyData :"+JSON.stringify(this.loadApplyData));

    var deviceDetailRequest = {
      supplierId : data.supplyId
    };

    // zip(this.supplierSingleViewService.newLoadDetail(JSON.stringify(this.loadApplyData)),
    //     this.supplierSingleViewService.getDeviceIdOfSupplier(JSON.stringify(deviceDetailRequest))).subscribe(([response1,response2]) => {

    zip(this.tfs.detailsOfLoad(JSON.stringify(this.loadApplyData)),
          this.supplierSingleViewService.getDeviceIdOfSupplier(JSON.stringify(deviceDetailRequest))).subscribe(([response1,response2]) => {
        
          this.responseNewLoadDetails = response1;
          this.responseDeviceDetails = response2;

          console.log(this.responseNewLoadDetails,"::::::::",this.responseDeviceDetails);

          this.nldResFromList = this.responseNewLoadDetails.list[0].fromCityList[0].cityName;

          console.log("From City :"+ this.nldResFromList);

          console.log("Reporting date : "+this.responseNewLoadDetails.list[0].reportingDate); 
          

          if(this.responseNewLoadDetails.status ==1 && this.responseDeviceDetails.status ==1){

            var notificationRequest = {

              reportingDate : this.responseNewLoadDetails.list[0].reportingDate,
              fromCity : this.responseNewLoadDetails.list[0].fromCityList[0].cityName,
              toCity : this.responseNewLoadDetails.list[0].toCityList[0].cityName,
              LoadId : data.loadId,
              deviceId : this.responseDeviceDetails.deviceId
            };

            console.log(notificationRequest);

            this.rest.notificationInformBidWinner(JSON.stringify(notificationRequest)).subscribe((data:{}) => { 
  
              this.responseNotificatiion = data;
        
              if(this.responseNotificatiion.status ==1){
        
               
                
                var date = new Date();
              
                var a = {
                  send : true,
                  date_time : new Date()
                };

                localStorage.setItem(loadId+"-notificationSend-"+supplierId,JSON.stringify(a));

                alert("Notification send Successfully!");
                this.hideNotificationButton(loadId,supplierId);

              }else{
                this.notificationButton = true;
                console.log("Response of notificatiion :",this.responseNotificatiion);
                alert(this.responseNotificatiion.message);
              }
        
            },error =>{
              console.log("Error: "+error);
            });

          }
          else{

            this.notificationButton = true;

            console.log("Response of new load details :",this.responseNewLoadDetails);
            console.log("Response of get deviceid of supplier :",this.responseDeviceDetails);
            alert("Error occured while sending notification please try again later");
          }
        
        }
    );
  }

  clearAbcData(){

    while(this.abc.length > 0){

      this.abc.shift();
    }

  }

  hideNotificationButton(loadId,supplierId){

    const item = JSON.parse(localStorage.getItem(loadId+"-notificationSend-"+supplierId));
    const now = new Date();
    
    if(item == null){

      this.notificationButton = true;
      return;
    }

    if(item.send){

      const n_date = new Date(item.date_time);

      this.notificationButton = false;

      const diff = now.getTime() -n_date.getTime();

      var resultInMinutes = Math.round(diff / 60000);

      if (resultInMinutes > 1 ) {

        var a = {
                  send : false,
                  date_time : new Date()
                };

        localStorage.setItem(loadId+"-notificationSend-"+supplierId,JSON.stringify(a));

        this.notificationButton = true;
        
      }else{

        this.interval = setInterval(() => {

          const now = new Date();
          const diff = now.getTime() -n_date.getTime();
          var resultInMinutes = Math.round(diff / 60000);

          if (resultInMinutes >1 ) {

            var a = {
                      send : false,
                      date_time : new Date()
                    };
    
            localStorage.setItem(loadId+"-notificationSend-"+supplierId,JSON.stringify(a));
    
            this.notificationButton = true;
            
            clearInterval(this.interval);

          }

        }, 5*1000);
      }
      
    }
    else{
      this.notificationButton = true;
    }

  }

}

export class supplierBidsPerLoadTableData {

  supplyId : any;
  loadId : any;
  supplierName : any;
  supplierCity : any;
  supplierContectNumber : any;
  truckModelId : any;
  truckModelName : any;
  quantity : any;
  submittedRate : any;
  weight : any;
  count : any;
  submittedTrucksList : any;
}

