export class APiProperties {   
    
    s3AccessKeyId : any ;
    s3SecretAccessKey : any ;
    s3Region : any ;
    s3Bucket : any ;
    clientId : any;
    clientSecret : any;

    truckiefunctionsApi : any; 
    truckieRestApiUrl : any;

    
    removeMappedCityKey : any;

    getTruckListForSupplierApi : any;
    getTruckListForSupplierKey : any;

    getDriverListForSupplierApi : any;
    getDriverListForSupplierKey : any;

    forgetPasswordBranchUserApi : any;
    forgetPasswordBranchUserKey : any;

    passwordSmsSendApi : any;
    passwordSmsSendKey : any;

    decideBidWinnerAPI: any;
    decideBidWinnerKey: any;

    tdbdetailAPI: any;
    tdbdetailKey: any;

    submitSupplyBidKey: any;

    loadDetailKey: any;

    newLoadDetailApi: any;
    newLoadDetailKey: any;

    resubmitSupplyBidAPI: any;
    resubmitSupplyBidKey: any;

    getDeviceIdOfSupplierKey : any;

    notificationInformBidWinnerApi : any;
    notificationInformBidWinnerKey : any;

    getTruckOwnerPanDetailsApi : any;

    addTruckPanCardApi : any;
    addTruckInsuranceApi : any;
    addTruckBankApi : any;

    addSupplyUserProfileApi : any;
    
    constructor() {
	console.log(" window.location.hostname  "+window.location.hostname );
        if((window.location.hostname == 'localhost') || (window.location.hostname == 'stagingadmin.truck99.in')){
        //if(window.location.hostname == 'admin.truckie.in'){
        //if((window.location.hostname == 'admin.truck99.in')){        

            this.s3AccessKeyId = "AKIA23RU6PNZPIXDEV63";
            this.s3SecretAccessKey = "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";
            this.s3Region = "ap-south-1";
            this.s3Bucket = "truckie-staging";
            this.clientId = "mobapp";
            this.clientSecret = "mobappsecret";
                        
            this.truckiefunctionsApi = 'https://stagingfunctions.truck99.in/';
            this.truckieRestApiUrl = 'https://stagingapi.truck99.in/rest/V1/';

        } else {

            this.s3AccessKeyId = "AKIA23RU6PNZPIXDEV63";
            this.s3SecretAccessKey = "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";
            this.s3Region = "ap-south-1";
            this.s3Bucket = "truckie-live";
            this.clientId = "trucklive";
            this.clientSecret = "5tYOBoImh96I2WDf";

            this.truckiefunctionsApi = 'https://node.truck99.in/';
            this.truckieRestApiUrl = 'https://api.truck99.in/rest/V1/';
            
        }

    }
    
}
