import { Component, OnInit,  Input, Output, ViewChildren, ViewChild, QueryList, ElementRef } from '@angular/core';
import { FormBuilder , FormGroup , Validators } from '@angular/forms';
import { APiProperties } from 'app/class/api-properties';
import { DatePipe,formatDate } from '@angular/common';
import { ToastrService } from "ngx-toastr";
import { TruckieFunctionsService } from '../../service/truckie-functions.service';
import { TruckieApiService } from '../../service/truckie-api.service';

@Component({
  selector: 'app-supplier-city-management',
  templateUrl: './supplier-city-management.component.html',
  styleUrls: ['./supplier-city-management.component.css']
})
export class SupplierCityManagementComponent implements OnInit {
  apiProperties : APiProperties = new APiProperties();
  @Input('supplierId') supplierId;
  @ViewChild('closeDeleteSupplierCityModal') closeDeleteSupplierCityModal: ElementRef;

  constructor(private fb: FormBuilder,
    private tfs : TruckieFunctionsService, 
    private tas: TruckieApiService,
    private toastr : ToastrService,
    private datePipe: DatePipe,) { }

  requestData: any = {};
  stateListRes: any = []; 
  submitted;
  ngOnInit(): void {
    this.submitted = false;
    this.fetchStateListFun();
    this.getSupplierLinkCityListFun();
  }

  saveSupplierLinkCityForm = this.fb.group({
    stateId: ['',[Validators.required]],
    cityId : ['',[Validators.required]],
  });
  get f() { return this.saveSupplierLinkCityForm.controls; }

  fetchStateListFun(){

    this.requestData = {
      clientId : this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret
    };
    console.log(this.requestData);

    return this.tfs.fetchStateList(JSON.stringify(this.requestData)).subscribe((data : {}) => {
        this.stateListRes = data;
        console.log(this.stateListRes);

    }, error => console.log(error));

  }

  cityListBasedOnStateWithStatusRes : any = [];
  fetchCityListBasedOnStateWithStatusFun(){

    this.requestData = {
      clientId: this.apiProperties.clientId,
      clientSecret: this.apiProperties.clientSecret,
      stateId: this.saveSupplierLinkCityForm.controls['stateId'].value
    };
    console.log(this.requestData);

    return this.tfs.fetchCityListBasedOnStateWithStatus(JSON.stringify(this.requestData)).subscribe((data : {}) => {
        this.cityListBasedOnStateWithStatusRes = data;
        console.log(this.cityListBasedOnStateWithStatusRes);

    }, error => console.log(error));
    
  }

  saveSupplierLinkCityRes : any = [];
  saveSupplierLinkCityFun(){

    this.requestData = {
      supplierId: this.supplierId,
      cityId: this.saveSupplierLinkCityForm.controls['cityId'].value
    };
    console.log(this.requestData);

    return this.tas.saveSupplierLinkCity(JSON.stringify(this.requestData)).subscribe((data : {}) => {
        this.saveSupplierLinkCityRes = data;
        console.log(this.saveSupplierLinkCityRes);

        if(this.saveSupplierLinkCityRes.status == 1){
          this.showNotification('top','right',this.saveSupplierLinkCityRes.message, '1');
          this.getSupplierLinkCityListFun();

          this.submitted = false;
          this.saveSupplierLinkCityForm.reset();
          this.saveSupplierLinkCityForm.controls['stateId'].setValue('');
          this.saveSupplierLinkCityForm.controls['cityId'].setValue('');
        } else {
          this.showNotification('top','right',this.saveSupplierLinkCityRes.message, '3');
        }

    }, error => console.log(error));
    
  }

  supplierLinkCityListRes : any = [];
  getSupplierLinkCityListFun(){

    this.requestData = {
      supplierId: this.supplierId
    };
    console.log(this.requestData);

    return this.tas.getSupplierLinkCityList(JSON.stringify(this.requestData)).subscribe((data : {}) => {
        this.supplierLinkCityListRes = data;
        console.log(this.supplierLinkCityListRes);

    }, error => console.log(error));
    
  }

  cityNameVal: any;
  cityIdVal: any;
  idVal: any;
  setSupplierLinkCity(data){
    this.cityNameVal = data.cityName;
    this.cityIdVal = data.cityId;
    this.idVal = data.id;

    // if(action == 'Update'){
    //   this.updateGroupNameForm.controls.groupName.setValue(this.groupNameVal)
    // }

  }

  removeSupplierLinkCityRes : any = [];
  removeSupplierLinkCityFun(){

    this.requestData = {
      id: this.idVal
    };
    console.log(this.requestData);

    return this.tas.removeSupplierLinkCity(JSON.stringify(this.requestData)).subscribe((data : {}) => {
        this.removeSupplierLinkCityRes = data;
        console.log(this.removeSupplierLinkCityRes);

        if(this.removeSupplierLinkCityRes.status == 1){
          this.closeDeleteSupplierCityModal.nativeElement.click();
          this.showNotification('top','right',this.removeSupplierLinkCityRes.message, '1');
          this.getSupplierLinkCityListFun();
        } else {
          this.showNotification('top','right',this.removeSupplierLinkCityRes.message, '3');
        }

    }, error => console.log(error));
    
  }

  showNotification(from, align, messageDis, errorNo) {
    
    //const color = Math.floor(Math.random() * 5 + 1);
    const color = errorNo;
    //console.log(errorNo);

    switch (color) {
      
        case '1':
        this.toastr.success(
          '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">'+messageDis+'</span>',
          "",
          {
            timeOut: 500,
            // closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-success alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;
      
        case '2':
        this.toastr.warning(
        '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">'+messageDis+'</span>',
          "",
          {
            timeOut: 4000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-danger alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;
      
        case '3':
        this.toastr.error(
        '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">'+messageDis+'</span>',
          "",
          {
            timeOut: 4000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-danger alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;

        default:
        break;
    }

  }

}