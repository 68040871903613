import { Injectable } from '@angular/core';
import { Observable,throwError,forkJoin } from 'rxjs';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { APiProperties } from 'app/class/api-properties';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http:HttpClient) { }

  login(data : {}): Observable<{}> {
    return ;
  }

  logout(): void {
    localStorage.setItem('isLoggedIn', "false");
    //localStorage.setItem('userRole','');
    localStorage.removeItem('userRole');
    localStorage.removeItem('token');
    //window.location.reload();
  } 

  apiProperties : APiProperties = new APiProperties();

  branchUserLoginHttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  branchUserLogin(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'validate_branch_user_login', data ,this.branchUserLoginHttpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  handleError(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
 }


}
