import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable,throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { APiProperties } from 'app/class/api-properties';

@Injectable({
  providedIn: 'root'
})
export class TruckieApiService {

  constructor(private http:HttpClient) { }
  apiProperties : APiProperties = new APiProperties();

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  uploadImage(data : FormData) : Observable<{}> {
    //console.log("uploadImage service: "+data);

    let upHttpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
      })
    }

    return this.http.post(this.apiProperties.truckieRestApiUrl+'uploadImage', data ,upHttpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  uploadBulkLoad(data : FormData) : Observable<{}> {

    let upHttpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
      })
    }

    return this.http.post(this.apiProperties.truckieRestApiUrl+'uploadBulkLoadExcel', data, upHttpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  uploadBulkDriver(data : FormData) : Observable<{}> {

    let upHttpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
      })
    }

    return this.http.post(this.apiProperties.truckieRestApiUrl+'uploadBulkDriverDetails', data, upHttpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  sampleDriverUpload(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckieRestApiUrl+'sampleUploadBulkDriverDetails', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  uploadBulkBank(data : FormData) : Observable<{}> {

    let upHttpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
      })
    }

    return this.http.post(this.apiProperties.truckieRestApiUrl+'uploadBulkBankDetails', data, upHttpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  sampleBankUpload(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckieRestApiUrl+'sampleUploadBulkBankDetails', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  uploadBulkTruck(data : FormData) : Observable<{}> {

    let upHttpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
      })
    }

    return this.http.post(this.apiProperties.truckieRestApiUrl+'uploadBulkTruckDetails', data, upHttpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  sampleTruckUpload(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckieRestApiUrl+'sampleUploadBulkTruckDetails', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  getMasterLoadReport(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckieRestApiUrl+'getMasterLoadReport', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  loginAdmin(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckieRestApiUrl+'loginAdmin', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  getLoginRoleList(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckieRestApiUrl+'getLoginRoleList', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  addAdminUser(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckieRestApiUrl+'addAdminUser', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  getAdminLoginUserList(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckieRestApiUrl+'getAdminLoginUserList', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  resendAdminUserPassword(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckieRestApiUrl+'resendAdminUserPassword', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  changeAdminProfileDetails(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckieRestApiUrl+'changeAdminProfileDetails', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  updateAdminUserStatus(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckieRestApiUrl+'updateAdminUserStatus', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  saveSupplierLinkCity(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckieRestApiUrl+'saveSupplierLinkCity', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  getSupplierLinkCityList(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckieRestApiUrl+'getSupplierLinkCityList', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  removeSupplierLinkCity(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckieRestApiUrl+'removeSupplierLinkCity', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  getUserAccessURLList(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckieRestApiUrl+'getUserAccessURLList', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  addAdminRole(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckieRestApiUrl+'addAdminRole', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  updateRoleStatus(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckieRestApiUrl+'updateRoleStatus', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  deleteAdminRole(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckieRestApiUrl+'deleteAdminRole', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  addAdminUrlAccess(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckieRestApiUrl+'addAdminUrlAccess', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  deleteAdminUrlAccess(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckieRestApiUrl+'deleteAdminUrlAccess', data ,this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  handleError(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
}