import { Component, OnInit , ViewChild} from '@angular/core';
import { FormGroup , FormBuilder , Validators } from '@angular/forms';
import { APiProperties } from '../../class/api-properties';
import { BranchService } from 'app/service/branch.service';
import { SupplierListService } from 'app/service/supplier-list.service';
import { TruckModelService } from "../../service/truck-model.service";
import { TruckBankService } from '../../service/truck-bank.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import * as S3 from 'aws-sdk/clients/s3';
import { SafeUrl, DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-truck-bank',
  templateUrl: './truck-bank.component.html',
  styleUrls: ['./truck-bank.component.css']
})
export class TruckBankComponent implements OnInit {
  
  @ViewChild('closeButton') closeButton;
  properties : APiProperties = new APiProperties();
  
  constructor(private fb: FormBuilder, 
              private branchService : BranchService, 
              private supplierListService : SupplierListService, 
              private tms : TruckModelService, 
              private sanitizer: DomSanitizer, 
              private tbs: TruckBankService) { }


  truckOwnerBankSaveForm : FormGroup;
  submitted = false;
  dbwResult = false;

  City : any=[];
  requestDataLSC : any={};
  supplierListRes : any=[];
  TruckModelRes : any=[];
  
  truckOwnerBankRequestData : {};
  saveTruckOwnerBankRes: any=[];

  interval;
  updateVehicleConfigImageRes : any;
  vmiID;
  uvmiID;

  fileToUpload: any;

  beforeUpload = true;
  afterUpload = false;

  afterRCImg = false;
  image: any;
  cancelChequePhotoCls: any;
  
  truckBankBlk = true;
  truckImageUpload = false;

  

  ngOnInit(): void {

    this.truckOwnerBankSaveForm = this.fb.group({
      SupplierCity : ['' , Validators.required], 
      supplierId : ['' , Validators.required],
      truckId : ['' , Validators.required],
      IFSCCode : ['' , Validators.required],
      bankName : ['' , Validators.required],
      bankAccountNumber : ['' , Validators.required],
      bankAccountName : ['' , Validators.required],
      cancelChequePhoto : ['' , Validators.required],
      verificationStatus : ['']
    });

    this.submitted = false;

    this.getCity();
    this.getTruckModelList();
 
  }

  get f() { return this.truckOwnerBankSaveForm.controls; }

  getCity(){
    return this.branchService.getCity().subscribe((data : {}) => {
        this.City = data;
        //console.log(this.City);
    }, error => console.log(error));
  }

  onChangeGetValue(event){

    const value = event.target.value;
    
    this.requestDataLSC = {
      supplierCityId : value
    };
    
    this.supplierListService.getListOfSupplierPerCity(JSON.stringify(this.requestDataLSC)).subscribe((data:{})=>{
        this.supplierListRes = data;
    },error => {
      console.log("Error: "+error);
    });

  }

  getTruckModelList(){
    return this.tms.getTruckModel().subscribe((data : {}) => {
      this.TruckModelRes = data;
    }, error => console.log(error));
  }
  
  // public open(id) {
  //   this.vmiID = id;

  //   this.beforeUpload = true;
  //   this.afterUpload = false;

  //   this.afterRCImg = false;

  // }

  
  
  updateImage(ev) {
    this.image = this.sanitizer.bypassSecurityTrustUrl(
      window.URL.createObjectURL(ev.target.files[0])
    );

    this.afterRCImg = true;
  }

  handleFileInput(files: FileList) {
    //alert(files);
    this.fileToUpload = files.item(0);

    this.afterRCImg = true;
  }
  
  uploadCourseImage(vmiID){
      
      this.uvmiID = vmiID;
      this.uploadCourseImageToAws(this.fileToUpload, this.uvmiID);

      //alert(localStorage.getItem('image'));
      
      this.interval = setInterval(() => {
          
        if(localStorage.getItem('image') != null){

          //alert(localStorage.getItem('image'));

          var imagePass = localStorage.getItem('image');

          this.closeButton.nativeElement.click();

          this.afterRCImg = true;

          
          this.truckOwnerBankSaveForm.patchValue({cancelChequePhoto:imagePass});
          this.cancelChequePhotoCls = 1;
          
          
          

          clearInterval(this.interval); 
          localStorage.removeItem('image');

          window.localStorage.clear();
          
        }
    
      }, 1000);

      
      this.beforeUpload = false;
      this.afterUpload = true;
  }

  uploadCourseImageToAws(file,rcTextInt){

    //alert(rcTextInt);

    const bucket = new S3(
        {
            accessKeyId: this.properties.s3AccessKeyId,
            secretAccessKey: this.properties.s3SecretAccessKey,
            region: this.properties.s3Region
        }
    );

    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz';
    const stringLength = 10;
    let randomstring = '';
    for (let i = 0; i < stringLength; i++) {
    const rnum = Math.floor(Math.random() * chars.length);
    randomstring += chars.substring(rnum, rnum + 1);
    }
    const codeGenerated = randomstring;


      const params = {
        Bucket: this.properties.s3Bucket,
        Key: 'truck/' + codeGenerated ,
        Body: file,
        ACL: 'public-read',
        ContentType: 'image/jpeg',
        ServerSideEncryption: 'AES256'
    };


     bucket.upload(params, function (err, data) {
      if (err) {
          console.log('There was an error uploading your file: ', err);
          return false;
      } else {
          
      console.log('Successfully uploaded file.', data);
      localStorage.setItem('image',data.Location);

      return true;

      }
      
    });

  
  }
  
  saveTruckOwnerBankData(){

    this.submitted = true; 

    if(this.truckOwnerBankSaveForm.invalid){
          return;
    }

    this.truckOwnerBankRequestData = {
      supplierId : this.truckOwnerBankSaveForm.get("supplierId").value,
      truckId : this.truckOwnerBankSaveForm.get("truckId").value,
      IFSCCode : this.truckOwnerBankSaveForm.get("IFSCCode").value,
      bankName : this.truckOwnerBankSaveForm.get("bankName").value,
      bankAccountNumber : this.truckOwnerBankSaveForm.get("bankAccountNumber").value,
      bankAccountName : this.truckOwnerBankSaveForm.get("bankAccountName").value,
      cancelChequePhoto : this.truckOwnerBankSaveForm.get("cancelChequePhoto").value,
      verificationStatus : '1',
    }

    //console.log(this.truckOwnerBankRequestData);

    this.tbs.saveTruckOwnerBank(JSON.stringify(this.truckOwnerBankRequestData)).subscribe((data : {}) => {

      this.saveTruckOwnerBankRes = data;

      //alert(this.saveVehicleRes);

      if(this.saveTruckOwnerBankRes.status ==1 ){
        
        alert("Truck Owner Bank Added Successfully!");
        this.dbwResult = true;
        this.ngOnInit();
      }
      else{
        
        alert(this.saveTruckOwnerBankRes.message);
      }


    }, error => console.log(error));
  }

  supplierChange(event){
    this.supplierIdVal = event.target.value;
  }

  truckChange(event){
    this.truckIdVal = event.target.value;

    if(this.truckIdVal != ""){
      this.chequePhotoImg = true;
    } 
  }
  
  supplierIdVal : any;
  truckIdVal : any;
  documentFor : any;
  supplierId : any;
  truckId : any;
  chequePhotoImg;

  imageUploadShow() {
    this.documentFor = "truck";
    this.supplierId = this.supplierIdVal;
    this.truckId = this.truckIdVal;
    console.log(this.supplierId + " ~~~~ " + this.truckId);

    this.truckBankBlk = false;
    this.truckImageUpload = true;
  }

  upImageUrl : any;
  hideImageupload(data){
    console.log(data);
    
    this.upImageUrl = data.finalImageUp;
    console.log(this.upImageUrl);

    this.truckBankBlk = true;
    this.truckImageUpload = false;
    this.chequePhotoImg = true;
    this.cancelChequePhotoCls = 1;
  }

}