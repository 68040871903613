import { Component, OnInit , ViewChild, Input, Output, EventEmitter} from '@angular/core';
import { FormGroup , FormBuilder , Validators } from '@angular/forms';
import { APiProperties } from '../../class/api-properties';
import { TruckieFunctionsService } from 'app/service/truckie-functions.service';
import { TruckModelService } from "../../service/truck-model.service";
import { zip } from 'rxjs';
import { DatePipe,formatDate } from '@angular/common';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-truck-pancard-add',
  templateUrl: './truck-pancard-add.component.html',
  styleUrls: ['./truck-pancard-add.component.css']
})
export class TruckPancardAddComponent implements OnInit {

  @Input() supplierId;
  @Input() truckId;
  @Output() hideTruckPancardAdd = new EventEmitter();

  apiProperties : APiProperties = new APiProperties();
  
  constructor(private fb: FormBuilder, 
              private tfs : TruckieFunctionsService,
              private tms : TruckModelService,
              private datePipe: DatePipe, 
              private toastr: ToastrService) { }

  tpaBack;  
  tpaBlk; 
  truckImageUpload; 
  tPancardAdd;      
  ngOnInit(): void {
    this.tPancardAdd = true;
    this.tpaBack = true;
    this.tpaBlk = true;
  }

  truckPancardAddForm = this.fb.group({
    nameOnPanCard : ['' , Validators.required],
    numberOnPanCard : ['' , [Validators.required,Validators.minLength(10),Validators.maxLength(10)]],
    panFrontPictureURL : ['' , Validators.required],
  });
  get utp() { return this.truckPancardAddForm.controls; }

  submitted;
  requestData : any = {};
  toPancardRes : any = [];
  tPancardProgreess;
  truckPancardAdd() {
    this.tPancardAdd = false;
    this.tPancardProgreess = true;

    this.submitted = true;

    if(this.truckPancardAddForm.invalid){
      this.tPancardAdd = true;
      this.tPancardProgreess = false;
      return true;
    }
    
    this.requestData = {
      //clientId : this.apiProperties.clientId,
      //clientSecret : this.apiProperties.clientSecret,
      supplierId : this.supplierId,
      truckId : this.truckId,
      nameOnPanCard : this.truckPancardAddForm.get("nameOnPanCard").value,
      numberOnPanCard : this.truckPancardAddForm.get("numberOnPanCard").value,
      panFrontPictureURL : this.truckPancardAddForm.get("panFrontPictureURL").value,
    }
    console.log(this.requestData);

    return this.tfs.addTruckPanCard(JSON.stringify(this.requestData)).subscribe(data => {
      
      this.toPancardRes = data;
      console.log(this.toPancardRes);
      
      if(this.toPancardRes.status == 1){
        this.tPancardAdd = true;
        this.tPancardProgreess = false;
        this.showNotification('top','right',this.toPancardRes.message, '1');
        this.hideTruckPancardAdd.emit({"panCardId": this.toPancardRes.panCardId});
      } else {
        this.tPancardAdd = true;
        this.tPancardProgreess = false;
        this.showNotification('top','right',this.toPancardRes.message, '3');
      }

    },error => console.log(error));

  }

  documentFor : any;
  paramPass : any;
  upImageUrl : any;
  paramPassFinal : any;

  imageUploadShow(paramPassVal) {
    this.documentFor = "truck";
    this.supplierId = this.supplierId;
    this.truckId = this.truckId;
    this.paramPass = paramPassVal;
    console.log(this.supplierId + " ~~~~ " + this.truckId);

    this.tpaBack = false;
    this.tpaBlk = false;
    this.truckImageUpload = true;
  }

  hideImageupload(data){

    if(data != null){
      
        console.log(data);
        
        this.upImageUrl = data.finalImageUp;
        this.paramPassFinal = data.paramPassFinal;

        if(this.paramPassFinal == "panFrontPictureURL"){
          this.truckPancardAddForm.patchValue({
            'panFrontPictureURL' : this.upImageUrl,
          });
        }

    }
    
    this.tpaBack = true;
    this.tpaBlk = true;
    this.truckImageUpload = false;
    
  }

  showNotification(from, align, messageDis, errorNo) {
    
    // const color = Math.floor(Math.random() * 5 + 1);
    const color = errorNo;
    
    //console.log(errorNo);

    switch (color) {
      
      case '1':
        this.toastr.success(
          '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">'+messageDis+'</span>',
          "",
          {
            timeOut: 500,
            // closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-success alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;
      
        case '2':
        this.toastr.warning(
        '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">'+messageDis+'</span>',
          "",
          {
            timeOut: 4000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-warning alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;

        case '3':
        this.toastr.error(
        '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">'+messageDis+'</span>',
          "",
          {
            timeOut: 4000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-danger alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;
      
        default:
        break;
    }

  }

  back(){
    this.hideTruckPancardAdd.emit();
  }

}
