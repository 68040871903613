import { Component, OnInit } from '@angular/core';
import { SupplierDetails } from 'app/class/supplier-details';

@Component({
  selector: 'app-supplier-management',
  templateUrl: './supplier-management.component.html',
  styleUrls: ['./supplier-management.component.css']
})
export class SupplierManagementComponent implements OnInit {

  supplieDetails : SupplierDetails;

  constructor() { }

  displaySingleView;
  ngOnInit(): void {
    this.displaySingleView = false;
  }

  singleView(){
    this.displaySingleView = true;
    localStorage.setItem("singleView","true");
    this.supplieDetails = new SupplierDetails();
  }

  back(){
    
      //this.location.back();
  
      if(localStorage.getItem("singleView") == "true"){
  
        if(localStorage.getItem("walletRecharge") == "true"){
          
          localStorage.setItem("walletRecharge","false");
        }
        else{
          this.displaySingleView = false;
          localStorage.setItem("singleView","false");
        }
        
      }
  }

}
