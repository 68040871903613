import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { FormGroup,FormControl, Validators, FormBuilder } from '@angular/forms';
import { SupplierDetails } from 'app/class/supplier-details';
import { SupplierSingleViewService } from 'app/service/supplier-single-view.service';
import { RestApiService } from "../../rest-service/rest-api.service";
import { TruckieFunctionsService } from 'app/service/truckie-functions.service';
import { data } from 'jquery';

@Component({
  selector: 'app-supplier-single-view',
  templateUrl: './supplier-single-view.component.html',
  styleUrls: ['./supplier-single-view.component.css']
})
export class SupplierSingleViewComponent implements OnInit {

  @Input() supplieDetails : SupplierDetails;
  
  @ViewChild('closeUpdateSupplyUserProfileModal') closeUpdateSupplyUserProfileModal: ElementRef;

  constructor(private fb: FormBuilder, 
              private supplierSingleViewService : SupplierSingleViewService,
              public restApi: RestApiService,
              private tfs: TruckieFunctionsService) { }

  requestData : any = {};
  responseData : any = [];

  displaySupplierDetails;
  displaySupplierBankDetails;
  searched;
  readonlyInput;
  displayWalletRecharge;

  supplierId;
  interval;

  viewWalletHistory;
  viewBankAccount;
  viewTrucks;
  viewDrivers;
  supplierCityManagement;

  name;
  city;
  type;
  status;
  public settings = {};
  ngOnInit(): void {

    this.displaySupplierDetails = false;
    this.displaySupplierBankDetails = false;
    this.searched = false;
    this.readonlyInput = false;
    this.displayWalletRecharge = false;

    this.viewWalletHistory = false;
    this.viewBankAccount = false;
    this.viewTrucks = false;
    this.viewDrivers = false;
    this.supplierCityManagement = false;

    this.settings = {
      singleSelection: false,
      idField: 'supplierId',
      textField: 'sFullD',
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      limitSelection: -1,
      clearSearchFilter: true,
      maxHeight: 197,
      itemsShowLimit: 3,
      searchPlaceholderText: 'Search Supplier',
      noDataAvailablePlaceholderText: 'No data Vailable',
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false,
    };

    this.setSupplierDetails();
    this.fetch_city_master_staging();
  }

  searchForm = this.fb.group({
    supplierNumber : ['', Validators.required]
  });

  get formValidation(){
    return this.searchForm.controls;
  }

  //only number will be add
  keyPressForNumber(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  supplierCityId : any;
  supplierType : any;
  searchSupplier(){

    this.searched = true;

    this.readonlyInput = true;
    if(this.searchForm.invalid) {
      return;
    }

    this.requestData = {
      supplierNumber : this.searchForm.get("supplierNumber").value
    };

    this.supplierSingleViewService.getSupplierDetailsByNumber(JSON.stringify(this.requestData)).subscribe((data:{}) => {

      this.responseData = data;

      if(this.responseData.status==1){

        if(this.responseData.details !=0){

          this.displaySupplierDetails = true;

          this.supplierId = this.responseData.details[0].supplierId;

          this.name = this.responseData.details[0].supplierName;
          this.city = this.responseData.details[0].supplierCityName;
          this.supplierCityId = this.responseData.details[0].supplierCityId;
          this.supplierType = this.responseData.details[0].supplierType;

          if(this.responseData.details[0].supplierType == 1){
            this.type = "DCO";
          }
          else if(this.responseData.details[0].supplierType == 2){
            this.type = "Broker";
          }
          else if(this.responseData.details[0].supplierType == 3){
            this.type = "Both";
          }

          if(this.responseData.details[0].supplierStatus == 0){
            this.status = "Disable";
          }
          else if(this.responseData.details[0].supplierStatus == 1){
            this.status = "Active";
          }
          else if(this.responseData.details[0].supplierStatus == 2){
            this.status = "Suspend";
          }
          else if(this.responseData.details[0].supplierStatus == 3){
            this.status = "In Approval";
          }

        }
        
      }

    },error =>{
      console.log("Error: "+error);
    });

  }

  resetSearch(){
    this.displaySupplierDetails = false;
    this.readonlyInput = false;
    this.searched = false;
    this.searchForm.reset();
  }

  setSupplierDetails(){


    if(this.supplieDetails.supplierNumber != null){

      this.readonlyInput = true;
      this.searchForm.controls.supplierNumber.setValue(this.supplieDetails.supplierNumber);
    }

    if(this.supplieDetails.supplierName !=null || this.supplieDetails.supplierCity !=null || this.supplieDetails.supplierType !=null || this.supplieDetails.supplierStatus !=null )
    {  
      this.displaySupplierDetails = true;

      this.supplierId = this.supplieDetails.supplierId;
      this.name = this.supplieDetails.supplierName;
      this.city = this.supplieDetails.supplierCity
      this.type = this.supplieDetails.supplierType;
      this.status = this.supplieDetails.supplierStatus;
      this.supplierCityId = "";
      this.supplierType = "";
   
    }
  }

  updateSupplyUserProfileForm = this.fb.group({
    vendorId : [''],
    userName : ['', Validators.required],
    cityId : ['', Validators.required],
    userType : ['', Validators.required]
  });

  get fEdit(){
    return this.updateSupplyUserProfileForm.controls;
  }

  cityMaster : any = [];
  fetch_city_master_staging(){
    return this.restApi.fetch_city_master_staging().subscribe(data => {
    this.cityMaster = data.list;
    //console.log(this.cityMaster);

    const fromCityName = localStorage.getItem('fromCityName');
    const toCityName = localStorage.getItem('toCityName');

    // for(let i=0; i < this.cityMaster.length; i++){

    //   if(fromCityName == this.cityMaster[i].city_name){

    //     this.registrationForm.controls.fromCityName.setValue(this.cityMaster[i].city_id);
    //   }
      
    //   if (toCityName == this.cityMaster[i].city_name){
        
    //     this.registrationForm.controls.toCityName.setValue(this.cityMaster[i].city_id);
    //   }
    // }

    
  }, error => console.log(error));
  }

  setSupplyUserProfile(){
    this.updateSupplyUserProfileForm.controls.vendorId.setValue(this.supplierId);
    this.updateSupplyUserProfileForm.controls.userName.setValue(this.name);
    this.updateSupplyUserProfileForm.controls.cityId.setValue(this.supplierCityId);
    this.updateSupplyUserProfileForm.controls.userType.setValue(this.supplierType);
  }

  onChangeFromCityName(e){
    this.updateSupplyUserProfileForm.controls.cityId.setValue(e);
  }

  submittedUpdate;
  supplyUserProfileRes : any = [];
  add_supply_user_profile_staging_Fun(){

    this.submittedUpdate = true;

    if(this.updateSupplyUserProfileForm.invalid){
        return true;
    }

    this.requestData = {
      vendorId: this.updateSupplyUserProfileForm.controls.vendorId.value,
      userName : this.updateSupplyUserProfileForm.controls.userName.value,
      cityId : this.updateSupplyUserProfileForm.controls.cityId.value,
      userType : this.updateSupplyUserProfileForm.controls.userType.value
    }
    console.log(this.requestData);

    this.tfs.add_supply_user_profile_staging(JSON.stringify(this.requestData)).subscribe((data : {}) => {
      
      this.supplyUserProfileRes = data;
      console.log(JSON.stringify(this.supplyUserProfileRes));

      if(this.supplyUserProfileRes.status == 1){
        this.closeUpdateSupplyUserProfileModal.nativeElement.click();
        window.location.reload();
      }

    }, error => console.log(error));

  }

  ssvData : any;
  viewSpecificDetails(data){

    if(data == 'walletHistory'){
      this.viewWalletHistory = true;
      this.viewBankAccount = false;
      this.viewTrucks = false;
      this.viewDrivers = false;
      this.displayWalletRecharge = false;
      this.supplierCityManagement = false;
    }
    else if(data == 'bankAccount'){
      this.viewBankAccount = true;
      this.viewWalletHistory = false;
      this.viewTrucks = false;
      this.viewDrivers = false;
      this.displayWalletRecharge = false;
      this.supplierCityManagement = false;
    }
    else if(data == 'trucks'){
      this.viewTrucks = true;
      this.viewWalletHistory = false;
      this.viewBankAccount = false;
      this.viewDrivers = false;
      this.displayWalletRecharge = false;
      this.supplierCityManagement = false;
    }
    else if(data == 'drivers'){
      this.viewDrivers = true;
      this.viewWalletHistory = false;
      this.viewBankAccount = false;
      this.viewTrucks = false;
      this.displayWalletRecharge = false;
      this.supplierCityManagement = false;
    }
    else if(data == 'walletRecharge'){
      this.displayWalletRecharge = true;
      this.viewWalletHistory = false;
      this.viewBankAccount = false;
      this.viewTrucks = false;
      this.viewDrivers = false;
      this.supplierCityManagement = false;
    }
    else if(data == 'supplierLinkCity'){
      this.supplierCityManagement = true;
      this.displayWalletRecharge = false;
      this.viewWalletHistory = false;
      this.viewBankAccount = false;
      this.viewTrucks = false;
      this.viewDrivers = false;
    }
    
  }


}
