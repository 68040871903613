import { Component, OnInit , ViewChild, Input, Output, EventEmitter} from '@angular/core';
import { FormGroup , FormBuilder , Validators } from '@angular/forms';
import { DatePipe,formatDate } from '@angular/common';
import { TruckieApiService } from 'app/service/truckie-api.service';
import { RestApiService } from "../../rest-service/rest-api.service";
import { APiProperties } from '../../class/api-properties';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-order-placement-report',
  templateUrl: './order-placement-report.component.html',
  styleUrls: ['./order-placement-report.component.css']
})
export class OrderPlacementReportComponent implements OnInit {

  apiProperties : APiProperties = new APiProperties();

  constructor(private fb: FormBuilder, 
              private datePipe: DatePipe,
              private tas: TruckieApiService,
              public restApi: RestApiService, 
              private toastr: ToastrService) { }

  requestData : any = {};

  public settings = {};
  branchBlk;
  todayDate : any;
  ngOnInit(): void {
    
    this.todayDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    this.settings = {
      singleSelection: false,
      idField: 'branch_id',
      textField: 'branch_name',
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      limitSelection: -1,
      clearSearchFilter: true,
      maxHeight: 197,
      itemsShowLimit: 3,
      searchPlaceholderText: 'Search Branch',
      noDataAvailablePlaceholderText: 'No data Vailable',
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false,
    };

    if(localStorage.getItem('userRole') == "BranchUser"){
      this.branchIdData.push(parseInt(localStorage.getItem('branchLoginId')));
      this.partialListVal = this.branchIdData; 
      this.branchBlk = false;

      console.log(this.partialListVal);

    } else {
      this.branchBlk = true;
      this.fetch_master_branch_staging();
    }

  }

  masterLoadReportForm = this.fb.group({
    fromDate: ['', [Validators.required]],
    toDate: ['', [Validators.required]],
    //branchList: [''],
  });

  get f(){
    return this.masterLoadReportForm.controls;
  }

  toDateBlk;
  fromDateVal : any;
  toDateShow(e){
    //alert(e.target.value);
    this.toDateBlk = true;
    this.fromDateVal = e.target.value;
  }

  masterBranch: any = [];
  masterBranchLength : any;
  masterBranchRes : any = [];
  fetch_master_branch_staging(){

    if(localStorage.getItem('userRole') == "SUB_ADMIN"){
      this.requestData = {
        adminId : localStorage.getItem('adminIdLocal')
      }
    } else {
      this.requestData = {
        adminId : ""
      }
    }
    console.log(this.requestData);

    this.restApi.fetch_master_branch_staging(JSON.stringify(this.requestData)).subscribe(data => {
      
      //this.masterBranch = data.list;
      this.masterBranchRes = data;
      this.masterBranch = this.masterBranchRes.list;
      const branchName = localStorage.getItem('branchName');
      this.masterBranchLength = this.masterBranch.length;

      for(let i=0; i < this.masterBranch.length; i++){
        if(branchName == this.masterBranch[i].branch_name){
          //this.registrationForm.controls.branchName.setValue(this.masterBranch[i].branch_id);
        }
      }
      
      //console.log(this.masterBranch);
    }, error => console.log(error));
  }

  
  // fetch_master_branch_staging(){
  //   return this.restApi.fetch_master_branch_staging().subscribe(data => {
  //     this.masterBranch = data.list;
  //     const branchName = localStorage.getItem('branchName');
      
  //     console.log(this.masterBranch.length);
  //     this.masterBranchLength = this.masterBranch.length;
  //     console.log(this.masterBranchLength);

  //     for(let i=0; i < this.masterBranch.length; i++){

  //       if(branchName == this.masterBranch[i].branch_name){
          
  //         //this.masterLoadReportForm.controls.branchName.setValue(this.masterBranch[i].branch_id);
  //       }
  //     }
      
  //     //console.log(this.masterBranch);
  //   }, error => console.log(error));
  // }

  branchIdData = [];
  public onItemSelect(item: any) {
    //console.log(item);
    this.branchIdData.push(item.branch_id);
    //console.log(this.branchIdData);
  }
  
  public onDeSelect(item: any) {
    //console.log(item);
    for(var i=0; i < this.branchIdData.length;i++){
      if(this.branchIdData[i] == item.branch_id){
        this.branchIdData.splice(i,1);
      }
    }
    //console.log(this.branchIdData);
  }

  public onSelectAll(items: any) {
    //console.log(items);
    //console.log(items.length);
    this.branchIdData = [];
    for(var mp in items){
      this.branchIdData.push(items[mp].branch_id);
    }
    //console.log(this.branchIdData);
  }

  public onDeSelectAll(items: any) {
    console.log(items);
    this.branchIdData = [];
  }

  masterLoadReportRes : any 
  submitted = false;
  isViewable = true;
  branchListErr = false;
  partialListVal : any
  
  reportPathAvailable = false;

  fetchMasterLoadReport() {

    this.submitted = true;

    if(this.masterLoadReportForm.invalid) {
      return;
    }

    if(localStorage.getItem('userRole') != "BranchUser"){ 

        // if(this.branchIdData.length == 0 ){
        //   this.branchListErr = true;
        //   this.isViewable = true;
        //   return;

        // } else {

          if(this.masterBranchLength == this.branchIdData.length){
          
            var isPartialVal = 0;
            //this.partialListVal = '';
            this.partialListVal = this.branchIdData;

          } else {
            var isPartialVal = 1;
            this.partialListVal = this.branchIdData;
          }
          this.branchListErr = false;
          this.isViewable = false;

          console.log(this.partialListVal);
        }

    //}

    this.requestData = {
      fromDate : this.masterLoadReportForm.controls.fromDate.value,
      toDate : this.masterLoadReportForm.controls.toDate.value,
      branchList : this.partialListVal
    }
    console.log(this.requestData);

    return this.tas.getMasterLoadReport(JSON.stringify(this.requestData)).subscribe(data => {
      
      this.masterLoadReportRes = data;
      console.log(this.masterLoadReportRes);
      //this.clSupplierListRes = this.clSupplierRes.list;
      
      if(this.masterLoadReportRes.status == '1'){
        
        this.reportPathAvailable = true;
        this.isViewable = false;

        // var URL = this.masterLoadReportRes.reportPath;
        // console.log(URL);
		    // window.open(URL);

      } else {
        
        this.reportPathAvailable = false;
        this.isViewable = true;

        this.showNotification('top','right',this.masterLoadReportRes.message, '3');
      }

    },error => console.log(error));

  }

  downloadReport(){

    var URL = this.masterLoadReportRes.reportPath;
        console.log(URL);
		    window.open(URL);

  }

  masterLoadReportFormReset(){
    //this.submitted = false;
    //this.masterLoadReportForm.reset();
    window.location.reload();
  }

  showNotification(from, align, messageDis, errorNo) {
    
    //const color = Math.floor(Math.random() * 5 + 1);
    const color = errorNo;
    //console.log(errorNo);

    switch (color) {
      
        case '1':
        this.toastr.success(
          '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">'+messageDis+'</span>',
          "",
          {
            timeOut: 500,
            // closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-success alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;
      
        case '2':
        this.toastr.warning(
        '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">'+messageDis+'</span>',
          "",
          {
            timeOut: 4000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-danger alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;
      
        case '3':
        this.toastr.error(
        '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">'+messageDis+'</span>',
          "",
          {
            timeOut: 4000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-danger alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;

        default:
        break;
    }

  }



}