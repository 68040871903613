import { Component, OnInit , ViewChild, Input, Output, EventEmitter} from '@angular/core';
import { FormGroup , FormBuilder , Validators } from '@angular/forms';
import { DatePipe,formatDate } from '@angular/common';
import { ToastrService } from "ngx-toastr";
import { Router, ActivatedRoute } from '@angular/router';
import { APiProperties } from 'app/class/api-properties';
import { TruckieApiService } from 'app/service/truckie-api.service';
import { LoginService } from 'app/service/login.service';
import { BranchService } from 'app/service/branch.service';
import { RestApiService } from "app/rest-service/rest-api.service";
import { ROUTES } from 'app/sidebar/sidebar.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  apiProperties : APiProperties = new APiProperties();
  submitted = false;
  loading = false;
  returnUrl: string;
  message;
  alertMessage;

  requestData : {};
  req : any = [];

  adminUserblk;
  branchUserblk;
  Branch : any = [];
  BranchUserLoginRes : any = [];

  constructor(private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private toastr: ToastrService,
              private tas : TruckieApiService,
              private loginService : LoginService,
              private branchService : BranchService,
              public restApi: RestApiService) { }

  responseData : any = [];

  ngOnInit(){
      //console.log(localStorage.getItem('userRole'));
      this.getBranch();
      this.getUserAccessURLList();
      this.returnUrl = '/load';
  }

  getUserAccessURLList(){

    this.requestData = {
        clientId : this.apiProperties.clientId,
        clientSecret : this.apiProperties.clientSecret,
        displayLength : 100,
        displayStartFrom : 0,
        roleId : localStorage.getItem('roleIdLocal')
    };

    this.tas.getUserAccessURLList(JSON.stringify(this.requestData)).subscribe((data :{}) =>{
        this.responseData = data;

        if(this.responseData.status == 1 && this.responseData.roleList.length !=0)
            this.setSideBar();
        
    },error => console.error("getUserAccessURLList Error : ",error));
  }
  public menuItems: any[];
  setSideBar(){
    if(localStorage.getItem('userRole') != "ADMIN" && localStorage.getItem('userRole') != "SUB_ADMIN" && 
       localStorage.getItem('userRole') != "BranchUser"){
        
        this.menuItems = [];
        for(const data of this.responseData.roleList){
            ROUTES.forEach(item =>{

                if(item.path == data.accessLink)
                    this.menuItems.push(item);
                
            });
        }

        if(this.menuItems.length !=0)
          this.returnUrl = this.menuItems[0].path;
    }
}

  getBranch(){

    if(localStorage.getItem('userRole') == "SUB_ADMIN"){
      this.requestData = {
        adminId : localStorage.getItem('adminIdLocal')
      }
    } else {
      this.requestData = {
        adminId : ""
      }
    }
    console.log(this.requestData);

    this.restApi.fetch_master_branch_staging(JSON.stringify(this.requestData)).subscribe(data => {
      this.Branch = data;
      //console.log(this.Branch);

    }, error => console.log(error));
  }

  // getBranch(){
  //   return this.branchService.getBranch().subscribe((data : {}) => {
  //     this.Branch = data;
  //   }, error => console.log(error));
  // }
   
  branchform = this.formBuilder.group({
    branch : ['', Validators.required],
    branchUname: ['', Validators.required],
    branchPassword: ['', Validators.required]
  });

  get p() { return this.branchform.controls; }

  branchLogin(){

    this.submitted = true;

    if(this.branchform.invalid) {
      return;
    }

    this.requestData = {
      branchId : this.branchform.get("branch").value,
      userName : this.branchform.get("branchUname").value,
      userPassword : this.branchform.get("branchPassword").value,
    };
    //console.log(this.requestData);

    this.loginService.branchUserLogin(JSON.stringify(this.requestData)).subscribe((data : {})=>{

      this.BranchUserLoginRes = data;

      console.log(this.BranchUserLoginRes);

      if(this.BranchUserLoginRes.status == 1){ 
        localStorage.setItem('branchLoginId', this.branchform.get("branch").value);
        localStorage.setItem('isLoggedIn', "true");
        localStorage.setItem('userRole', "BranchUser");
        this.router.navigate([this.returnUrl]);
        
      } else {

        this.message = "Invalid username and password!";
        this.alertMessage = true;
        this.showMessage();

        this.submitted = false;
        this.branchform.reset();
    }
    },error =>{
      console.log("Error: "+error);
    });

  }

  loginForm = this.formBuilder.group({
    username: ['', Validators.required],
    password: ['', Validators.required]
  });
  get f() { return this.loginForm.controls; }

  loginAdminRes : any = [];
  onSubmit() {
    this.submitted = true;
    //this.alertService.clear();
    if(this.loginForm.invalid) {
        return;
    }

    this.requestData = {
      clientId : this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret,
      username : this.loginForm.get("username").value,
      password : this.loginForm.get("password").value,
    };
    console.log(this.requestData);

    localStorage.setItem('userPasswordLocal', this.loginForm.get("password").value);

    return this.tas.loginAdmin(JSON.stringify(this.requestData)).subscribe(data => {
      
      this.loginAdminRes = data;
      console.log(this.loginAdminRes);
      
      if(this.loginAdminRes.status == '1'){
        //this.showNotification('top','right',this.loginAdminRes.message, '1');

        localStorage.setItem('branchLoginId', "0");
        localStorage.setItem('isLoggedIn', "true");
        localStorage.setItem('roleIdLocal', this.loginAdminRes.roleId);
        localStorage.setItem('userRole', this.loginAdminRes.roleName);
        localStorage.setItem('userNameLocal', this.loginAdminRes.username);
        localStorage.setItem('adminIdLocal', this.loginAdminRes.adminId);

        this.router.navigate([this.returnUrl]);

      } else {

        //this.showNotification('top','right',this.loginAdminRes.message, '3');

        this.message = this.loginAdminRes.message;
        this.alertMessage = true;
        this.showMessage();
        
        this.submitted = false;
        this.loginForm.reset();
      }

    },error => console.log(error));


    // this.loading = true;
    // if(this.loginForm.get("username").value == 'admin' && this.loginForm.get("password").value == 'admin'){
    //   localStorage.setItem('branchLoginId', "0");
    //   localStorage.setItem('isLoggedIn', "true");
    //   localStorage.setItem('userRole', "adminUser");
    //   this.router.navigate([this.returnUrl]);
    // }
    // else{
    //   this.message = "Invalid username and password!";
    //   this.alertMessage = true;
    //   this.showMessage();

    //   this.submitted = false;
    //   this.loginForm.reset();
    // }
          
  }

  showMessage(){
    setTimeout(() => this.alertMessage=false , 3000);
  }

  adminUserClick(){
    this.adminUserblk = true;
    this.branchUserblk = false;
  }

  branchUserClick(){
    this.branchUserblk = true;
    this.adminUserblk = false;
  }

  showNotification(from, align, messageDis, errorNo) {
    
    //const color = Math.floor(Math.random() * 5 + 1);
    const color = errorNo;
    //console.log(errorNo);

    switch (color) {
      
        case '1':
        this.toastr.success(
          '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">'+messageDis+'</span>',
          "",
          {
            timeOut: 500,
            // closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-success alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;
      
        case '2':
        this.toastr.warning(
        '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">'+messageDis+'</span>',
          "",
          {
            timeOut: 4000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-danger alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;
      
        case '3':
        this.toastr.error(
        '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">'+messageDis+'</span>',
          "",
          {
            timeOut: 4000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-danger alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;

        default:
        break;
    }

  }

}