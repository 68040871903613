import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-branch-management',
  templateUrl: './branch-management.component.html',
  styleUrls: ['./branch-management.component.css']
})
export class BranchManagementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
