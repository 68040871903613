import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-image-upload-crop',
  templateUrl: './image-upload-crop.component.html',
  styleUrls: ['./image-upload-crop.component.css']
})
export class ImageUploadCropComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
