import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { WalletRechargeService } from 'app/service/wallet-recharge.service';

@Component({
  selector: 'app-wallet-recharge',
  templateUrl: './wallet-recharge.component.html',
  styleUrls: ['./wallet-recharge.component.css']
})
export class WalletRechargeComponent implements OnInit {

  @Input() supplierId : any;

  constructor(private formBuilder: FormBuilder, private walletRechareService : WalletRechargeService) { }

  requestData : any = {};
  responseData : any = [];

  submitted;
  isViewable;
  readonlyInput;

  ngOnInit(): void {

    this.submitted = false;
    this.isViewable = true;
    this.readonlyInput = false;

    this.setSupplierId();
  }

  walletRechargeForm = this.formBuilder.group({
    supplierId : ['', Validators.required],
   // eventName : ['', Validators.required],
    action : ['', Validators.required],
    points : ['', Validators.required],
    description : ['', Validators.required],
    notes : ['', Validators.required]
  });

  get formValidation(){
    return this.walletRechargeForm.controls;
  }

   //only number will be add
   keyPressForNumber(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  keyPress(event: any) {
    const pattern = /[0-9a-zA-Z ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  inputValidation(type){

    if(type = 'notes'){

      var data = this.walletRechargeForm.get("notes").value.trim();
      if(data == ''){
        this.walletRechargeForm.controls.notes.setValue('');
        return;
      }

    }

    if(type = 'description'){
      
      var data = this.walletRechargeForm.get("description").value.trim();
      if(data == ''){
        this.walletRechargeForm.controls.description.setValue('');
        return;
      }
    }
    
    
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.walletRechargeForm.invalid) {
        return;
    } 

    this.isViewable = false;

    this.requestData = {
      supplierId : this.walletRechargeForm.get('supplierId').value,
      points : this.walletRechargeForm.get('action').value+this.walletRechargeForm.get('points').value,
      description : this.walletRechargeForm.get("description").value.trim(),
      notesForInternalUser : this.walletRechargeForm.get("notes").value.trim()
    };

    this.walletRechareService.saveWalletTransactionForSupplier(JSON.stringify(this.requestData)).subscribe((data : {})=>{

      this.responseData = data;

      if(this.responseData.status == 1){

        this.isViewable = true;
        alert("Wallet Update Successfully!");

        this.onReset();
      }
      else{

        this.isViewable = true;
        alert(this.responseData.message);
      }

    },error =>{
      this.isViewable = true;
      this.onReset();
      console.log("Error : "+error);
    });

  }

  onReset() {
    this.submitted = false;

    this.walletRechargeForm.reset();
    this.walletRechargeForm.controls.action.setValue('');
   this.setSupplierId();
  }
  
  setSupplierId(){

    if(this.supplierId !=null){

      this.readonlyInput = true;
      this.walletRechargeForm.controls.supplierId.setValue(this.supplierId);
    }
  }
}
