import { Component, OnInit , ViewChild, Input, Output, EventEmitter} from '@angular/core';
import { FormGroup , FormBuilder , Validators } from '@angular/forms';
import { APiProperties } from '../../class/api-properties';
import { BranchService } from 'app/service/branch.service';
import { SupplierListService } from 'app/service/supplier-list.service';
import { TruckModelService } from "../../service/truck-model.service";
import { TruckService } from '../../service/truck.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import * as S3 from 'aws-sdk/clients/s3';
import { SafeUrl, DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-truck',
  templateUrl: './truck.component.html',
  styleUrls: ['./truck.component.css']
})
export class TruckComponent implements OnInit {
  @Input() ssvData;
  @Output() hideTruckAdd = new EventEmitter();

  constructor(private fb: FormBuilder, 
              private branchService : BranchService, 
              private supplierListService : SupplierListService, 
              private tms : TruckModelService, 
              private sanitizer: DomSanitizer, 
              private ts: TruckService) { }

  truckSaveForm : FormGroup;

  City : any=[];
  requestDataLSC : any={};
  supplierListRes : any=[];
  TruckModelRes : any=[];
  image: any;

  saveVehicleRes : any=[];

  submitted = false;
  imagePass: any;

  afterRCImg = false;
  
  rcFrontCls: any;
  rcBackCls: any;

  saveVehicleRequestData : {};
  ssvDataVal;
  truckBlk;

  mPancardAddBlk;
  mInsuranceAddBlk;
  mBankAddBlk;

  ngOnInit(): void {

    //alert("hello");
    console.log(sessionStorage.getItem("truckIdVal"));

    if(sessionStorage.getItem("truckIdVal")){
      this.truckBlk = false;
      this.truckIdAvailable = true;
    } else {
      this.truckBlk = true;
      this.truckIdAvailable = false;
    }

    if(sessionStorage.getItem("panCardId")){
      this.mPancardAddBlk = false;     
    } else {
      this.mPancardAddBlk = true;
    } 

    if(sessionStorage.getItem("insuranceId")){
      this.mInsuranceAddBlk = false;     
    } else {
      this.mInsuranceAddBlk = true;
    }

    if(sessionStorage.getItem("tBankId")){
      this.mBankAddBlk = false;     
    } else {
      this.mBankAddBlk = true;
    }

    if(sessionStorage.getItem("panCardId") && sessionStorage.getItem("insuranceId") && sessionStorage.getItem("tBankId")){
        //alert("here");
        this.truckIdAvailable = false;
        this.truckBlk = true;
        this.clearSessionData();
    }
    //console.log("supplier Id ::"+this.supplierId);
    //this.truckBlk = true;
    //console.log(this.ssvData)

    if(this.ssvData == "1"){
      this.ssvDataVal = true;
    }

    this.truckSaveForm = this.fb.group({
      SupplierCity : ['' , Validators.required], 
      supplierId : ['' , Validators.required],
      truckType : ['' , Validators.required],
      truckOwnerName : ['' , Validators.required],
      truckOwnerMobileNumber : ['' , Validators.required],
      truckRegistrationNumberStateCode : ['' , Validators.required , Validators.maxLength(2)],
      truckRegistrationNumberCityCode : ['' , Validators.required ,  Validators.maxLength(2)],
      truckRegistrationNumberNumber : ['' , Validators.required ,  Validators.maxLength(2)],
      truckRegistrationNumberSeries : ['' , Validators.required ,  Validators.maxLength(4)],
      RCBookPictureFrontURL : ['' , Validators.required],
      RCBookPictureBackURL : ['' , Validators.required]
    });

    this.submitted = false;

    this.getCity();
    this.getTruckModelList();
  }

  getCity(){
    return this.branchService.getCity().subscribe((data : {}) => {
        this.City = data;
        //console.log(this.City);
    }, error => console.log(error));
  }

  onChangeGetValue(event){

    const value = event.target.value;
    
    this.requestDataLSC = {
      supplierCityId : value
    };
    
    this.supplierListService.getListOfSupplierPerCity(JSON.stringify(this.requestDataLSC)).subscribe((data:{})=>{
        this.supplierListRes = data;
    },error => {
      console.log("Error: "+error);
    });

  }

  getTruckModelList(){
    return this.tms.getTruckModel().subscribe((data : {}) => {
      this.TruckModelRes = data;
    }, error => console.log(error));
  }

  @ViewChild('closeButton') closeButton;

  interval;
  updateVehicleConfigImageRes : any;

  properties : APiProperties = new APiProperties();

  vmiID;
  uvmiID;

  fileToUpload: any;

  beforeUpload = true;
  afterUpload = false;

  public open(id) {
    this.vmiID = id;

    this.beforeUpload = true;
    this.afterUpload = false;

    this.afterRCImg = false;

  }

  handleFileInput(files: FileList) {
    //alert(files);
    this.fileToUpload = files.item(0);

    this.afterRCImg = true;
  }
  
  updateImage(ev) {
    this.image = this.sanitizer.bypassSecurityTrustUrl(
      window.URL.createObjectURL(ev.target.files[0])
    );

    this.afterRCImg = true;
  }

  uploadCourseImage(vmiID){
      
      this.uvmiID = vmiID;
      this.uploadCourseImageToAws(this.fileToUpload, this.uvmiID);

      //alert(localStorage.getItem('image'));
      
      this.interval = setInterval(() => {
          
        if(localStorage.getItem('image') != null){

          //alert(localStorage.getItem('image'));

          var imagePass = localStorage.getItem('image');

          this.closeButton.nativeElement.click();

          this.afterRCImg = true;

          if(vmiID == "rcFront"){
          this.truckSaveForm.patchValue({RCBookPictureFrontURL:imagePass});
          this.rcFrontCls = 1;
          }
          
          if(vmiID == "rcBack"){
            this.truckSaveForm.patchValue({RCBookPictureBackURL:imagePass});
            this.rcBackCls = 1;
          }

          clearInterval(this.interval); 
          localStorage.removeItem('image');

          window.localStorage.clear();
          
        }
    
      }, 1000);

      
      this.beforeUpload = false;
      this.afterUpload = true;
  }

  uploadCourseImageToAws(file,rcTextInt){

    //alert(rcTextInt);

    const bucket = new S3(
        {
            accessKeyId: this.properties.s3AccessKeyId,
            secretAccessKey: this.properties.s3SecretAccessKey,
            region: this.properties.s3Region
        }
    );

    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz';
    const stringLength = 10;
    let randomstring = '';
    for (let i = 0; i < stringLength; i++) {
    const rnum = Math.floor(Math.random() * chars.length);
    randomstring += chars.substring(rnum, rnum + 1);
    }
    const codeGenerated = randomstring;


      const params = {
        Bucket: this.properties.s3Bucket,
        Key: 'truck/' + codeGenerated ,
        Body: file,
        ACL: 'public-read',
        ContentType: 'image/jpeg',
        ServerSideEncryption: 'AES256'
    };

  //   const params = {
  //     Bucket: this.properties.s3Bucket,
  //     Key: this.properties.s3BucketPath + codeGenerated ,
  //     Body: buf,
  //     ACL: 'public-read',
  //     ContentType: 'image/jpeg',
  //     ServerSideEncryption: 'AES256'
  // };

     bucket.upload(params, function (err, data) {
      if (err) {
          console.log('There was an error uploading your file: ', err);
          return false;
      } else {
          
      console.log('Successfully uploaded file.', data);
      localStorage.setItem('image',data.Location);

      return true;

      }
      
    });

  
  }

  get f() { return this.truckSaveForm.controls; }
  
  keyPressForNumber(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  keyPressForNameWithoutSpace(event: any){
    var filter = /[a-zA-Z]/;
    var ch = String.fromCharCode(event.keyCode);
    if(!filter.test(ch)){
         event.returnValue = false;
    }
  }

  truckIdAvailable;

  truckSave(){
      this.submitted = true; 

      if(this.truckSaveForm.invalid){
        return;
      }

      this.saveVehicleRequestData = {
        SupplierCity : this.truckSaveForm.get("SupplierCity").value,
        supplierId : this.truckSaveForm.get("supplierId").value,
        truckType : this.truckSaveForm.get("truckType").value,
        truckOwnerName : this.truckSaveForm.get("truckOwnerName").value,
        truckOwnerMobileNumber : this.truckSaveForm.get("truckOwnerMobileNumber").value,
        truckRegistrationNumberStateCode : this.truckSaveForm.get("truckRegistrationNumberStateCode").value,
        truckRegistrationNumberCityCode : this.truckSaveForm.get("truckRegistrationNumberCityCode").value,
        truckRegistrationNumberNumber : this.truckSaveForm.get("truckRegistrationNumberNumber").value,
        truckRegistrationNumberSeries : this.truckSaveForm.get("truckRegistrationNumberSeries").value,
        RCBookPictureFrontURL : this.truckSaveForm.get("RCBookPictureFrontURL").value,
        RCBookPictureBackURL : this.truckSaveForm.get("RCBookPictureBackURL").value,
      }

      console.log(this.saveVehicleRequestData);

      this.ts.saveTruck(JSON.stringify(this.saveVehicleRequestData)).subscribe((data : {}) => {

        this.saveVehicleRes = data;

        //alert(this.saveVehicleRes);

        if(this.saveVehicleRes.status ==1 ){

          sessionStorage.setItem("truckIdVal",this.saveVehicleRes.truckId)
          
          alert("Truck Added Successfully!");
          this.rcFrontCls = 0;
          this.rcBackCls = 0;

          this.truckIdAvailable = true;

          this.ngOnInit();
          this.back();
        }
        else{
          
          alert(this.saveVehicleRes.message);
        }


      }, error => console.log(error));
  }
  
  supplierIdVal : any;
  truckIdVal : any;
  documentFor : any;
  supplierId : any;
  truckId : any;
  paramPass : any;
  mrcBookPhoto;
  mrcBackPhoto;
  imageUploadBlk;

  supplierChange(event){
    this.supplierIdVal = event.target.value;

    sessionStorage.setItem("supplierIdVal",this.supplierIdVal);
  }

  truckChange(event){
    this.truckIdVal = event.target.value;

    if(this.truckIdVal != ""){
      this.mrcBookPhoto = true;
      this.mrcBackPhoto = true;
    } 
  }

  imageUploadShow(paramPassVal) {
    this.documentFor = "truck";
    this.supplierId = this.supplierIdVal;
    this.truckId = this.truckIdVal;
    this.paramPass = paramPassVal;
    console.log(this.supplierId + " ~~~~ " + this.truckId);

    //this.truckBlk = false;
    this.imageUploadBlk = true;
  }

  upImageUrl : any;
  paramPassFinal : any;

  hideImageupload(data){
    console.log(data);
    
    this.upImageUrl = data.finalImageUp;
    console.log(this.upImageUrl);

    if(data != null){
      
      console.log(data);
      
      this.upImageUrl = data.finalImageUp;
      this.paramPassFinal = data.paramPassFinal;
      
      if(this.paramPassFinal == "RCBookPictureFrontURL"){
        this.truckSaveForm.patchValue({
          'RCBookPictureFrontURL' : this.upImageUrl,
        });
        this.rcFrontCls = 1;
      }
      
      if(this.paramPassFinal == "RCBookPictureBackURL"){
        this.truckSaveForm.patchValue({
          'RCBookPictureBackURL' : this.upImageUrl,
        });
        this.rcBackCls = 1;
      }

  } else {

  }
  
  this.imageUploadBlk = false;
  //this.truckBlk = true;
  //this.mrcBookPhoto = true;
    
  }

  truckPancardAddBlk;
  truckInsuranceAddBlk;
  truckBankAddBlk;

  showTruckPancardAdd(){
    //this.ssvDataVal = false;
    //this.imageUploadBlk = false;
    this.supplierId = sessionStorage.getItem("supplierIdVal");
    this.truckId = sessionStorage.getItem("truckIdVal");
    this.truckBlk = false;
    this.truckIdAvailable = false;
    this.truckPancardAddBlk = true;
    console.log(this.supplierId +"-M-"+this.truckId);
  }

  hideTruckPancardAdd(data){
    if(data != null){
      sessionStorage.setItem("panCardId",data.panCardId);
      //this.ssvDataVal = true;
      //this.imageUploadBlk = true;
      this.truckBlk = false;
      this.truckIdAvailable = true;
      this.mPancardAddBlk = false;
      this.truckPancardAddBlk = false;
    } else {
      this.truckBlk = false;
      this.truckIdAvailable = true;
      this.mPancardAddBlk = true;
      this.truckPancardAddBlk = false;
    }
    this.ngOnInit();
  }
  
  showTruckInsuranceAdd(){
    //this.ssvDataVal = false;
    //this.imageUploadBlk = false;
    this.supplierId = sessionStorage.getItem("supplierIdVal");
    this.truckId = sessionStorage.getItem("truckIdVal");
    this.truckBlk = false;
    this.truckIdAvailable = false;
    this.truckInsuranceAddBlk = true;
  }

  hideTruckInsuranceAdd(data){
    if(data != null){
      sessionStorage.setItem("insuranceId",data.insuranceId);
      //this.ssvDataVal = true;
      //this.imageUploadBlk = true;
      this.truckBlk = false;
      this.truckIdAvailable = true;
      this.mInsuranceAddBlk = false;
      this.truckInsuranceAddBlk = false;
    } else {
      this.truckBlk = false;
      this.truckIdAvailable = true;
      this.mInsuranceAddBlk = true;
      this.truckInsuranceAddBlk = false;
    }
    this.ngOnInit();
  }

  showTruckBankAdd(){
    //this.ssvDataVal = false;
    //this.imageUploadBlk = false;
    this.supplierId = sessionStorage.getItem("supplierIdVal");
    this.truckId = sessionStorage.getItem("truckIdVal");
    this.truckBlk = false;
    this.truckIdAvailable = false;
    this.truckBankAddBlk = true;
  }

  hideTruckBankAdd(data){
    if(data != null){
      sessionStorage.setItem("tBankId",data.tBankId);
      //this.ssvDataVal = true;
      //this.imageUploadBlk = true;
      this.truckBlk = false;
      this.truckIdAvailable = true;
      this.mBankAddBlk = false;
      this.truckBankAddBlk = false;
    } else {
      this.truckBlk = false;
      this.truckIdAvailable = true;
      this.mBankAddBlk = true;
      this.truckBankAddBlk = false;
    }
    this.ngOnInit();
  }

  clearSessionData(){
    sessionStorage.setItem("supplierIdVal","");
    sessionStorage.setItem("truckIdVal","");
    sessionStorage.setItem("panCardId","");
    sessionStorage.setItem("insuranceId","");
    sessionStorage.setItem("tBankId","");
  }

  onReset(){
    this.truckSaveForm.reset();
    this.rcFrontCls = 0;
    this.rcBackCls = 0;
  }

  back(){
    this.hideTruckAdd.emit();
  }

}