import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable,throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { APiProperties } from 'app/class/api-properties';

@Injectable({
  providedIn: 'root'
})
export class BankDetailsOfSupplierService {

  constructor(private http:HttpClient) { }

  apiProperties : APiProperties = new APiProperties();

  getSupplierBankDetailsHttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  getSupplierBankDetails(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'fetch_supplier_bank_details', data ,this.getSupplierBankDetailsHttpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  handleError(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
 }

}
