import { Component, OnInit, Input } from '@angular/core';
import { FormGroup,FormControl } from '@angular/forms';
import { ViewBidsSupplierPerLoadService } from "../../service/view-bids-supplier-per-load.service";
import { Tabledata, DisplayLoadData } from './tabledata';

@Component({
  selector: 'app-view-bids-supplier-per-load',
  templateUrl: './view-bids-supplier-per-load.component.html',
  styleUrls: ['./view-bids-supplier-per-load.component.css']
})
export class ViewBidsSupplierPerLoadComponent implements OnInit {

  @Input() loadData : DisplayLoadData;

  constructor( private viewBidsSupplierPerLoadService : ViewBidsSupplierPerLoadService ) { }

  id : any;
  ViewBidsSupplierPerLoad : any=[];
  index=0;
  requestData : {};
  requestCountWeightData : {};
  CountWeight : any=[];
  countWeightRequestData : any=[];
  tableData : Tabledata = new Tabledata();
  TableData =[];
  
  TruckList : any=[];
  truckRequestData : {};

  showModal: boolean;
 

  dataNotFound;
  loading;

  ngOnInit(): void {

    this.dataNotFound = false;
    this.loading = true;
    this.index=0;

  this.getSpecificListOfSupplierPerLoad();

  this.setLoadDetails();
  }

  truckListForm = new FormGroup({
    supplierName: new FormControl(),
    supplierNumber: new FormControl(),
    submittedRate : new FormControl()
  });

  loadDetailForm = new FormGroup({
    loadId: new FormControl(),
    fromCity: new FormControl(),
    toCity : new FormControl(),
    branch : new FormControl()
  });

  getSpecificListOfSupplierPerLoad() {

    this.requestData= {
      loadId : this.loadData.loadId
    };

    return this.viewBidsSupplierPerLoadService.getSpecificListOfSupplierPerLoad(JSON.stringify(this.requestData)).subscribe((data : {}) => {
      
      this.ViewBidsSupplierPerLoad = data;
    
      if(this.ViewBidsSupplierPerLoad.status == 1){
      
        for(let i=0; i< this.ViewBidsSupplierPerLoad.list.length; i++){

          this.requestCountWeightData = {
            loadId : this.ViewBidsSupplierPerLoad.list[i].loadId,
            supplyId : this.ViewBidsSupplierPerLoad.list[i].supplyId 
          };
        this.countWeightRequestData.push(JSON.stringify(this.requestCountWeightData));  

        }

        if(this.ViewBidsSupplierPerLoad.list.length ==0){
          this.dataNotFound=true;
          this.loading = false;
        }
        else{

          this.viewBidsSupplierPerLoadService.getCountWeigthSpecificListOfSupplierPerLoad(this.countWeightRequestData).subscribe( data => {
            this.CountWeight=data;
    
            for(let i=0; i< this.ViewBidsSupplierPerLoad.list.length; i++){

              this.tableData = new Tabledata();
              this.tableData.loadId= this.ViewBidsSupplierPerLoad.list[i].loadId;
              this.tableData.supplyId= this.ViewBidsSupplierPerLoad.list[i].supplyId;
              this.tableData.supplierName = this.ViewBidsSupplierPerLoad.list[i].supplierName;
              this.tableData.supplierCity = this.ViewBidsSupplierPerLoad.list[i].supplierCity;
              this.tableData.supplierContectNumber = this.ViewBidsSupplierPerLoad.list[i].supplierContectNumber;
              this.tableData.bidRate =  this.ViewBidsSupplierPerLoad.list[i].bidRate;
              this.tableData.count = this.CountWeight[i].count;
              this.tableData.weight = this.CountWeight[i].weight;

              this.TableData.push(this.tableData) ;
            
            }

            this.loading = false;
          
          }, error => console.log(error));

        }
      }
      else{
        this.dataNotFound=true;
        this.loading = false;
      }
     
      
    }, error => console.log(error));
    
  }

  getTruckList(loadId,supplyId,supplierName,supplierNumber,submittedRate){

    this.truckRequestData={
      loadId : loadId ,
      supplyId : supplyId
    };

    return this.viewBidsSupplierPerLoadService.getTruckAvailabilityPerBidPerLoad(JSON.stringify(this.truckRequestData)).subscribe((data : {}) => {
      
      this.TruckList = data;
    
      this.showModal = true;

      
      this.truckListForm.setValue({
        supplierName : supplierName,
        supplierNumber : supplierNumber,
        submittedRate : submittedRate
      });
    
      
    }, error => console.log(error));
  }

show()
{
  this.showModal = true; // Show-Hide Modal Check
  
}

hide()
{
  this.showModal = false;
}

setLoadDetails(){

  this.loadDetailForm.controls.loadId.setValue(this.loadData.loadId);
  this.loadDetailForm.controls.fromCity.setValue(this.loadData.fromCityName);
  this.loadDetailForm.controls.toCity.setValue(this.loadData.toCityName);
  this.loadDetailForm.controls.branch.setValue(this.loadData.branchName);
  
}

}
