export class ViewBidCompletedLoadTableData {

    loadId : any;
    fromCityName : any;
    toCityName : any;
    branchName : any;
    reportingDate : any;
    countOfBidsReceived : any;
    lowestBid : any;
    order_type : any;
}