import { Component, OnInit , ViewChild, Input, Output, EventEmitter, ElementRef} from '@angular/core';
import { FormGroup , FormBuilder , Validators } from '@angular/forms';
import { DatePipe,formatDate } from '@angular/common';
import { ToastrService } from "ngx-toastr";
import { APiProperties } from '../../class/api-properties';
import { TruckieApiService } from 'app/service/truckie-api.service';
import { RestApiService } from "../../rest-service/rest-api.service";

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {
  apiProperties : APiProperties = new APiProperties();
  @ViewChild('closeUpdateadminProfileDetailsModal') closeUpdateadminProfileDetailsModal: ElementRef;
  constructor(private fb: FormBuilder, 
              private datePipe: DatePipe,
              private tas: TruckieApiService,
              public restApi: RestApiService, 
              private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getLoginRoleListFun();
    this.getAdminLoginUserListFun();
  }

  submitted;

  userCreationForm = this.fb.group({
    username : ['',[Validators.required]],
    personalNumber : ['',[Validators.required,Validators.minLength(10),Validators.maxLength(10)]],
    roleId : ['',[Validators.required]],
  });
  get f() { return this.userCreationForm.controls; }

  updateUserCreationForm = this.fb.group({
    personalNumber : ['',[Validators.required,Validators.minLength(10),Validators.maxLength(10)]],
    roleId : ['',[Validators.required]],
    //roleName : [{value: '', disabled: true}],
    roleName : [''],
    username : [''],
    adminId : ['',[Validators.required]],
  });
  get fUpdate() { return this.updateUserCreationForm.controls; }

  keyPressForNumber(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  keyPress(event: any) {
    const pattern = /[0-9a-zA-Z ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  requestData : any = {};
  loginRoleListRes : any = [];
  getLoginRoleListFun(){
    
    this.requestData = {
      clientId : this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret
    };
    //console.log(this.requestData);

    return this.tas.getLoginRoleList(JSON.stringify(this.requestData)).subscribe(data => {
      
      this.loginRoleListRes = data;
      console.log(this.loginRoleListRes);
      
      if(this.loginRoleListRes.status == '1'){
        

      } else {
        
        this.showNotification('top','right',this.loginRoleListRes.message, '3');
      }

    },error => console.log(error));

  }

  adminUserRes : any = [];
  addAdminUserFun(){
    this.submitted = true;
    if(this.userCreationForm.invalid){
      return true;
    }
    let usernameAlert = this.userCreationForm.controls.username.value;

    this.requestData = {
      clientId : this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret,
      username : this.userCreationForm.controls.username.value,
      personalNumber : this.userCreationForm.controls.personalNumber.value,
      roleId : this.userCreationForm.controls.roleId.value,
      currentLoginUsername : localStorage.getItem('userNameLocal'),
      currentLoginPassword : localStorage.getItem('userPasswordLocal')
    };
    console.log(this.requestData);

    return this.tas.addAdminUser(JSON.stringify(this.requestData)).subscribe(data => {
      this.adminUserRes = data;
      console.log(this.adminUserRes);
      
      if(this.adminUserRes.status == '1'){
        this.submitted = false;
        this.showNotification('top','right',this.adminUserRes.message, '1');
        alert("New User Created. User name : "+usernameAlert+", Password : "+this.adminUserRes.password);
        this.getAdminLoginUserListFun();
        this.userCreationForm.reset();
      } else {
        this.submitted = false;
        this.showNotification('top','right',this.adminUserRes.message, '3');
        //this.userCreationForm.reset();
      }

    },error => console.log(error));

  }

  clearUserCreationForm(){
     this.userCreationForm.reset();
  } 

  adminLoginUserListRes : any = [];
  noDataAdminUser;
  getAdminLoginUserListFun(){
    
    this.requestData = {
      clientId : this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret,
      displayStartFrom : "0",    
      displayLength : "30"
    };
    console.log(this.requestData);

    return this.tas.getAdminLoginUserList(JSON.stringify(this.requestData)).subscribe(data => {
      
      this.adminLoginUserListRes = data;
      console.log(this.adminLoginUserListRes.adminUserList);

      //console.log(this.adminLoginUserListRes.adminUserList.legth);
      
      if(this.adminLoginUserListRes.status == '1'){
        
        this.noDataAdminUser = false;
      } else {
        this.noDataAdminUser = true;
        this.showNotification('top','right',this.adminLoginUserListRes.message, '3');
      }

    },error => console.log(error));

  }

  resendAdminUserPasswordRes : any = [];
  resendAdminUserPasswordFun(dataVal){
    
    this.requestData = {
      clientId : this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret,
      adminId : dataVal.adminId,
      currentLoginUsername : localStorage.getItem('userNameLocal'),
      currentLoginPassword : localStorage.getItem('userPasswordLocal')
    };
    console.log(this.requestData);

    return this.tas.resendAdminUserPassword(JSON.stringify(this.requestData)).subscribe(data => {
      
      this.resendAdminUserPasswordRes = data;
      console.log(this.resendAdminUserPasswordRes);

      
      if(this.resendAdminUserPasswordRes.status == '1'){
       alert("Password change successfully. User name : "+dataVal.username+", Role Name :"+dataVal.roleName+", Password :"+this.resendAdminUserPasswordRes.password);
       // this.noDataAdminUser = false;
      } else {
        //this.noDataAdminUser = true;
        this.showNotification('top','right',this.resendAdminUserPasswordRes.message, '3');
      }

    },error => console.log(error));

  }

  setadminProfileDetails(data){
    this.updateUserCreationForm.controls.personalNumber.setValue(data.personalNumber);
    this.updateUserCreationForm.controls.roleId.setValue(data.roleId);
    this.updateUserCreationForm.controls.adminId.setValue(data.adminId);
    this.updateUserCreationForm.controls.roleName.setValue(data.roleName);
    this.updateUserCreationForm.controls.username.setValue(data.username);
  }

  changeAdminProfileDetailsRes : any = [];
  submittedEdit;
  changeAdminProfileDetailsFun(){
    
    this.submittedEdit = true;
    if(this.updateUserCreationForm.invalid){
      return true;
    }

    this.requestData = {
      clientId : this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret,
      adminId : this.updateUserCreationForm.controls.adminId.value,
      roleId : this.updateUserCreationForm.controls.roleId.value,
      personalNumber : this.updateUserCreationForm.controls.personalNumber.value,  
      currentLoginUsername : localStorage.getItem('userNameLocal'),
      currentLoginPassword : localStorage.getItem('userPasswordLocal')
    };
    console.log(this.requestData);

    return this.tas.changeAdminProfileDetails(JSON.stringify(this.requestData)).subscribe(data => {
      this.changeAdminProfileDetailsRes = data;
      console.log(this.changeAdminProfileDetailsRes);
      
      if(this.changeAdminProfileDetailsRes.status == '1'){
        //this.noDataAdminUser = false;
        this.closeUpdateadminProfileDetailsModal.nativeElement.click();
        this.showNotification('top','right',this.changeAdminProfileDetailsRes.message, '1');
        this.getAdminLoginUserListFun();
      } else {
        //this.noDataAdminUser = true;
        this.showNotification('top','right',this.changeAdminProfileDetailsRes.message, '3');
      }

    },error => console.log(error));

  }

  updateAdminUserStatusRes : any = [];
  updateAdminUserStatusFun(e,dataVal,enableVal){
    
    this.requestData = {
      clientId : this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret,
      adminId : dataVal.adminId,
      enable : enableVal,
      currentLoginUsername : localStorage.getItem('userNameLocal'),
      currentLoginPassword : localStorage.getItem('userPasswordLocal')
    };
    console.log(this.requestData);

    return this.tas.updateAdminUserStatus(JSON.stringify(this.requestData)).subscribe(data => {
      this.updateAdminUserStatusRes = data;
      console.log(this.updateAdminUserStatusRes);

      if(this.updateAdminUserStatusRes.status == '1'){
        //this.noDataAdminUser = false;
        this.getAdminLoginUserListFun();
      } else {
        //this.noDataAdminUser = true;
        this.showNotification('top','right',this.updateAdminUserStatusRes.message, '3');
      }

    },error => console.log(error));

  }

  showNotification(from, align, messageDis, errorNo) {
    
    //const color = Math.floor(Math.random() * 5 + 1);
    const color = errorNo;
    //console.log(errorNo);

    switch (color) {
      
        case '1':
        this.toastr.success(
          '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">'+messageDis+'</span>',
          "",
          {
            timeOut: 500,
            // closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-success alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;
      
        case '2':
        this.toastr.warning(
        '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">'+messageDis+'</span>',
          "",
          {
            timeOut: 4000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-danger alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;
      
        case '3':
        this.toastr.error(
        '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">'+messageDis+'</span>',
          "",
          {
            timeOut: 4000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-danger alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;

        default:
        break;
    }

  }

}