import { Component, OnInit , ViewChild, Input, Output, EventEmitter} from '@angular/core';
import { FormGroup , FormBuilder , Validators } from '@angular/forms';
import { DatePipe,formatDate } from '@angular/common';
import { ToastrService } from "ngx-toastr";
import { APiProperties } from '../../class/api-properties';
import { TruckieApiService } from 'app/service/truckie-api.service';
import { RestApiService } from "../../rest-service/rest-api.service";
import { BulkUploadService } from 'app/service/bulk-upload.service';

@Component({
  selector: 'app-bulk-bank-upload',
  templateUrl: './bulk-bank-upload.component.html',
  styleUrls: ['./bulk-bank-upload.component.css']
})
export class BulkBankUploadComponent implements OnInit {

  apiProperties : APiProperties = new APiProperties();
  @ViewChild('fileUplaod') fileUplaod: any;

  constructor(private fb: FormBuilder, 
              private datePipe: DatePipe,
              private tas: TruckieApiService,
              private buService : BulkUploadService) { }
  
  file : File = null;
  responseData : any = [];
  ngOnInit(): void {
  }

  requestData : any = {};

  sample(){

    this.requestData = {
      clientId : this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret
    };

    return this.tas.sampleBankUpload(JSON.stringify(this.requestData)).subscribe((data : {}) => {
      this.responseData = data;
        var URL = this.responseData.filePath;
        window.open(URL);
    }, error => console.log(error));
  }

  fileChangeEvent(event){
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
    }
  }

  uploadBulkBank(){
    
    if(this.file == null){
      alert("Please uplaod file!");
      return;
    }

    const formData = new FormData();
    formData.append("file", this.file, this.file.name);

    this.tas.uploadBulkBank(formData).subscribe((data : {})=>{

      this.responseData = data;

      alert(this.responseData.message);
      
      this.fileUplaod.nativeElement.value='';
      this.file = null;

    }, error => console.log("uploadBulkLoad Error: "+error));
  }

}
