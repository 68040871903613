import { Component, OnInit, Input } from '@angular/core';
import { BankDetailsOfSupplierService } from 'app/service/bank-details-of-supplier.service';
import { TruckieFunctionsService } from 'app/service/truckie-functions.service';
import { APiProperties } from 'app/class/api-properties';
import { DatePipe,formatDate } from '@angular/common';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-bank-details-of-supplier',
  templateUrl: './bank-details-of-supplier.component.html',
  styleUrls: ['./bank-details-of-supplier.component.css']
})
export class BankDetailsOfSupplierComponent implements OnInit {
  apiProperties : APiProperties = new APiProperties();
  @Input() supplierId : any;

  constructor(private bankDetailsOfSupplierService : BankDetailsOfSupplierService, 
              private tfs : TruckieFunctionsService, 
              private toastr : ToastrService) { }

  requestData : any = {};
  supplierBankDetails : any = [];

  dataNotFound;
  loading;
  serverError;

  ngOnInit(): void {
    
    this.supplierBankDetailBlk = true;
    
    this.dataNotFound=false;
    this.loading = true;
    this.serverError = false;

    this.getSupplierBankDetails();
  }

  getSupplierBankDetails(){

    this.requestData = {
      "supplierId" : this.supplierId
    };
    console.log(this.requestData);

    this.bankDetailsOfSupplierService.getSupplierBankDetails(JSON.stringify(this.requestData)).subscribe((data : {}) =>{

      this.supplierBankDetails = data;

      if(this.supplierBankDetails.status ==1){
        if(this.supplierBankDetails.list.length ==0){
          this.dataNotFound=true;
          this.loading = false;
        }
        else{
          this.loading = false;
        }
      }
      else{
        this.dataNotFound=true;
        this.loading = false;
      }

    }, error => {
      this.serverError = true;
      console.log("Error : "+error);
    });
  }

  supplierBankDetailBlk;
  supplierBankBlk;
  ssvData : any;
  
  showSupplierBank(){
    this.ssvData = "1";
     this.supplierBankDetailBlk = false;
     this.supplierBankBlk = true;
  }

  hideSupplierBank(){
    this.supplierBankDetailBlk = true;
    this.supplierBankBlk = false;
  }
  
  bu : buLoadData = new buLoadData();

  supplierBankUpdateBlk;
  supplierBankUpdate(bankId,supplier_id,bank_name,ifsc_code,bank_account_name,bank_account_number){
    this.supplierId = this.supplierId;
    //this.driverId = driverIdVal;

    this.bu = new buLoadData ();
    this.bu.bankId = bankId;
    this.bu.supplier_id = supplier_id;
    this.bu.bank_name = bank_name;
    this.bu.ifsc_code = ifsc_code;
    this.bu.bank_account_name = bank_account_name;
    this.bu.bank_account_number = bank_account_number;

    this.supplierBankDetailBlk = false;
    this.supplierBankUpdateBlk = true;
  }

  hideBankUpdate(){
    this.supplierBankDetailBlk = true;
    this.supplierBankUpdateBlk = false;
    this.getSupplierBankDetails();
  }

  bdStatusRes : any = [];
  updateBDStatus(e,supplier_id, bankId, status){

    //if(e.traget.checked){}
    this.requestData = {
      clientId : this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret,
      supplierId : supplier_id,
      supplierBankDetailId : bankId,
      status : status
    };
    console.log(this.requestData);

    return this.tfs.updateBankDetailStatus(JSON.stringify(this.requestData)).subscribe((data : {}) => {
      this.bdStatusRes = data;

      if(this.bdStatusRes.status == 1){
        this.getSupplierBankDetails();
      }

    }, error => console.log(error));

  }

}

export class buLoadData {
  bankId : any;
  supplier_id : any;
  bank_name : any;
  ifsc_code : any;
  bank_account_name : any;
  bank_account_number : any;
}