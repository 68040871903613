import { Component, OnInit, ViewChildren, ViewChild, QueryList, ElementRef } from '@angular/core';
import { DatePipe,formatDate } from '@angular/common';
import { FormBuilder, FormGroup, FormArray, Validators, AbstractControl } from '@angular/forms';
import { RestApiService } from "../../rest-service/rest-api.service";
import { TruckieFunctionsService } from 'app/service/truckie-functions.service';
import { BulkUploadService } from 'app/service/bulk-upload.service';
import { APiProperties } from 'app/class/api-properties';

@Component({
  selector: 'app-club-load',
  templateUrl: './club-load.component.html',
  styleUrls: ['./club-load.component.css']
})
export class ClubLoadComponent implements OnInit {

  @ViewChildren("checkboxes") checkboxes: QueryList<ElementRef>;
  @ViewChild("multiSelect") multiSelect;
  
  apiProperties : APiProperties = new APiProperties();

  constructor(public fb: FormBuilder, 
    public restApi: RestApiService, 
    private datePipe: DatePipe,
    private buService : BulkUploadService,
    private tfs : TruckieFunctionsService) { 

          this.isMasterSel = false;

          this.send_date.setDate(this.send_date.getDate()+27);
          this.formattedDate=this.send_date.toISOString().slice(0,10);
          //console.log(this.formattedDate);
  }

  items: FormArray;

  toArr: FormArray;
  truckModelArr: FormArray;

  TruckLoad: any = [];
  cityMaster: any = [];
  masterBranch: any = [];
  materialMaster: any = [];
  masterMaterialPackaging: any = [];
  singleLoad: any = [];
  data : string;

  milan: number;
  
  send_date=new Date();
  formattedDate : any;
  singleLoadData: any;
  FormResponseData : any =[];

  checkBoxArray: any= [];
  checkBoxValidation;

  isMasterSel:boolean;
  checkedList:any;
  selected;
  truckModel: any;

  submitted = false;
  isViewable;
  todayDate;
  interval;
  message;
  alertMessage;

  requestData : any = {};
  fromPartyResponse : any = [];
  toPartyResponse : any = [];

  clSupplierRes : any = [];
  clSupplierListRes : any = [];
  
  public settings = {};

  public groupSettings = {};

  GroupSupplierList : any =[];

  displaySupplier;
  displayGroupSupplier;
  ngOnInit() {

    this.submitted=false;
    this.isViewable=true;
    this.checkBoxValidation = false;

    this.displaySupplier = false;
    this.displayGroupSupplier = false;

    this.fetch_truck_model_staging();
    this.fetch_city_master_staging();
    this.fetch_master_branch_staging();
    this.fetch_material_master_staging();
    this.fetch_master_material_packaging_staging();

    this.milan = this.formattedDate;

    this.todayDate=this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    this.setDefaultDateTime();

    this.getLoadDetails();
    
    while(this.checkBoxArray.length > 0){
      this.checkBoxArray.shift();
    }

    this.getSupplierList();
    
    this.fetchSupplierGroupList();

    this.settings = {
      singleSelection: false,
      idField: 'supplierId',
      textField: 'sFullD',
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      limitSelection: -1,
      clearSearchFilter: true,
      maxHeight: 197,
     // itemsShowLimit: 3,
      searchPlaceholderText: 'Search Supplier',
      noDataAvailablePlaceholderText: 'No data Vailable',
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false,
    };

    this.groupSettings = {
      singleSelection: false,
      idField: 'groupId',
      textField: 'groupName',
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      limitSelection: -1,
      clearSearchFilter: true,
      maxHeight: 197,
     // itemsShowLimit: 3,
      searchPlaceholderText: 'Search Supplier',
      noDataAvailablePlaceholderText: 'No data Vailable',
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false,
    };

  }

  /*########### Form ###########*/
  registrationForm = this.fb.group({
    fromCityName: ['', [Validators.required]],
    fromPartyName: [''], 
    //toCityName: ['', [Validators.required]],
    //toPartyName: [''],
    //toTon: [''],
    branchName: ['', [Validators.required]],
    bidStartDate: ['', [Validators.required]],
    bidStartTime: ['', [Validators.required]],
    bidClosingDate: ['', [Validators.required]],
    bidClosingTime: ['', [Validators.required]],
    reportingDate: ['', [Validators.required]],
    reportingTime: ['', [Validators.required]],
    materialName: ['', [Validators.required]],
    PackagName: ['',[Validators.required]],
    targetLoad : [''],
    remarks: [''],
    requirementStatus: [''],
    toArr: this.fb.array([this.createToItem()]),
    truckModelArr: this.fb.array([this.createtruckModel()]),
    //truckModel: ['', [Validators.required]],
    //ratePerTon: ['', [Validators.required]],
    //items: this.fb.array([ this.createItem() ]),
    //tons: ['',[Validators.required , Validators.pattern("^[0-9]*$")]],
    //referenceRate: [''],
    //referenceRate: ['',[Validators.required , Validators.pattern("^[0-9]*$")]],
  })

  nontrade = [
    { label: 'a', selected: false }, 
    { label: 'b', selected: false }, 
    { label: 'c', selected: false }, 
    { label: 'd', selected: false }
  ];

  loadContent = false;
  clSupplierListResLength : any;
  supplierListErr;

  getSupplierList() {

    this.requestData = {
      clientId : this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret
    }

    return this.tfs.fetchSupplierList(JSON.stringify(this.requestData)).subscribe(data => {
      
      this.clSupplierRes = data;
      this.clSupplierListRes = this.clSupplierRes.list;
      //console.log(this.clSupplierListRes);
      this.clSupplierListResLength = this.clSupplierRes.list.length;
      console.log(this.clSupplierListResLength);

      for(var i=0; i<this.clSupplierListRes.length; i++){
        this.clSupplierRes.list[i].sFullD = this.clSupplierRes.list[i].supplierName+" - "+this.clSupplierRes.list[i].supplierNumber; 
      }

      if(this.clSupplierRes.status == '1'){
        this.loadContent = true;
      }

    },error => console.log(error));

  }

  supplierIdData = [];
  public onItemSelect(item: any) {
    
    this.supplierIdData.push(item.supplierId);
    //console.log(this.supplierIdData);
  }
  
  public onDeSelect(item: any) {
    
    for(var i=0; i < this.supplierIdData.length;i++){
      if(this.supplierIdData[i] == item.supplierId){
        this.supplierIdData.splice(i,1);
      }
    }
    //console.log(this.supplierIdData);
  }

  public onSelectAll(items: any) {
    
    this.supplierIdData = [];
    for(var mp in items){
      this.supplierIdData.push(items[mp].supplierId);
    }
    //console.log(this.supplierIdData);
  }

  public onDeSelectAll(items: any) {
    console.log(items);
   
    this.supplierIdData = [];
  }

  public onFilterChange(item: any) {
    //console.log(item);
    if(this.supplierIdData.length === 0) {
      this.submitted = true;
    }
  }

  public onDropDownClose(item: any) {
    //console.log(item);
    if(this.supplierIdData.length === 0) {
      this.submitted = true;
    }
  }

  partialListVal = [];

  onSubmit() {

    this.submitted = true;
    
    if(this.registrationForm.invalid) {
      return;
    } 

    let orderType = 'single';

    let listOfFromParty = [];

    let fromCity = this.registrationForm.get("fromCityName").value;
    if( fromCity != null && fromCity != ''){

      let ct ={
        cityId : fromCity
      };

      let party = this.registrationForm.get("fromPartyName").value;
      if(party != null && party != ''){

        let ptL = [];

        let pt = {
          partyId : party
        };

        ptL.push(pt);

        ct['listOfParty'] = ptL;
      }
      
      listOfFromParty.push(ct);
    }

    let listOfToParty = [];
    let toCity = this.registrationForm.get('toArr').value;
    if(toCity != null && toCity.length !=0){
      
      for(var index in toCity){

        let ct = toCity[index].toCityName;
        
        if(ct != null && ct != ''){

          let ctM = {
            cityId : ct
          }

          let ptM = {};
          let pt = toCity[index].toPartyName;
          let tn = toCity[index].toTon;
          if(pt != null && pt != ''){

            ptM = {
              partyId : pt
            }

            if(tn != null && tn != ''){
              ptM['ton'] = tn;
              ctM['ton'] = tn;
            }
          }
          else{
            if(tn != null && tn != ''){
              ctM['ton'] = tn;
            }
          }

          if(listOfToParty.length == 0){

            let ptL = [];

            if(ptM['partyId']){
              ptL.push(ptM);  
              ctM['listOfParty'] = ptL;
            }
            
            listOfToParty.push(ctM);
          }else{

            let ext = false;
            let ind = 0;
            for(var index in listOfToParty){

              if(listOfToParty[index].cityId == ct){
                ext = true;
                ind = Number(index);
                break;
              }
            }

            if(ext){

              if(ctM['ton']){

                if(listOfToParty[ind].ton){
                  listOfToParty[ind].ton = Number(listOfToParty[ind].ton) + Number(ctM['ton']);
                }
                else{
                  listOfToParty[ind].ton = ctM['ton'];
                }
              }
              
              if(listOfToParty[ind].listOfParty){

                if(ptM['partyId']){
                  orderType = 'club';
                  listOfToParty[ind].listOfParty.push(ptM);
                }
              }
              else{
                let ptL = [];
                if(ptM['partyId']){
                  ptL.push(ptM);  
                  listOfToParty[ind]['listOfParty'] = ptL;
                } 
              }
              
            }
            else{
              orderType = 'club';
              let ptL = [];

              if(ptM['partyId']){
                ptL.push(ptM);  
                ctM['listOfParty'] = ptL;
              }
            
              listOfToParty.push(ctM);

            }

          }
        }
      }
    }

    let listOfTrucks = [];
    let truckModelArr = this.registrationForm.get('truckModelArr').value;
    if(truckModelArr != null && truckModelArr.length !=0){

      for(var index in truckModelArr){

        let truck = truckModelArr[index].truckModel;
        if(truck != null && truck != ''){

          let truckM = {
            truckModelId : truck
          };

          let ref = truckModelArr[index].ratePerTon;
          if(ref != null && ref !=''){

            truckM['referenceRate']= ref;
          }else{
            truckM['referenceRate'] = 0;
          }
          
          listOfTrucks.push(truckM);
        }
      }
    }

    if(this.supplierIdData.length == 0 && this.groupSupplierData.length == 0){
      this.supplierListErr = false;
      this.isViewable = true;
      alert("Please select suppliers.");
      return;
    } else {

      if(this.displaySupplier && this.clSupplierListResLength == this.supplierIdData.length){
      
        var isPartialVal = 0;
        this.partialListVal = [];

      } else {
        var isPartialVal = 1;
        if(this.displaySupplier)
          this.partialListVal = this.supplierIdData;
        else{
          //alert(this.groupSupplierData);
          for(var index in this.groupSupplierData){
            let listOfSupplier = this.groupSupplierData[index].listOfSupplier;
            for(var index in listOfSupplier)
              this.partialListVal.push(listOfSupplier[index].supplierId);
          }
        }
      }
      this.supplierListErr = false;
      this.isViewable = false;
    }

    this.isViewable=false;

    this.requestData = {   
      clientId : this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret,
      loadStatus : 1,
      orderType : orderType,
      listOfFromParty : listOfFromParty,
      listOfToParty : listOfToParty,
      listOfTrucks : listOfTrucks,
      branchId : this.registrationForm.get("branchName").value,
      bidStartDate : this.registrationForm.get("bidStartDate").value+" "+this.registrationForm.get("bidStartTime").value,
      bidCloseDate : this.registrationForm.get("bidClosingDate").value+" "+this.registrationForm.get("bidClosingTime").value,
      reportingDate :this.registrationForm.get("reportingDate").value+" "+this.registrationForm.get("reportingTime").value,
      materialTypeId : this.registrationForm.get("materialName").value,
      materialPackagingId : this.registrationForm.get("PackagName").value,
      targetLoad :  this.registrationForm.get("targetLoad").value,
      remarks :  this.registrationForm.get("remarks").value,
      requirementStatus :  this.registrationForm.get("requirementStatus").value,
      isPartial : isPartialVal,
      partialList : this.partialListVal,
      //partialList : this.supplierIdData,
    };
   console.log("Club Load Request Data :: "+JSON.stringify(this.requestData));
    
    
    this.buService.createLoad(JSON.stringify(this.requestData)).subscribe((data : {}) => {
      
      this.FormResponseData = data;
      //console.log("response :"+this.FormResponseData.status);
      
      if(this.FormResponseData.status ==1 ){
        alert("New Load Added Successfully!");

        this.setDefaultDateTime();

        this.isViewable=true;
        this.checkBoxValidation=false;
        this.submitted = false;

        this.registrationForm.controls.fromCityName.setValue('');
        //this.registrationForm.controls.toCityName.setValue('');
        this.registrationForm.controls.branchName.setValue('');
        this.registrationForm.controls.materialName.setValue('');
        this.registrationForm.controls.PackagName.setValue('');

        //this.registrationForm.controls.tons.setValue('');
        //this.registrationForm.controls.referenceRate.setValue('');
        this.registrationForm.reset();

        window.location.reload();
      }
      else{
        alert(this.FormResponseData.message);
        this.isViewable=true;
      }
    }, error => console.log(error));
    

  }
  
  createToItem() {
    return this.fb.group({
      toCityName: ['', [Validators.required]],
      toPartyName: [''],
      toTon: ['']
    })
  }

  addToCityDetail() {
    this.toArr = this.registrationForm.get('toArr') as FormArray;
    this.toArr.push(this.createToItem());
  }

  createtruckModel() {
    return this.fb.group({
      truckModel: ['', [Validators.required]],
      ratePerTon: ['']
    })
  }

  addtruckModel() {
    this.truckModelArr = this.registrationForm.get('truckModelArr') as FormArray;
    this.truckModelArr.push(this.createtruckModel());
  }

  fetch_truck_model_staging() {
      return this.restApi.fetch_truck_model_staging().subscribe(data => {
      this.TruckLoad = data.list;
      console.log(this.TruckLoad);

    },error => console.log(error));
  }

  fetch_city_master_staging(){
      return this.restApi.fetch_city_master_staging().subscribe(data => {
      this.cityMaster = data.list;

      const fromCityName = localStorage.getItem('fromCityName');
      const toCityName = localStorage.getItem('toCityName');

      for(let i=0; i < this.cityMaster.length; i++){

        if(fromCityName == this.cityMaster[i].city_name){

          this.registrationForm.controls.fromCityName.setValue(this.cityMaster[i].city_id);
        }
        
        if (toCityName == this.cityMaster[i].city_name){
          
          this.registrationForm.controls.toCityName.setValue(this.cityMaster[i].city_id);
        }
      }

      //console.log(this.cityMaster);
    }, error => console.log(error));
  }

  masterBranchRes : any = [];
  fetch_master_branch_staging(){

    if(localStorage.getItem('userRole') == "SUB_ADMIN"){
      this.requestData = {
        adminId : localStorage.getItem('adminIdLocal')
      }
    } else {
      this.requestData = {
        adminId : ""
      }
    }
    console.log(this.requestData);

    this.restApi.fetch_master_branch_staging(JSON.stringify(this.requestData)).subscribe(data => {
      
      //this.masterBranch = data.list;
      this.masterBranchRes = data;
      this.masterBranch = this.masterBranchRes.list;

      const branchName = localStorage.getItem('branchName');
      for(let i=0; i < this.masterBranch.length; i++){
        if(branchName == this.masterBranch[i].branch_name){
          this.registrationForm.controls.branchName.setValue(this.masterBranch[i].branch_id);
        }
      }
      
      //console.log(this.masterBranch);
    }, error => console.log(error));
  }

  // fetch_master_branch_staging(){
  //   return this.restApi.fetch_master_branch_staging().subscribe(data => {
  //     this.masterBranch = data.list;

  //     const branchName = localStorage.getItem('branchName');
  //     for(let i=0; i < this.masterBranch.length; i++){

  //       if(branchName == this.masterBranch[i].branch_name){
          
  //         this.registrationForm.controls.branchName.setValue(this.masterBranch[i].branch_id);
  //       }
  //     }
      
  //     //console.log(this.masterBranch);
  //   }, error => console.log(error));
  // }

  fetch_material_master_staging() {
    return this.restApi.fetch_material_master_staging().subscribe(data => {
      this.materialMaster = data.list;
      //console.log(this.materialMaster);
    }, error => console.log(error));
  }

  fetch_master_material_packaging_staging(){
    return this.restApi.fetch_master_material_packaging_staging().subscribe(data => {
      this.masterMaterialPackaging = data.list;
      //console.log(this.masterMaterialPackaging);
    }, error => console.log(error));
  }

  get formValidation(){
    return this.registrationForm.controls;
  }

  //only number will be add
  keyPress(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  stopInterval(){

    if (this.interval) {
      clearInterval(this.interval);
   }

  }

  setDefaultDateTime(){

   this.interval = setInterval(() => {
     
    var date = new Date();
    this.registrationForm.controls.bidStartDate.setValue(formatDate(date, "yyyy-MM-dd" , "en-US"));
    this.registrationForm.controls.bidClosingDate.setValue(formatDate(date, "yyyy-MM-dd" , "en-US"));
    this.registrationForm.controls.reportingDate.setValue(formatDate(date, "yyyy-MM-dd" , "en-US"));

    date.setMinutes(date.getMinutes()+5);
    this.registrationForm.controls.bidStartTime.setValue(formatDate(date, "HH:mm" , "en-US"));

    date.setMinutes(date.getMinutes()+5);
    this.registrationForm.controls.bidClosingTime.setValue(formatDate(date, "HH:mm" , "en-US"));

    date.setMinutes(date.getMinutes()+5);
    this.registrationForm.controls.reportingTime.setValue(formatDate(date, "HH:mm" , "en-US"));

  }, 1000);

  }

  bidStartDateTimeValidation(){

    var date = new Date();
    date.setMinutes(date.getMinutes()+5);
    var startDateTime =  new Date(this.registrationForm.get("bidStartDate").value +' '+ this.registrationForm.get("bidStartTime").value);

    if(date > startDateTime){

      //alert("Invalid time! Bid start time must be greater than current date & time to 5 minutes!"); 

      this.message = "Invalid time! Bid start time must be greater than current date & time to 5 minutes!";
      this.alertMessage = true;
      this.showMessage();
      this.registrationForm.controls.bidStartTime.setValue("");
      return;
    }
    else{

      this.registrationForm.controls.bidClosingDate.setValue(formatDate(startDateTime, "yyyy-MM-dd" , "en-US"));
      this.registrationForm.controls.reportingDate.setValue(formatDate(startDateTime, "yyyy-MM-dd" , "en-US"));

      startDateTime.setMinutes(startDateTime.getMinutes()+5);
      this.registrationForm.controls.bidClosingTime.setValue(formatDate(startDateTime, "HH:mm" , "en-US"));

      startDateTime.setMinutes(startDateTime.getMinutes()+5);
      this.registrationForm.controls.reportingTime.setValue(formatDate(startDateTime, "HH:mm" , "en-US"));

    }
  }

  bidCloseDateTimeValidation(){

    var startDateTime =  new Date(this.registrationForm.get("bidStartDate").value +' '+ this.registrationForm.get("bidStartTime").value);
    startDateTime.setMinutes(startDateTime.getMinutes()+5);

    var endDateTime =  new Date(this.registrationForm.get("bidClosingDate").value +' '+ this.registrationForm.get("bidClosingTime").value);

   
    if(formatDate(startDateTime, "yyyy-MM-dd" , "en-US") > 
        formatDate(endDateTime, "yyyy-MM-dd" , "en-US")){

     // alert("Invalid date! Bid close date must be greater than or equal to bid start date!");

      this.message = "Invalid date! Bid close date must be greater than or equal to bid start date!";
      this.alertMessage = true;
      this.showMessage();
      this.registrationForm.controls.bidClosingDate.setValue("");
      return;
    }
    else{
      this.registrationForm.controls.reportingDate.setValue(formatDate(endDateTime, "yyyy-MM-dd" , "en-US"));
    }

    if(startDateTime > endDateTime){

     // alert("Invalid time! Bid close time must be greater than bid start date & time to 5 minutes!");

      this.message = "Invalid time! Bid close time must be greater than bid start date & time to 5 minutes!";
      this.alertMessage = true;
      this.showMessage();
      this.registrationForm.controls.bidStartTime.setValue("");
      return;
    }
    else{

      endDateTime.setMinutes(endDateTime.getMinutes()+5);
      this.registrationForm.controls.reportingTime.setValue(formatDate(endDateTime, "HH:mm" , "en-US"));

    }
  }

  createNewLoadDateTimeValidation(){
    
    var endDate = this.registrationForm.get("bidClosingDate").value;
    var endTime = this.registrationForm.get("bidClosingTime").value;
    var reportingDate = this.registrationForm.get("reportingDate").value;
    var reportingTime = this.registrationForm.get("reportingTime").value;

    var startDateTime;
    var endDateTime;
    var reportingDateTime;

     

     if(endDate !=''){

      if(reportingDate !=''){

        if(formatDate(new Date(endDate), "yyyy-MM-dd" , "en-US") > formatDate(new Date(reportingDate), "yyyy-MM-dd" , "en-US")){
          //alert("Invalid date! Reporting date must be greater than or equal to bid close date!");

          this.message = "Invalid date! Reporting date must be greater than or equal to bid close date!";
          this.alertMessage = true;
          this.showMessage();
          this.registrationForm.controls.reportingDate.setValue("");
          return;
        }

        if(endTime !=''){
          endDateTime = new Date(endDate +" "+endTime);
          endDateTime.setMinutes(endDateTime.getMinutes()+5);

          if(reportingTime !=''){
            
            reportingDateTime = new Date(reportingDate +" "+reportingTime);
            
            if(endDateTime > reportingDateTime){
             // alert("Invalid time! Reporting time must be greater than bid close date & time!");

              this.message = "Invalid time! Reporting time must be greater than bid close date & time!";
              this.alertMessage = true;
              this.showMessage();
              this.registrationForm.controls.reportingTime.setValue("");
              return;
            }

          }

        }

      }

     }
  }

  showMessage(){

    setTimeout(() => this.alertMessage=false , 3000);
  }

  allNonTrades(event) {

    this.checkBoxArray = [];

    const checked = event.target.checked;

    this.TruckLoad.forEach(item => item.selected = checked);
   
    if(checked == true){

      this.checkboxes.forEach((element) => {
        element.nativeElement.checked = true;
      });

    for(var i = 0; i < this.TruckLoad.length; i++) {
        
      this.checkBoxArray.push(this.TruckLoad[i].truck_model_id);

    }

  } else {

    this.checkboxes.forEach((element) => {
      element.nativeElement.checked = false;
    });
    this.checkBoxArray = [];
  }

    //console.log(this.checkBoxArray)
    
  }

  onCheckboxChange(e) {

    if(e.target.checked) {
     
      this.checkBoxValidation=false;
      this.checkBoxArray.push(e.target.value); 

    } else {
         
      for(var i=0; i < this.checkBoxArray.length;i++){
        if(this.checkBoxArray[i] == e.target.value){
          this.checkBoxArray.splice(i,1);
        }
       } 
    }

  }

  getLoadDetails(){

    const loadId = localStorage.getItem('loadId');
    const fromCityName = localStorage.getItem('fromCityName');
    const toCityName = localStorage.getItem('toCityName');
    const branchName = localStorage.getItem('branchName');
    
    console.log("Load details for duplicate : loadId : "+loadId+" : fromCityName : "+fromCityName+" : toCityName : "+toCityName+" : branchName : "+branchName)
  }

  getPartyList(cityId, type, index){

    this.requestData = {
      clientId : this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret,
      cityId : cityId
    }

    this.buService.getPartyList(JSON.stringify(this.requestData)).subscribe((data : {}) =>{

      if(type == 'from'){
        this.fromPartyResponse = data;
      }else if(type == 'to'){
        this.toPartyResponse[index] = data;
      }
      

    }, error => console.log("getPartyList Error: "+error));

  }

  onChangeFromCityName(event, type, index){
    
    //let cityId = this.registrationForm.get("fromCityName").value;

    if(type == 'from'){
      this.registrationForm.controls.fromPartyName.setValue('');
    }
    else if(type == 'to'){

      if(this.registrationForm.get("toArr").value[index].toPartyName != null && 
         this.registrationForm.get("toArr").value[index].toPartyName != ''){

          (<FormArray>this.registrationForm.controls['toArr']).at(index).patchValue({toPartyName:''});
      }
    }
    this.getPartyList(event, type, index);
  }

  fetchSupplierGroupList() {

    this.requestData = {
      status : 'Active'
    }

    return this.tfs.fetchSupplierGroupList(JSON.stringify(this.requestData)).subscribe(data => {
      
      this.GroupSupplierList = data;

      if(this.GroupSupplierList.status == '1'){
        this.loadContent = true;
      }

    },error => console.log(error));

  }

  groupSupplierData = [];
  public onGroupItemSelect(item: any) {
    
    for(var index in this.GroupSupplierList.listOfGroup){
      if(item.groupId == this.GroupSupplierList.listOfGroup[index].groupId)
        this.groupSupplierData.push(this.GroupSupplierList.listOfGroup[index]);  
    }
    
    //console.log(this.groupSupplierData);
  }
  
  public onGroupDeSelect(item: any) {
    
    for(var index in this.groupSupplierData){
      if(this.groupSupplierData[index].groupId == item.groupId)
        this.groupSupplierData.splice(Number(index),1);
    }
    //console.log(this.groupSupplierData);
  }

  public onGroupSelectAll(items: any) {
    
    this.groupSupplierData = [];
    for(var index in this.GroupSupplierList.listOfGroup){
      this.groupSupplierData.push(this.GroupSupplierList.listOfGroup[index]);
    }
    //console.log(this.groupSupplierData);
  }

  public onGroupDeSelectAll(items: any) {
    this.groupSupplierData = [];
  }

  checkUncheckSupplier(e, data){

    if(data == 'supplier'){
      if (e.target.checked){
        this.displaySupplier = true;
        this.displayGroupSupplier = false;
        this.groupSupplierData = [];
      }else{
        this.displaySupplier = false;
        this.supplierIdData = [];
      }
    }else if(data == 'groupSupplier'){
      if (e.target.checked){
        this.displaySupplier = false;
        this.displayGroupSupplier = true;
        this.supplierIdData = [];
      }else{
        this.displayGroupSupplier = false;
        this.groupSupplierData = [];
      }
    }
  }
  
}
