import { Component, OnInit , ViewChild, Input, Output, EventEmitter} from '@angular/core';
import { FormGroup , FormBuilder , Validators } from '@angular/forms';
import { APiProperties } from '../../class/api-properties';
import { TruckieFunctionsService } from 'app/service/truckie-functions.service';
import { DatePipe,formatDate } from '@angular/common';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-driver-update',
  templateUrl: './driver-update.component.html',
  styleUrls: ['./driver-update.component.css']
})
export class DriverUpdateComponent implements OnInit {
  
  @Input() supplierId;
  @Input() driverId;
  @Output() hideUpdateDriver = new EventEmitter();
  
  apiProperties : APiProperties = new APiProperties();
  
  constructor(private fb: FormBuilder, private tfs : TruckieFunctionsService, private datePipe: DatePipe,private toastr: ToastrService) { }
  
  requestData : any = {};
  ddsRes : any = [];
  uddRes : any = [];
  dlExpiryDate : any;
  dlExpiryDateUp : any;
  duBack;
  duBlk;
  todayDate : any;
  
  ngOnInit(): void {
    this.duBack = true;
    this.duBlk = true;
    this.todayDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.fetchDriverDetails();
  }
  
  driverUpdateForm = this.fb.group({
    name : ['' , Validators.required], 
    mobileNumber : ['' , Validators.required],
    drivingLicenseExpiryDate : ['' , Validators.required],
    drivingLicenseNumber : ['' , Validators.required],
    drivingLicenseImage : ['' , Validators.required],
    driverProfileImage : ['' , Validators.required],
  });

  get f() { return this.driverUpdateForm.controls; }

  fetchDriverDetails() {

    this.requestData = {
      supplierId : this.supplierId,
      driverId : this.driverId,
    }
    console.log(this.requestData);

    return this.tfs.fetch_driver_details_staging(JSON.stringify(this.requestData)).subscribe(data => {
      
      this.ddsRes = data;
      console.log(this.ddsRes.list[0]);
      
      if(this.ddsRes.status == 1){

        if(this.ddsRes.list[0].drivingLicenseExpiryDate){
          this.dlExpiryDate = this.datePipe.transform(this.ddsRes.list[0].drivingLicenseExpiryDate, 'yyyy-MM-dd');
        }
         this.driverUpdateForm.patchValue({
           'name' : this.ddsRes.list[0].name,
           'mobileNumber' : this.ddsRes.list[0].mobileNumber,
           'drivingLicenseExpiryDate' : this.dlExpiryDate,
           'drivingLicenseNumber' : this.ddsRes.list[0].drivingLicenseNumber,
           'drivingLicenseImage' : this.ddsRes.list[0].drivingLicenseImage,
           'driverProfileImage' : this.ddsRes.list[0].driverProfileImage,
         });
      }

    },error => console.log(error));

  }

  updateDriverDetailData() {
    
    this.dlExpiryDateUp = this.datePipe.transform(this.driverUpdateForm.get("drivingLicenseExpiryDate").value, 'yyyy-MM-dd hh:mm:ss');

    this.requestData = {
      clientId : this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret,
      supplierId : this.supplierId,
      driverId : this.driverId,
      name : this.driverUpdateForm.get("name").value,
      mobileNumber : this.driverUpdateForm.get("mobileNumber").value,
      //drivingLicenseExpiryDate : this.driverUpdateForm.get("drivingLicenseExpiryDate").value,
      drivingLicenseExpiryDate : this.dlExpiryDateUp,
      drivingLicenseFrontPic : this.driverUpdateForm.get("drivingLicenseImage").value,
      drivingLicenseNumber : this.driverUpdateForm.get("drivingLicenseNumber").value,
      driverProfilePic : this.driverUpdateForm.get("driverProfileImage").value,
    }
    console.log(this.requestData);

    return this.tfs.updateDriverDetails(JSON.stringify(this.requestData)).subscribe(data => {
      
      this.uddRes = data;
      console.log(this.uddRes);
      
      if(this.uddRes.status == 1){
        this.showNotification('top','right',this.uddRes.message, '1');
        this.back();
      }

    },error => console.log(error));

  }

  documentFor : any;
  truckId : any;
  chequePhotoImg;
  upImageUrl : any;
  paramPass : any;
  paramPassFinal : any;
  truckBankBlk = true;
  truckImageUpload = false;
  
  imageUploadShow(paramPassVal) {
    this.documentFor = "driver";
    this.supplierId = this.supplierId;
    this.driverId = this.driverId;
    this.paramPass = paramPassVal;
    console.log(this.supplierId + " ~~~~ " + this.driverId);

    this.duBack = false;
    this.duBlk = false;
    this.truckImageUpload = true;
  }

  hideImageupload(data){

    if(data != null){
      
        console.log(data);
        
        this.upImageUrl = data.finalImageUp;
        this.paramPassFinal = data.paramPassFinal;
        
        if(this.paramPassFinal == "drivingLicenseImage"){
          this.driverUpdateForm.patchValue({
            'drivingLicenseImage' : this.upImageUrl,
          });
        }
        
        if(this.paramPassFinal == "driverProfileImage"){
          this.driverUpdateForm.patchValue({
            'driverProfileImage' : this.upImageUrl,
          });
        }

    }
    
    this.duBack = true;
    this.duBlk = true;
    this.truckImageUpload = false;
    
  }

  showNotification(from, align, messageDis, errorNo) {
    
    // const color = Math.floor(Math.random() * 5 + 1);
    const color = errorNo;
    
    //console.log(errorNo);

    switch (color) {
      
      case '1':
        this.toastr.success(
          '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">'+messageDis+'</span>',
          "",
          {
            timeOut: 500,
            // closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-success alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;
      
        case '2':
        this.toastr.warning(
        '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">'+messageDis+'</span>',
          "",
          {
            timeOut: 4000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-danger alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;
      
        default:
        break;
    }

  }

  back(){
    this.hideUpdateDriver.emit();
  }

}