import { Component, OnInit } from '@angular/core';
import { FormBuilder , FormGroup , Validators } from '@angular/forms';
import { TruckieFunctionsService } from 'app/service/truckie-functions.service';
import { APiProperties } from 'app/class/api-properties';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-material',
  templateUrl: './material.component.html',
  styleUrls: ['./material.component.css']
})
export class MaterialComponent implements OnInit {
  
  apiProperties : APiProperties = new APiProperties();

  constructor(private fb: FormBuilder, private tfs : TruckieFunctionsService, private toastr : ToastrService) { }

  submitted = false;

  materialBack;
  materialAdd;
  materialList;

  ngOnInit(): void {

    this.materialBack = true;
    this.materialAdd = true;
    this.materialList = true;

    this.submitted=false;
    this.isViewable = true;

    this.getMaterial();
  }

  materialForm = this.fb.group({
    materialName : ['' , [Validators.required, Validators.minLength(3), Validators.maxLength(30)]]
  });

  get f(){ return this.materialForm.controls; }

  keyPress(event: any) {
    const pattern = /[0-9a-zA-Z ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  inputValidation(){

    var data = this.materialForm.get("materialName").value.trim();
    if(data == ''){
      this.materialForm.controls.materialName.setValue('');
      return;
    }
    
  }

  materialRes : any = [];

  getMaterial(){

    this.requestData = {
      clientId : this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret,
    }

    return this.tfs.fetchMaterialWithStatus(JSON.stringify(this.requestData)).subscribe((data : {}) => {
      this.materialRes = data;
    }, error => console.log(error));
  }

  isViewable;
  requestData : any = {};
  materialData : any = [];

  materialSave() {
    this.submitted = true;

    if(this.materialForm.invalid) {
      return;
    }

    this.isViewable = false;

    this.requestData = {
      clientId : this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret,
      materialName :this.materialForm.get("materialName").value.trim()
    };
    console.log(this.requestData);


    this.tfs.addMaterialType(JSON.stringify(this.requestData)).subscribe((data : {}) => {
      
      this.materialData = data;
      console.log(this.materialData);
      
      if(this.materialData.status ==1 ){
        this.isViewable = true;
        this.showNotification('top','right',this.materialData.message, '1');
        this.ngOnInit();
      }
      else{
        this.isViewable = true;
        this.showNotification('top','right',this.materialData.message, '2');
      }
    }, error => console.log(error));
    
  }

  materialUpdateBlk;
  materialId : any;
  materialName : any;

  showMaterialUpdate(material_id,material_name){
    this.materialId = material_id;
    this.materialName = material_name;
    this.materialBack = false;
    this.materialAdd = false;
    this.materialList = false;
    this.materialUpdateBlk = true;
  }
  
  hideMaterialUpdate(){
    this.getMaterial();
    this.materialBack = true;
    this.materialAdd = true;
    this.materialList = true;
    this.materialUpdateBlk = false;
  }

  materialStatusRes : any = [];
  updateMaterialStatus(e,material_id, status){

    //if(e.traget.checked){}
    this.requestData = {
      clientId : this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret,
      materialId : material_id,
      status : status
    };
    console.log(this.requestData);

    return this.tfs.updateMaterialMaster(JSON.stringify(this.requestData)).subscribe((data : {}) => {
      this.materialStatusRes = data;

      if(this.materialStatusRes.status == 1){
        this.getMaterial();
      }

    }, error => console.log(error));

  }

  materialReset() {
    this.submitted = false;
    this.materialForm.reset();
  }

  showNotification(from, align, messageDis, errorNo) {
    
    // const color = Math.floor(Math.random() * 5 + 1);
    const color = errorNo;
    
    //console.log(errorNo);

    switch (color) {
      
      case '1':
        this.toastr.success(
          '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">'+messageDis+'</span>',
          "",
          {
            timeOut: 500,
            // closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-success alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;
      
        case '2':
        this.toastr.warning(
        '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">'+messageDis+'</span>',
          "",
          {
            timeOut: 4000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-danger alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;
      
        default:
        break;
    }

  }

}