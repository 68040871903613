export class ViewLiewLoadTableData {

    loadId : any;
    fromCityName : any;
    fromCityList : any;
    toCityName : any;
    toCityList : any;
    bidCloseDateTime : any;
    branchName : any;
    reportingDate : any;
    countOfBidsReceived : any;
    loadStatus : any;
    lowestBidList : any;
    remarks : any;
    order_type : any;
    activeStatus : any;
    countOfBidReceived : any;
}
