import { Component, OnInit, Input } from '@angular/core';
import { formatDate } from '@angular/common';
import { WalletTransactionHistoryService } from 'app/service/wallet-transaction-history.service';

@Component({
  selector: 'app-wallet-transaction-history',
  templateUrl: './wallet-transaction-history.component.html',
  styleUrls: ['./wallet-transaction-history.component.css']
})
export class WalletTransactionHistoryComponent implements OnInit {

  @Input() supplierId : any;

  constructor(private walletTransactionHistoryService : WalletTransactionHistoryService) { }

  requestData : any = {};
  walletTransactionHistory :  any = [];

  dataNotFound;
  loading;
  serverError;

  ngOnInit(): void {

    this.dataNotFound=false;
    this.loading = true;
    this.serverError = false;

    this.getSupplierWalletTransactionHistory();
  }

  getSupplierWalletTransactionHistory(){

    var date = new Date();
    var todate = formatDate(date, "yyyy-MM-dd" , "en-US")+" "+formatDate(date, "HH:mm:ss" , "en-US");
    date.setDate(date.getDate()-20);
    var fromdate = formatDate(date, "yyyy-MM-dd" , "en-US")+" "+formatDate(date, "HH:mm:ss" , "en-US");
    this.requestData = {
      "supplierId" : this.supplierId,
      "fromDate" : fromdate,
      "toDate" : todate
    };

    this.walletTransactionHistoryService.getSupplierWalletTransactionHistory(JSON.stringify(this.requestData)).subscribe((data : {}) =>{

      this.walletTransactionHistory = data;

      if(this.walletTransactionHistory.status ==1){

        if(this.walletTransactionHistory.listOfTransactionHistory.length ==0){

          this.dataNotFound=true;
          this.loading = false;
        }
        else{
          this.loading = false;
        }
      }
      else{
          this.dataNotFound=true;
          this.loading = false;
      }

    }, error => {
      this.serverError = true;
      console.log("Error : "+error);
    });

  }

}
