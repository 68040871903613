import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable,throwError,forkJoin } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { APiProperties } from 'app/class/api-properties';

@Injectable({
  providedIn: 'root'
})
export class ViewLiveLoadService {

  constructor(private http:HttpClient) { }

  apiProperties : APiProperties = new APiProperties();

  getViewLiveLoadHttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  getViewLiveLoad() : Observable<{}> {
    return this.http.get<any>(this.apiProperties.truckiefunctionsApi+'view_live_load', this.getViewLiveLoadHttpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  getCountOfBidPerLoadHttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  countOfBidPerLoad : any=[];
  getCountOfBidPerLoad(data : any[]) : Observable<any[]> {
    for(let i=0; i< data.length; i++){ 
      this.countOfBidPerLoad[i]= this.http.post(this.apiProperties.truckiefunctionsApi+'count_of_bids_per_load', data[i] ,this.getCountOfBidPerLoadHttpOptions).pipe(
        retry(1),
        catchError(this.handleError)
      )
    }
  
    return forkJoin(this.countOfBidPerLoad);
  }

  getLoadStatusHttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  loadStatus : any=[];
  getLoadStatus(data : any[]) : Observable<any[]> {
   
    for(let i=0; i< data.length; i++){
      this.loadStatus[i]= this.http.post(this.apiProperties.truckiefunctionsApi+'get_load_status', data[i] ,this.getLoadStatusHttpOptions).pipe(
        retry(1),
        catchError(this.handleError)
      )
    }
  
    return forkJoin(this.loadStatus);
  }

  updateLoadStatusHttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  updateLoadStatus(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'update_load_status', data ,this.updateLoadStatusHttpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  completeLoadHttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  completeLoad(data : {}) : Observable<{}> {
    return this.http.post(this.apiProperties.truckiefunctionsApi+'update_live_load_as_completed', data ,this.completeLoadHttpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  handleError(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
 }
}
