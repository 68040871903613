import { Component, OnInit , ViewChild, Input, Output, EventEmitter} from '@angular/core';
import { FormGroup , FormBuilder , Validators } from '@angular/forms';
import { APiProperties } from '../../class/api-properties';
import { TruckieFunctionsService } from 'app/service/truckie-functions.service';
import { DatePipe,formatDate } from '@angular/common';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-supplier-bank-update',
  templateUrl: './supplier-bank-update.component.html',
  styleUrls: ['./supplier-bank-update.component.css']
})
export class SupplierBankUpdateComponent implements OnInit {

  @Input() bankData;
  @Output() hideBankUpdate = new EventEmitter();

  apiProperties : APiProperties = new APiProperties();
  
  constructor(private fb: FormBuilder, 
              private tfs : TruckieFunctionsService,
              private datePipe: DatePipe, 
              private toastr: ToastrService) { }

  buBack;
  buBlk;
  ngOnInit(): void {
    this.buBack = true;
    this.buBlk = true;
    console.log(this.bankData);
    this.setBankDetails();
  }
  
  bankUpdateForm = this.fb.group({
    supplierBankDetailsId : ['' , Validators.required],
    supplierId : ['' , Validators.required],
    bankName : ['' , Validators.required],
    IFSCCode : ['' , Validators.required],
    bankAccountName : ['' , Validators.required],
    bankAccountNumber : ['' , Validators.required],
    //bankProofPhotoURL : ['' , Validators.required],
    //cancelChequePhoto : ['' , Validators.required],
  });

  get f() { return this.bankUpdateForm.controls; }

  setBankDetails() {
    this.bankUpdateForm.patchValue({
      'supplierBankDetailsId' : this.bankData.bankId,
      'supplierId' : this.bankData.supplier_id,
      'bankName' : this.bankData.bank_name,
      'IFSCCode' : this.bankData.ifsc_code,
      'bankAccountName' : this.bankData.bank_account_name,
      'bankAccountNumber' : this.bankData.bank_account_number,
      //'bankProofPhotoURL' : this.bankData.bankId,
      //'cancelChequePhoto' : this.bankData.bankId,
    });
  }

  requestData : any = {};
  utBankRes : any = [];
  utOwnerBankDetails() {
    
    this.requestData = {
      clientId : this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret,
      supplierId : this.bankUpdateForm.get("supplierId").value,
      supplierBankDetailsId : this.bankUpdateForm.get("supplierBankDetailsId").value,
      bankName : this.bankUpdateForm.get("bankName").value,
      IFSCCode : this.bankUpdateForm.get("IFSCCode").value,
      bankAccountName : this.bankUpdateForm.get("bankAccountName").value,
      bankAccountNumber : this.bankUpdateForm.get("bankAccountNumber").value,
      verificationStatus : 1,
    }
    console.log(this.requestData);

    return this.tfs.updateTruckOwnerBankDetails(JSON.stringify(this.requestData)).subscribe(data => {
      
      this.utBankRes = data;
      console.log(this.utBankRes);
      
      if(this.utBankRes.status == 1){
        this.showNotification('top','right',this.utBankRes.message, '1');
        this.back();
      }

    },error => console.log(error));

  }

  // documentFor : any;
  // chequePhotoImg;
  // upImageUrl : any;
  // paramPass : any;
  // paramPassFinal : any;
  // truckBankBlk = true;
  // truckImageUpload = false;
  
  // imageUploadShow(paramPassVal) {
  //   this.documentFor = "truck";
  //   this.supplierId = this.supplierId;
  //   this.truckId = this.truckId;
  //   this.paramPass = paramPassVal;
  //   console.log(this.supplierId + " ~~~~ " + this.truckId);

  //   this.tuBack = false;
  //   this.tuBlk = false;
  //   this.truckImageUpload = true;
  // }

  // hideImageupload(data){

  //   if(data != null){
      
  //       console.log(data);
        
  //       this.upImageUrl = data.finalImageUp;
  //       this.paramPassFinal = data.paramPassFinal;
        
  //       if(this.paramPassFinal == "RCBookPictureFrontURL"){
  //         this.utBasicDetailsForm.patchValue({
  //           'RCBookPictureFrontURL' : this.upImageUrl,
  //         });
  //       }
        
  //       if(this.paramPassFinal == "RCBookPictureBackURL"){
  //         this.utBasicDetailsForm.patchValue({
  //           'RCBookPictureBackURL' : this.upImageUrl,
  //         });
  //       }

  //   }
    
  //   this.tuBack = true;
  //   this.tuBlk = true;
  //   this.truckImageUpload = false;
    
  // }

  showNotification(from, align, messageDis, errorNo) {
    
    // const color = Math.floor(Math.random() * 5 + 1);
    const color = errorNo;
    
    //console.log(errorNo);

    switch (color) {
      
      case '1':
        this.toastr.success(
          '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">'+messageDis+'</span>',
          "",
          {
            timeOut: 500,
            // closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-success alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;
      
        case '2':
        this.toastr.warning(
        '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">'+messageDis+'</span>',
          "",
          {
            timeOut: 4000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-danger alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;
      
        default:
        break;
    }

  }

  back(){
    this.hideBankUpdate.emit();
  }

}