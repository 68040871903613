import { Component, OnInit , ViewChild, Input, Output, EventEmitter} from '@angular/core';
import { FormGroup , FormBuilder , Validators } from '@angular/forms';
import { APiProperties } from '../../class/api-properties';
import { BranchService } from 'app/service/branch.service';
import { SupplierListService } from 'app/service/supplier-list.service';
import { TruckModelService } from "../../service/truck-model.service";
import { DriverService } from '../../service/driver.service';
import { DatePipe,formatDate } from '@angular/common';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import * as S3 from 'aws-sdk/clients/s3';

import { SafeUrl, DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-driver',
  templateUrl: './driver.component.html',
  styleUrls: ['./driver.component.css']
})
export class DriverComponent implements OnInit {
  @Input() ssvData;
  @Output() hideDriverAdd = new EventEmitter();

  @ViewChild('myInput') myInputVariable: any;

  constructor(private fb: FormBuilder, 
              private branchService : BranchService,
              private supplierListService : SupplierListService, 
              private tms : TruckModelService, 
              private datePipe: DatePipe,
              private sanitizer: DomSanitizer, 
              private ds: DriverService) { }

  driverSaveForm : FormGroup;

  City : any=[];
  requestDataLSC : any={};
  supplierListRes : any=[];
  saveDriverRes : any=[];

  submitted = false;

  saveDriverRequestData : {};
  ssvDataVal;

  todayDate : any;
  
  ngOnInit(): void {
    
    this.todayDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    if(this.ssvData == "1"){
      this.ssvDataVal = true;
    }

    this.driverSaveForm = this.fb.group({
      supplierCity : ['' , Validators.required], 
      supplierId : ['' , Validators.required],
      driverName : ['' , Validators.required],
      driverMobileNumber : ['' , [Validators.required,Validators.minLength(10),Validators.maxLength(10)]],
      drivingLicenseNumber : ['' , Validators.required],
      drivingLicenseExpiryDate : ['' , Validators.required],
      drivingLicenseFrontPic : ['' , Validators.required],
      driverProfilePic : ['' , Validators.required]
    });

    this.submitted = false;

    this.getCity();

    this.dlFrontCls = 0;
    this.dppicCls = 0;
  }

  keyPressForNumber(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  keyPressForName(event: any){
    var filter = /[a-zA-Z]/;
    var ch = String.fromCharCode(event.keyCode);
    if(!filter.test(ch)){
         event.returnValue = false;
    }
  }

  getCity(){
    return this.branchService.getCity().subscribe((data : {}) => {
        this.City = data;
    }, error => console.log(error));
  }

  onChangeGetValue(event){

    const value = event.target.value;
    
    this.requestDataLSC = {
      supplierCityId : value
    };
    
    this.supplierListService.getListOfSupplierPerCity(JSON.stringify(this.requestDataLSC)).subscribe((data:{})=>{
        this.supplierListRes = data;
    },error => {
      console.log("Error: "+error);
    });

  }

  @ViewChild('closeButton') closeButton;

  interval;
  updateVehicleConfigImageRes : any;

  properties : APiProperties = new APiProperties();

  vmiID;
  uvmiID;
  
  image: any;

  fileToUpload: any;

  beforeUpload = true;
  afterUpload = false;

  dlFrontCls : any;
  dppicCls : any;
  afterDLImg = false;

  public open(id) {
    this.vmiID = id;

    this.beforeUpload = true;
    this.afterUpload = false;

    this.afterDLImg = false;

    this.myInputVariable.nativeElement.value = "";
  }

  handleFileInput(files: FileList) {
    
    //alert(files);
    this.fileToUpload = files.item(0);
    this.afterDLImg = true;
  }
  
  updateImage(ev) {
    this.image = this.sanitizer.bypassSecurityTrustUrl(
      window.URL.createObjectURL(ev.target.files[0])
    );

    this.afterDLImg = true;
    
  }

  uploadCourseImage(vmiID){
      
      this.uvmiID = vmiID;
      this.uploadCourseImageToAws(this.fileToUpload, this.uvmiID);

      //alert(localStorage.getItem('image'));
      
      this.interval = setInterval(() => {
          
        if(localStorage.getItem('image') != null){

          //alert(localStorage.getItem('image'));

          var imagePass = localStorage.getItem('image');

          this.closeButton.nativeElement.click();

          this.afterDLImg = true;

          if(vmiID == "dlFront"){
          this.driverSaveForm.patchValue({drivingLicenseFrontPic:imagePass});
          this.dlFrontCls = 1;
          }
          
          if(vmiID == "dppic"){
            this.driverSaveForm.patchValue({driverProfilePic:imagePass});
            this.dppicCls = 1;
          }

          clearInterval(this.interval); 
          localStorage.removeItem('image');

          window.localStorage.clear();
          
        }
    
      }, 1000);

      
      this.beforeUpload = false;
      this.afterUpload = true;
  }

  uploadCourseImageToAws(file,rcTextInt){

    //alert(rcTextInt);

    const bucket = new S3(
        {
            accessKeyId: this.properties.s3AccessKeyId,
            secretAccessKey: this.properties.s3SecretAccessKey,
            region: this.properties.s3Region
        }
    );

    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz';
    const stringLength = 10;
    let randomstring = '';
    for (let i = 0; i < stringLength; i++) {
    const rnum = Math.floor(Math.random() * chars.length);
    randomstring += chars.substring(rnum, rnum + 1);
    }
    const codeGenerated = randomstring;


      const params = {
        Bucket: this.properties.s3Bucket,
        Key: 'truck/' + codeGenerated ,
        Body: file,
        ACL: 'public-read',
        ContentType: 'image/jpeg',
        ServerSideEncryption: 'AES256'
    };

     bucket.upload(params, function (err, data) {
      if (err) {
          console.log('There was an error uploading your file: ', err);
          return false;
      } else {
          
      console.log('Successfully uploaded file.', data);
      localStorage.setItem('image',data.Location);

      return true;

      }
      
    });

  
  }

  get f() { return this.driverSaveForm.controls; }

  saveDriver(){
    this.submitted = true; 

    if(this.driverSaveForm.invalid){
          return;
    }

    this.saveDriverRequestData = {
      supplierId : this.driverSaveForm.get("supplierId").value,
      name : this.driverSaveForm.get("driverName").value,
      mobileNumber : this.driverSaveForm.get("driverMobileNumber").value,
      drivingLicenseNumber : this.driverSaveForm.get("drivingLicenseNumber").value,
      drivingLicenseExpiryDate : this.driverSaveForm.get("drivingLicenseExpiryDate").value,
      drivingLicenseFrontPic : this.driverSaveForm.get("drivingLicenseFrontPic").value,
      driverProfilePic : this.driverSaveForm.get("driverProfilePic").value,
    }

    console.log(this.saveDriverRequestData);

    this.ds.saveDriver(JSON.stringify(this.saveDriverRequestData)).subscribe((data : {}) => {

      this.saveDriverRes = data;

      //alert(this.saveDriverRes);

      if(this.saveDriverRes.status ==1 ){
        
        alert("Driver Detail Added Successfully!");
        this.ngOnInit();
      }
      else{
        
        alert(this.saveDriverRes.message);
      }


    }, error => console.log(error));
  }

  back(){
    this.hideDriverAdd.emit();
  }

}
