import { Component, OnInit, ViewChildren, ViewChild, QueryList, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, AbstractControl } from '@angular/forms';
import { TruckieFunctionsService } from 'app/service/truckie-functions.service';
import { APiProperties } from 'app/class/api-properties';
import { DatePipe,formatDate } from '@angular/common';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-supplier-group',
  templateUrl: './supplier-group.component.html',
  styleUrls: ['./supplier-group.component.css']
})
export class SupplierGroupComponent implements OnInit {

  apiProperties : APiProperties = new APiProperties();
  @ViewChild('closeDeleteGroupModal') closeDeleteGroupModal: ElementRef;
  @ViewChild('closeUpdateGroupNameModal') closeUpdateGroupNameModal: ElementRef;

  constructor(private fb: FormBuilder, 
              private tfs : TruckieFunctionsService,
              private datePipe: DatePipe, 
              private toastr : ToastrService) { }

  submitted;
  isViewable;
  slGroupAddBack;
  slGroupAddBlk;
  slGroupListTbl;
  dataNotFound;
  addSupplier;
  addGroupBranch;
  public settings = {};
  branchBtnBlk;
  ngOnInit(): void {

    this.slGroupAddBack = true;
    this.slGroupAddBlk = true;
    this.slGroupListTbl = true;

    this.submitted=false;
    this.isViewable = true;

    this.getSupplierList();
    this.getSupplierGroupList('All');

    this.settings = {
      singleSelection: false,
      idField: 'supplierId',
      textField: 'sFullD',
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      limitSelection: -1,
      clearSearchFilter: true,
      maxHeight: 197,
      itemsShowLimit: 3,
      searchPlaceholderText: 'Search Supplier',
      noDataAvailablePlaceholderText: 'No data Vailable',
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false,
    };

    if(localStorage.getItem('userRole') == 'ADMIN'){
      this.branchBtnBlk = true;
    } 

    this.addSupplier = false;
    this.addGroupBranch = false;
  }

  slGroupForm = this.fb.group({
    groupName : ['' , [Validators.required]]
  });

  updateGroupNameForm = this.fb.group({
    groupId : [""],
    groupName : ["",Validators.required]
  });

  get f(){ return this.slGroupForm.controls; }
  get fEdit(){ return this.updateGroupNameForm.controls; }

  supplierGPLForm = this.fb.group({
    status : ['']
  });

  keyPressCharNotStartingSpace(event: any) {
    const pattern = /^[a-zA-Z0-9 ]/;
    let inputChar = String.fromCharCode(event.charCode);

    if((event.keyCode != 8 && !pattern.test(inputChar)) || (event.which === 32 && !event.target.value.length)) {
      //alert(inputChar);
      event.preventDefault();
    }
    
  }

  clSupplierRes : any = [];
  clSupplierListRes : any = [];
  clSupplierListResLength : any;
  loadContent = false;

  getSupplierList() {

    this.requestData = {
      clientId : this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret
    }

    return this.tfs.fetchSupplierList(JSON.stringify(this.requestData)).subscribe(data => {
      
      this.clSupplierRes = data;
      this.clSupplierListRes = this.clSupplierRes.list;
      //console.log(this.clSupplierListRes);
      this.clSupplierListResLength = this.clSupplierRes.list.length;
      console.log(this.clSupplierListResLength);

      for(var i=0; i<this.clSupplierListRes.length; i++){
        this.clSupplierRes.list[i].sFullD = this.clSupplierRes.list[i].supplierName+" - "+this.clSupplierRes.list[i].supplierNumber; 
      }

      if(this.clSupplierRes.status == '1'){
        this.loadContent = true;
      }

    },error => console.log(error));

  }

  supplierIdData = [];
  public onItemSelect(item: any) {
    //console.log(item);
    //this.supplierIdData.push(item.supplierId);
    this.supplierIdData.push({'supplierId':item.supplierId});
    //console.log(this.supplierIdData);
  }
  
  public onDeSelect(item: any) {
    console.log(item);
    for(var i=0; i < this.supplierIdData.length;i++){
      //if(this.supplierIdData[i] == item.supplierId){
      if(this.supplierIdData[i].supplierId == item.supplierId){  
          console.log(this.supplierIdData[i]);
          this.supplierIdData.splice(i,1);
      }
    }
    //console.log(this.supplierIdData);
  }

  public onSelectAll(items: any) {
    this.supplierIdData = [];
    for(var mp in items){
      //this.supplierIdData.push(items[mp].supplierId);
      this.supplierIdData.push({'supplierId':items[mp].supplierId});
    }
    //console.log(this.supplierIdData);
  }

  public onDeSelectAll(items: any) {
    console.log(items);
    this.supplierIdData = [];
  }

  addSupplierBlkShow(){
    this.addSupplier = true;
  }

  updatedSupplierIdData;
  SupplierIdCheck;
  getSelectedSupplierText(event){

    let selectedElementValue = event.target.value;

    let selectElementText = event.target['options']
      [event.target['options'].selectedIndex].text;

    console.log(selectElementText);  

    this.SupplierIdCheck = selectedElementValue;

    this.updatedSupplierIdData = [];
    this.updatedSupplierIdData.push({'supplierId':selectedElementValue});

  }

  addSupplierToGroup(){

    console.log(this.updatedSupplierIdData);

    if(this.updatedSupplierIdData == undefined){
       alert("select Supplier");
    } else {
      
      this.requestData = {
        clientId : this.apiProperties.clientId,
        clientSecret : this.apiProperties.clientSecret,
        groupName : this.gsGroupNameVal,
        supplierList : this.updatedSupplierIdData,
      }
      console.log(this.requestData);
  
      return this.tfs.createSupplierGroup(JSON.stringify(this.requestData)).subscribe((data : {}) => {
  
        this.slGroupRes = data;
        console.log(this.slGroupRes);
  
        if(this.slGroupRes.status == '1'){
          this.showNotification('top','right',this.slGroupRes.message, '1');
          this.addSupplier = false;
          this.closeUpdateGroupNameModal.nativeElement.click();

          for(var m in this.clSupplierListRes){

            if(this.clSupplierListRes[m].supplierId == this.SupplierIdCheck){
              //console.log(this.clSupplierListRes[m].supplierId);

              this.listOfSupplierRes.push({'supplierId':this.clSupplierListRes[m].supplierId,
                                          'supplierName':this.clSupplierListRes[m].supplierName,
                                          'supplierNumber':this.clSupplierListRes[m].supplierNumber});
            }
            //SupplierIdCheck
          }
          
        } else {
          this.showNotification('top','right',this.slGroupRes.message, '3');
        }
  
      }, error => console.log(error));

    }
  }

  deleteSpFromGpRes : any = [];
  dataPass : any; 
  deleteSupplierFromGroupFun(param){

    this.requestData = {
      groupId : this.gsGroupIdVal,
      supplierId : param.supplierId
    };
    console.log(this.requestData);

    this.tfs.deleteSupplierFromGroup(JSON.stringify(this.requestData)).subscribe((data : {}) => {
      this.deleteSpFromGpRes = data;
      console.log(JSON.stringify(this.deleteSpFromGpRes));
      
      //this.closeDeleteGroupModal.nativeElement.click();
      if(this.deleteSpFromGpRes.status == 1){
        this.showNotification('top','right',this.deleteSpFromGpRes.message, '1');
        
        let index=0;
        for(var i=0; i < this.listOfSupplierRes.length;i++){
            if(this.listOfSupplierRes[i].supplierId == param.supplierId){  
              //console.log(this.listOfSupplierRes[i].supplierId);
              //console.log(param.supplierId);
              //console.log("Inner If");
              this.listOfSupplierRes.splice(index,1);
            }
            index++;
          }
          console.log(this.listOfSupplierRes); 

      }else{
        this.showNotification('top','right',this.deleteSpFromGpRes.message, '2');
      }

    }, error => console.log(error));

  }

  partialListVal : any;
  slGroupRes : any = [];
  supplierListErr;
  saveSupplierGroup(){

    this.submitted = true;

    if(this.slGroupForm.invalid){
       return;
    }

    if(this.supplierIdData.length == 0 ){
      this.supplierListErr = true;
      this.isViewable = true;
      return;
    } else {

      if(this.clSupplierListResLength == this.supplierIdData.length){
      
        var isPartialVal = 0;
        this.partialListVal = this.supplierIdData;
        //this.partialListVal = '';

      } else {
        var isPartialVal = 1;
        this.partialListVal = this.supplierIdData;
      }
      this.supplierListErr = false;
      this.isViewable = false;
    }

    this.requestData = {
      clientId : this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret,
      groupName : this.slGroupForm.controls['groupName'].value,
      supplierList : this.partialListVal,
    }
    console.log(this.requestData);

    return this.tfs.createSupplierGroup(JSON.stringify(this.requestData)).subscribe((data : {}) => {

      this.slGroupRes = data;
      console.log(this.slGroupRes);

      if(this.slGroupRes.status == '1'){
        this.slGroupListTbl = true;
        this.submitted = false;
        //this.slGroupForm.controls.PackagName.setValue('');
        this.supplierIdData = [];
        this.slGroupForm.reset();
        //this.showNotification('top','right',this.slGroupRes.message, '1');
        window.location.reload();
        this.getSupplierGroupList('Active');
      } else {
        this.dataNotFound = true;
        //this.showNotification('top','right',this.slGroupRes.message, '3');
      }

    }, error => console.log(error));

  }

  resetSupplierGroup(){
    //this.submitted = false;
    this.supplierIdData = [];
    this.slGroupForm.reset();
    window.location.reload();
  }

  requestData : any = {};
  slGroupListRes : any = [];
  statusVal : any;
  getSupplierGroupList(e){

    if(e == "Active"){
      this.statusVal = "Active";
    } else if(e == "All"){
      this.statusVal = "All";
    }else {
      this.statusVal = e.target.value;
    }
    this.requestData = {
      status : this.statusVal
    }
    console.log(this.requestData);

    return this.tfs.fetchSupplierGroupList(JSON.stringify(this.requestData)).subscribe((data : {}) => {

      this.slGroupListRes = data;
      console.log(this.slGroupListRes);

      if(this.slGroupListRes.status == '1'){
        this.slGroupListTbl = true;
        this.dataNotFound = false;
        //this.showNotification('top','right',this.slGroupListRes.message, '1');
      } else {
        this.dataNotFound = true;
        this.showNotification('top','right',this.slGroupListRes.message, '3');
      }

    }, error => console.log(error));
  }

  gpStatusRes : any = [];
  updateGroupStatusFun(groupId, status){

    //if(e.traget.checked){}
    this.requestData = {
      groupId : groupId,
      status : status
    };
    console.log(this.requestData);

    return this.tfs.updateGroupStatus(JSON.stringify(this.requestData)).subscribe((data : {}) => {
      this.gpStatusRes = data;

      if(this.gpStatusRes.status == 1){
        this.getSupplierGroupList("All");
      } else {
        this.showNotification('top','right',this.gpStatusRes.message, '3');
      }

    }, error => console.log(error));

  }

  groupIdVal : any;
  groupNameVal : any;
  gpIdNameIndex : any;
  setGroupIdName(data,index,action){
    this.groupIdVal = data.groupId;
    this.groupNameVal = data.groupName;
    this.gpIdNameIndex = index;

    if(action == 'Update'){
      this.updateGroupNameForm.controls.groupName.setValue(this.groupNameVal)
    }
  }

  deleteGroupRes : any = [];
  deleteGroupFun(){

    this.requestData = {
      groupId : this.groupIdVal
    };

    console.log(this.requestData);

    this.tfs.deleteGroup(JSON.stringify(this.requestData)).subscribe((data : {}) => {
      this.deleteGroupRes = data;
      console.log(JSON.stringify(this.deleteGroupRes));
      
      this.closeDeleteGroupModal.nativeElement.click();

      if(this.deleteGroupRes.status == 1){
        this.showNotification('top','right',this.deleteGroupRes.message, '1');
        this.getSupplierGroupList("All");
      }else{
        this.showNotification('top','right',this.deleteGroupRes.message, '2');
      }

    }, error => console.log(error));

  }

  submittedEdit = false;
  updateSPGroupNameRes : any = [];
  updateSupplierGroupNameFun(){

    this.submittedEdit = true;
    if(this.updateGroupNameForm.invalid == true){
      return;
    }

    this.requestData = {
      groupId : this.groupIdVal,
      groupName : this.updateGroupNameForm.controls.groupName.value, 
    };
    console.log(this.requestData);

    this.tfs.updateSupplierGroupName(JSON.stringify(this.requestData)).subscribe((data:{})=>{

      this.updateSPGroupNameRes = data;

      this.submittedEdit = false;
      this.closeUpdateGroupNameModal.nativeElement.click();

      if(this.updateSPGroupNameRes.status == 1){
        this.showNotification('top','right',this.updateSPGroupNameRes.message, '1');
        this.getSupplierGroupList("All");
      } else {
        this.showNotification('top','right',this.updateSPGroupNameRes.message, '3');
      }

    },error => console.log(error));

  }

  listOfSupplierRes : any;
  gsGroupIdVal : any;
  gsGroupNameVal : any;
  removeIndex : any;
  setGroupSupplierName(data,i){
    
    this.gsGroupIdVal = data.groupId;
    this.gsGroupNameVal = data.groupName;
    console.log(data.groupId);

    this.removeIndex = i;
    for(var p in this.slGroupListRes.listOfGroup){
      //console.log(this.slGroupListRes.listOfGroup[p]);
      if(data.groupId == this.slGroupListRes.listOfGroup[p].groupId){
        //console.log(this.slGroupListRes.listOfGroup[p].listOfSupplier);
        this.listOfSupplierRes = this.slGroupListRes.listOfGroup[p].listOfSupplier;
      }
    }
    
    //console.log(this.listOfSupplierRes);
  }

  bsGroupIdVal: any;
  bsGroupNameVal: any;
  setGroupBranchName(data,i){
    this.bsGroupIdVal = data.groupId;
    this.bsGroupNameVal = data.groupName;
    console.log(this.bsGroupIdVal+" "+this.bsGroupNameVal);

    this.fetchListOfMappedGroupWithBranch(data);
  }

  listOfMappedGroupWithBranchRes: any=[];
  fetchListOfMappedGroupWithBranch(datav) {

    this.requestData = {
      clientId : this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret,
      groupId : datav.groupId
    }
    console.log(this.requestData);

    return this.tfs.fetchListOfMappedGroupWithBranch(JSON.stringify(this.requestData)).subscribe(data => { 
      this.listOfMappedGroupWithBranchRes = data;
      
      if(this.listOfMappedGroupWithBranchRes.status == 1){
        console.log(this.listOfMappedGroupWithBranchRes);
      }

    },error => console.log(error));

  }

  masterBranchRes : any = [];
  masterBranch: any=[];
  fetch_master_branch(){

    if(localStorage.getItem('userRole') == "SUB_ADMIN"){
      this.requestData = {
        adminId : localStorage.getItem('adminIdLocal')
      }
    } else {
      this.requestData = {
        adminId : ""
      }
    }
    console.log(this.requestData);

    this.tfs.fetch_master_branch(JSON.stringify(this.requestData)).subscribe((data : {}) => {
      this.masterBranchRes = data;
      this.masterBranch = this.masterBranchRes.list;
      console.log(this.masterBranch);
      
    }, error => console.log(error));
  }

  addGroupBranchBlkShow(){
    this.fetch_master_branch();
    this.addGroupBranch = true;
  }

  selectedBranchValue: any;
  getSelectedBranchText(event){

    this.selectedBranchValue = event.target.value;

    let selectElementText = event.target['options']
      [event.target['options'].selectedIndex].text;

      
    console.log(this.selectedBranchValue+" "+selectElementText);  

    // this.SupplierIdCheck = selectedElementValue;

    // this.updatedSupplierIdData = [];
    // this.updatedSupplierIdData.push({'supplierId':selectedElementValue});

  }

  mappGroupWithBranch(){
      if(!this.selectedBranchValue){
        alert("please select branch");
        return;
      }
      this.mappUnmappGroupWithBranch(this.selectedBranchValue,1);
  }


  mappUnmappGroupWithBranchRes: any=[];
  mappUnmappGroupWithBranch(branchIdVal,actionVal) {

    this.requestData = {
      clientId : this.apiProperties.clientId,
      clientSecret : this.apiProperties.clientSecret,
      actingUser: localStorage.getItem('userNameLocal'),
      branchId: branchIdVal,
      listOfGroup: [
        {
            groupId: this.bsGroupIdVal
        }
    ],
    action: actionVal //mapped =1 and unmapped=0
    }
    console.log(this.requestData);
    //return;

    this.tfs.mappUnmappGroupWithBranch(JSON.stringify(this.requestData)).subscribe(data => { 
      this.mappUnmappGroupWithBranchRes = data;
      console.log(this.mappUnmappGroupWithBranchRes);

      if(this.mappUnmappGroupWithBranchRes.status == 1){
        this.fetchListOfMappedGroupWithBranch({groupId: this.bsGroupIdVal});
        this.showNotification('top','right',this.mappUnmappGroupWithBranchRes.message, '1');

        if(actionVal == 1){
          this.addGroupBranch = false;
        }
        
      } else {
        this.showNotification('top','right',this.mappUnmappGroupWithBranchRes.message, '3');
      }

    },error => console.log(error));

  }

  showNotification(from, align, messageDis, errorNo) {
    
    //const color = Math.floor(Math.random() * 5 + 1);
    const color = errorNo;
    //console.log(errorNo);

    switch (color) {
      
        case '1':
        this.toastr.success(
          '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">'+messageDis+'</span>',
          "",
          {
            timeOut: 500,
            // closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-success alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;
      
        case '2':
        this.toastr.warning(
        '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">'+messageDis+'</span>',
          "",
          {
            timeOut: 4000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-danger alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;
      
        case '3':
        this.toastr.error(
        '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">'+messageDis+'</span>',
          "",
          {
            timeOut: 4000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-danger alert-with-icon",
            positionClass: "toast-" + from + "-" + align
          }
        );
        break;

        default:
        break;
    }

  }

}